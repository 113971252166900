import { useState, useEffect } from "react";
import { SiteListApi } from "../../Api/SiteListApi";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import {
  CheckForLight,
  generateDynamicUrl,
  getFormatDate,
  roundedUp,
} from "../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import { paddedEncryptQuery, queryDecrypt } from "../util/security/Cipher";
import { useNavigate } from "react-router";
import { CustomChartIcon } from "../util/common/CustomChartIcon";
import CachedIcon from "@mui/icons-material/Cached";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

function SiteStatusTable() {
  const [loading, setLoading] = useState(true);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");
  const [siteDetails, setSiteDetails] = useState([]);
  const [isReferesh, setRefresh] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(
    sessionStorage.getItem("refresh") === "true"
  );

  const navigate = useNavigate();

  function getColor(status) {
    switch (status) {
      case "Active":
        return "green";
      case "Offline":
        return "#98A3B2";
      case "Warning":
        return "#FFB10E";
      case "Down":
        return "#E83A2A";
      default:
        return "transparent";
    }
  }

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      console.log("Auto-refresh enabled");
      interval = setInterval(handleRefresh, 5 * 60 * 1000);
      console.log("Interval set for 5 minutes");
    } else {
      clearInterval(interval);
      console.log("Auto-refresh disabled");
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  function navigateEncryptedURL(data, URL) {
    let passSite = {
      id: data.siteId,
      name: data.siteName,
      irradiation: data.irradiation,
    };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }

  function handleRowClick(rowValue) {
    navigateEncryptedURL(rowValue, "/menu/site_overview");
  }

  const handleAnalytics = () => {
    navigate("/menu/block-analyst");
  };

  const handleRefresh = () => {
    setRefresh(true);
    const RefreshTimeout = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => clearTimeout(RefreshTimeout);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortData = (data, orderBy, orderDirection) => {
    return data.sort((a, b) => {
      if (orderDirection === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  };

  const rolesString = queryDecrypt(sessionStorage.getItem("roles"));
  function hasPermission(activityName) {
    const rolesArray = rolesString ? JSON.parse(rolesString) : [];
    const activityEntry = rolesArray.find(
      (entry) => entry.activityName === activityName
    );

    const result =
      (activityEntry && activityEntry.status == 1 && activityEntry.view == 1) ||
      false;

    return result;
  }

  useEffect(() => {
    fetchSiteData();
  }, []);

  useEffect(() => {
    if (isReferesh) {
      fetchSiteData(siteDetails);
    }
  }, [isReferesh]);

  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : "0";
  };

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await SiteListApi();
    let updatedSites = response.map((site) => ({
      ...site,
      specificYield: specificYiled(site.specificYield),
      inverter: `${site.activeInverterCount}/${site.inverterCount}`,
      inverterFlag:
        +site.activeInverterCount === 0
          ? "#98A3B2"
          : +site.activeInverterCount <= +site.inverterCount - 1
          ? "#e83a2a"
          : "#10A710",
      status:
        site.siteStatus === "Active"
          ? 1
          : site.siteStatus === "Warning"
          ? 2
          : site.siteStatus === "Offline"
          ? 4
          : 3,
      irradiation: roundedUp(site.irradiation),
    }));
    // console.log(updatedSites);

    setSiteDetails(updatedSites);

    setLoading(false);
  };
  return (
    <>
      <Card
        sx={{ width: "100%", height: "340px", paddingBottom: 0 }}
        elevation={0}
        key={"siteStatusTable"}
      >
        <CardContent
          sx={{
            height: "100%",
            paddingBottom: "0 !important",
            paddingTop: "10px !important",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                }}
              >
                Quick Insights
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRefresh}
                    onChange={(e) => handleAutoRefresh(e.target.checked)}
                  />
                }
                label="Auto Refresh (5 min)"
              />
            </div> */}
              <div>
                <Tooltip title="Refresh">
                  <IconButton onClick={handleRefresh} disabled={isReferesh}>
                    <CachedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ marginTop: "1px" }}>
                <Tooltip title="Analytics">
                  <IconButton
                    onClick={handleAnalytics}
                    disabled={!hasPermission("blockAnalytics")}
                  >
                    {/* <a href="/menu/block-analyst"> */}
                    <AnalyticsOutlinedIcon
                      sx={{
                        color: !hasPermission("blockAnalytics")
                          ? "gray"
                          : CheckForLight()
                          ? "black"
                          : "white",
                      }}
                    />
                    {/* </a> */}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          {loading ? (
            <Card
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              elevation={0}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <FadeLoader color="#000440" loading={true} />
              </CardContent>
            </Card>
          ) : (
            <>
              <TableContainer
                component={Paper}
                elevation={1}
                sx={{ width: "100%", height: "285px", overflowX: "hidden" }}
              >
                <Table
                  size="small"
                  aria-label="Inverter Performance Table"
                  elevation={0}
                  stickyHeader
                >
                  <TableHead>
                    <TableRow style={{ fontSize: "13px !important" }}>
                      <TableCell
                        align="center"
                        sx={{
                          width: "30px !important",
                          fontSize: "13px !important",
                          padding: "0px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "siteName"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("siteName")}
                        >
                          Site Name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                          zIndex: 2,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "performanceRatio"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("performanceRatio")}
                        >
                          PR (%)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                          zIndex: 2,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "sumOfActivePower"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("sumOfActivePower")}
                        >
                          Active Power (kW)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "irradiation"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("irradiation")}
                        >
                          Irr (kWh/m²)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "specificYield"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("specificYield")}
                        >
                          Specific Yield
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "sumOfTodayEnergy"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("sumOfTodayEnergy")}
                        >
                          Today Energy (kWh)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "cuf"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("cuf")}
                        >
                          CUF (%)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "cuf"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("cuf")}
                        >
                          Inverter
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "ambientTemperature"}
                          direction={orderDirection}
                          onClick={() =>
                            handleSortRequest("ambientTemperature")
                          }
                        >
                          Ambient Temp (°C)
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "moduleTemperature"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("moduleTemperature")}
                        >
                          Module Temp (°C)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          padding: "0px",
                          fontSize: "13px !important",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "lastUpdatedTimestamp"}
                          direction={orderDirection}
                          onClick={() =>
                            handleSortRequest("lastUpdatedTimestamp")
                          }
                        >
                          Last Updated
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortData(siteDetails, orderBy, orderDirection).map(
                      (row) => {
                        const data = {
                          id: row.siteId,
                          name: row.siteName,
                          irradiation: row.irradiation,
                        };
                        return (
                          <TableRow key={row.siteId}>
                            <TableCell
                              sx={{
                                flexDirection: "row",
                                height: "100%",
                                color: CheckForLight() ? "blue" : "#0097ff",
                                cursor: "pointer",
                              }}
                            >
                              <a
                                href={generateDynamicUrl(
                                  data,
                                  "/menu/site_overview"
                                )}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRowClick(row);
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: CheckForLight() ? "blue" : "#0097ff",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: getColor(row.siteStatus),
                                    height: "15px",
                                    width: "10px",
                                    marginRight: "5px",
                                  }}
                                ></div>
                                <div style={{ flexGrow: 1 }}>
                                  {row.siteName}
                                </div>
                              </a>
                            </TableCell>
                            <TableCell
                              style={{
                                color:
                                  +row.performanceRatio?.split("%")[0] > 100
                                    ? "red"
                                    : null,
                              }}
                              align="left"
                            >
                              {row.performanceRatio?.split("%")[0] ??
                                row.performanceRatio}
                            </TableCell>
                            <TableCell align="left">
                              {row.sumOfActivePower?.split("kW")[0] ??
                                row.sumOfActivePower}
                            </TableCell>
                            <TableCell align="center">
                              {row.irradiation}
                            </TableCell>
                            <TableCell align="center">
                              {row.specificYield}
                            </TableCell>
                            <TableCell align="left">
                              {row.sumOfTodayEnergy?.split("kWh")[0] ??
                                row.sumOfTodayEnergy}
                            </TableCell>
                            <TableCell align="left">
                              {isNaN(row.cuf?.split("%")[0])
                                ? "-"
                                : row.cuf.split("%")[0] ?? row.cuf}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: row.inverterFlag }}
                            >
                              {row.inverter}
                            </TableCell>
                            <TableCell align="left">
                              {isNaN(row.ambientTemperature)
                                ? "-"
                                : row.ambientTemperature.toFixed(1)}
                            </TableCell>
                            <TableCell align="left">
                              {isNaN(row.moduleTemperature)
                                ? "-"
                                : row.moduleTemperature.toFixed(1)}
                            </TableCell>
                            <TableCell align="center">
                              {row.lastUpdatedTimestamp === ""
                                ? "-"
                                : getFormatDate(row.lastUpdatedTimestamp)}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default SiteStatusTable;
