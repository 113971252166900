import axios from "axios";

export const SharedWidgetsSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SHARED_WIDGETS_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const SharedWidgetsGet = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SHARED_WIDGETS_GET}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const SharedWidgetsDelete = async (obj) => {
  const { by, to } = obj;
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SHARED_WIDGETS_DELETE}sharedBy=${by}&sharedTo=${to}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const SharedWidgetsDropdown = async (to, siteId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SHARED_WIDGETS_DROPDOWN}sharedTo=${to}&siteId=${siteId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const WidgetsListDropdown = async (userID, siteId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_WIDGET_NAME_DROPDOWN}userId=${userID}&siteId=${siteId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
