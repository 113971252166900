import React, { useState, useEffect } from "react";

import {
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { useLocation } from "react-router-dom";
import ApexChart from "../Components/AnalyticsDashboard/ApexexChart";
import CombinedChart from "../Charts/CombainedChart";
import FadeLoader from "react-spinners/FadeLoader";
import {
  removeQuestionMark,
  shouldHighlightRed,
} from "../util/common/CommanFunction";
import { decryptPaddedQuery } from "../util/security/Cipher";
import moment from "moment";
import {
  CheckForLight,
  roundedUp,
  transformDataForSpecificYield,
} from "../util/common/CommanFunction";
import { SiteListDetail } from "../../Api/SiteoverviewApi";
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from "../../Api/CommonApi";
import {
  fetchSpecificYieldEira,
  fetchParameterComparisonEira,
  getTotalActivePower,
} from "../../Api/DataAnalystApi";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { ViewAnalytics } from "../Components/AnalyticsDashboard/ViewAnalyst";
import SiteoverviewTable from "./SiteoverviewTable";
import TicketView from "../Ticket/TicketView";
const Siteoverview = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [chart, setChart] = useState([]);
  const [Chartdata, setChartdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartloading, setChartLoading] = useState(true);
  const [charttotal, setCharttotal] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [customView, setCustomView] = useState();
  const [datavalue, setDatavalue] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [Categorydata, setCategorydata] = useState([]);
  console.log(Categorydata);

  const [Category, setCategory] = useState([]);
  const [unitChange, SetUnitChange] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  console.log(multiChartData);

  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;
  let SiteId = params.id;
  let name = params.name;
  let irradiation = params.irradiation;
  console.log(params, params);
  console.log(Categorydata);

  console.log(irradiation);
  const datacategory = [
    { dataName: "Active Power", value: "Active Power" },
    { dataName: "Total Active Power", value: "Total Active Power" },
  ];

  useEffect(() => {
    fetchData();
    getSiteCall();
    setCategorydata("Active Power");
  }, []);

  const changeUnit = () => {
    SetUnitChange(!unitChange);
  };
  const convertKWhToMWh = (kWh) => kWh / 1000;
  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : "0";
  };

  const fetchData = async () => {
    try {
      const dataa = await SiteListDetail(SiteId);
      const updatedSites = dataa.map((site) => ({
        ...site,
        specificYield: specificYiled(site.specificYield),
      }));

      setDatavalue(updatedSites);
    } catch (e) {}
    setLoading(false);
  };
  console.log(siteIdName);

  const getSiteCall = async () => {
    try {
      const equipList = await fetchEquipDropdownBySite(SiteId);
      await fetchActivepower(equipList);
      await fetchTotalActivepower(equipList);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    } finally {
      setChartLoading(false);
    }
  };

  const fetchActivepower = async (datavaluee) => {
    const toDateValue = new Date().toISOString().split("T")[0];
    const id = SiteId;
    let siteIdFilter = siteIdName?.filter((data) => {
      console.log(params.name);
      return data.siteName === params.name;
    });
    console.log(datavaluee);
    const equipmentId = datavaluee
      .filter(
        (item) =>
          item.equipmentCategory === "Weather Station" ||
          item.equipmentCategory === "String Inverter"
      )
      .map((item) => item.equipmentId)
      .sort((a, b) => a - b);

    console.log(equipmentId);
    const datas = {
      GraphType: "Parameter Comparision",

      energyFlag: 0,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIds: equipmentId,
      parameters: ["ActivePower", "Irradiation"],
      fromDate: toDateValue,
      intervalMins: 5,
      siteId: SiteId,
      timeperiod: "Daily",
      range: "daily",

      toDate: toDateValue,
    };

    console.log(Categorydata);

    try {
      let responseData = await fetchParameterComparisonEira(datas);

      setChart(responseData);
      console.log(responseData);

      const dataFields = ["ActivePower", "Irradiation"];
      const seriesData = [];

      dataFields.forEach(async (field) => {
        responseData.forEach(async (data) => {
          console.log(field);

          let equipmentName = datavaluee?.filter((dataValue) => {
            return (
              dataValue.equipmentId ===
              Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
            );
          });

          if (
            (equipmentName[0]?.equipmentCategory === "String Inverter" &&
              field === "ActivePower") ||
            (equipmentName[0]?.equipmentCategory === "Weather Station" &&
              field === "Irradiation")
          ) {
            console.log(equipmentName);
            console.log(data);

            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;

            const timeStamp = new Date(timeStampData).getTime();

            console.log(timeStamp);

            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            console.log(value);
            console.log(seriesName);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };
              seriesData.push(newSeries);
              console.log(seriesData);

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });

              setMultichartData(filteredArray);
              return filteredArray;
            }
          }
        });
      });

      console.log(seriesData);

      let filteredArray = seriesData.filter((obj) => {
        return !obj.data.every(([_, value]) => isNaN(value));
      });
      console.log(filteredArray);

      setMultichartData(filteredArray);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  const fetchTotalActivepower = async (datavaluee) => {
    setCharttotal(true);
    const toDateValue = new Date().toISOString().split("T")[0];
    const id = SiteId;
    let siteIdFilter = siteIdName?.filter((data) => {
      console.log(params.name);
      return data.siteName === params.name;
    });
    console.log(datavaluee);
    const category = "Total Active Power";
    const equipmentId = datavaluee
      .filter(
        (item) =>
          item.equipmentCategory === "Weather Station" ||
          item.equipmentCategory === "String Inverter"
      )
      .map((item) => item.equipmentId)
      .sort((a, b) => a - b);

    console.log(equipmentId);
    const datas = {
      GraphType: "Total Active Power",
      energyFlag: 0,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIds: equipmentId,
      parameters: ["ActivePower"],
      fromDate: toDateValue,
      intervalMins: 5,
      siteId: SiteId,
      timeperiod: "Daily",
      range: "daily",

      toDate: toDateValue,
    };

    console.log(Categorydata);

    try {
      let responseData = await getTotalActivePower(datas);
      setChartdata(responseData);
      console.log(responseData);

      const dataFields = ["ActivePower", "Irradiation"];
      const seriesData = [];

      dataFields.forEach(async (field) => {
        responseData.forEach(async (data) => {
          console.log(field);

          let equipmentName = datavaluee?.filter((dataValue) => {
            return (
              dataValue.equipmentId ===
              Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
            );
          });

          if (
            (equipmentName[0]?.equipmentCategory === "String Inverter" &&
              field === "ActivePower") ||
            (equipmentName[0]?.equipmentCategory === "Weather Station" &&
              field === "Irradiation")
          ) {
            console.log(equipmentName);
            console.log(data);

            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;

            const timeStamp = new Date(timeStampData).getTime();

            console.log(timeStamp);

            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            console.log(value);
            console.log(seriesName);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };
              seriesData.push(newSeries);
              console.log(seriesData);

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });

              setCustomView(filteredArray);
              return filteredArray;
            }
          }
        });
      });

      console.log(seriesData);

      let filteredArray = seriesData.filter((obj) => {
        return !obj.data.every(([_, value]) => isNaN(value));
      });
      console.log(filteredArray);

      setCustomView(filteredArray);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const performanceRatio = datavalue[0]?.performanceRatio;
  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "53vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "53vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Available
        </Typography>
      </CardContent>
    </Card>
  );

  const site = datavalue[0]?.siteName;
  const paths = isFromSiteList
    ? [
        { label: "Home", path: "/menu" },
        { label: "Site List", path: "/menu" },
        { label: site, path: -1 },
      ]
    : "";
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <FadeLoader color="#000440" loading={true} />
    </div>
  ) : (
    <div>
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "5vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              width={"100%"}
              direction="row"
              justifyContent="space-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                style={{ display: "flex", alignItems: "center" }}
              ></Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: "1%" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "0.5%",
          }}
        >
          <Paper
            elevation={0}
            style={{
              display: "flex",
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "20vh",
              userSelect: "none",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={4}
              columns={48}
              style={{ padding: "20px 20px 20px 20px" }}
            >
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>
                  Specific Yield
                </Typography>
                <Typography>{datavalue[0]?.specificYield}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Today Energy</Typography>
                <Typography>{datavalue[0]?.sumOfTodayEnergy}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Total Energy</Typography>
                <Typography style={{ position: "center" }}>
                {/* {datavalue[0]?.sumOfTotalEnergy} */}
                  {unitChange
                    ? `${(
                        parseFloat(datavalue[0]?.sumOfTotalEnergy || 0) * 1000
                      )} kWh`
                    : `${parseFloat(
                        datavalue[0]?.sumOfTotalEnergy || 0
                      ).toFixed(2)} MWh`}
                  {unitChange ? (
                    <ArrowDropUpOutlinedIcon
                      style={{ alignItems: "center" }}
                      onClick={changeUnit}
                    />
                  ) : (
                    <ArrowDropDownOutlinedIcon onClick={changeUnit} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Active Power</Typography>
                <Typography>{datavalue[0]?.sumOfActivePower}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Capacity</Typography>
                <Typography>{`${datavalue[0]?.installationCapacity} kWp`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>
                  Inverter Capacity
                </Typography>
                <Typography style={{ alignItems: "center" }}>
                  {datavalue[0]?.invertersCapacity}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>CUF</Typography>
                <Typography>{datavalue[0]?.cuf}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>CO2 Avoided</Typography>
                <Typography>{datavalue[0]?.co2}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Today Events</Typography>
                <Typography style={{ alignItems: "center" }}>
                  {datavalue[0]?.todayEvents}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Total Events</Typography>
                <Typography>{datavalue[0]?.totalEvents}</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>PR</Typography>

                <Typography
                  style={{
                    color: shouldHighlightRed(
                      "performanceRatio",
                      performanceRatio
                    )
                      ? "red"
                      : "inherit",
                  }}
                >
                  {performanceRatio}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: "gray" }}>Status</Typography>
                <Typography>{datavalue[0]?.siteStatus}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>

      <div>
        <Grid container columnSpacing={2} style={{ marginTop: "1%" }}>
          <Grid item lg={6}>
            <Paper elevation={0} style={{}}>
              {chartloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    padding: "30%",
                  }}
                >
                  <FadeLoader color="#000440" loading={true} />
                </div>
              ) : (
                <ApexChart
                  tableData={Chartdata}
                  chartName={"Total Active Power"}
                  multiChartData={customView}
                  name="paramter"
                  timeValue="Today"
                  widgetName={`Total Active Power - ${name}`}
                  fallBack={fallBack}
                  from={"Vanalytics"}
                />
              )}
            </Paper>
          </Grid>
          <Grid item lg={6}>
            <Paper elevation={0}>
              <div>
                {chartloading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",

                      padding: "30%",
                    }}
                  >
                    <FadeLoader color="#000440" loading={true} />
                  </div>
                ) : (
                  <ApexChart
                    tableData={chart}
                    chartName={"Parameter Comparision"}
                    multiChartData={multiChartData}
                    name="paramter"
                    timeValue="Today"
                    widgetName={`Daily Generation - ${name}`}
                    fallBack={fallBack}
                    from={"Vanalytics"}
                  />
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "1%" }}>
        <SiteoverviewTable SiteId={SiteId} irradiation={irradiation} />
      </div>
    </div>
  );
};
export default Siteoverview;
