import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  Divider,
  Modal,
  CardHeader,
  Card,
  CardContent,
  IconButton,
  TableCell,
  Tooltip,
  TableBody,
  TableRow,
  CircularProgress,
  TableContainer,
  TableHead,
  Table,
  Backdrop,
  Fade,
  tableCellClasses,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { MasterSchema } from "../../util/ValidateSchema";
import {
  fetchMasterDropdown,
  getAllDefMaster,
  GetFileParamCoff,
  postDefMappingParams,
  PutparameterUpdate,
  updateDefMapping
} from "../../../Api/ParameterApi";
import { Equipmenttypedropdown } from "../../../Api/EquipmenttypeApi";
import TableTemplate from "../../../Template/TableTemplate";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import CustomSnackbar from "../../util/components/CustomSnackbar";
import { TabsComponent } from "../../util/components/Tabs";
// import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FadeLoader from "react-spinners/FadeLoader";
import { fetchFileGetFile, GetMappingByMaster, saveDefMaster } from "../../../Api/ExcelUploadAPi";
import * as XLSX from "xlsx";
import { downloadExcelTemplate } from "../../../Template/ExcelTemplates/ExcelTemplate";
import { dataloggerfilePath } from "../../../Api/DataLoggerAPi";
import { Equipmentcategorydropdown } from "../../../Api/EquipmentListApi";
export default function Ftpgetfile({ Report }) {
  const initialState = {
    status: "Active",
    defMasterName: '',
    equipmentType: '',
    equipmentTypeId: '',
    defMasterId: ''
  };
  const [stdField, setstdField] = useState(initialState);
  console.log(stdField);
  // const [internalLoader, setInternalLoader] = useState(false);
  const [equipmentName, setequipmentId] = useState([]);

  const [editlog, seteditlog] = useState(false);
  const [openMaster, setOpenMaster] = useState(false);
  const [ParameterName, setParameterName] = useState([]);
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [toBackend, setToBackend] = useState(false);
  const [value, setValue] = React.useState(0);
  const [editValue, setEditvalue] = useState(null);
  const [Openmadal, setOpenModal] = useState(false);
  // const [dataLoggerValue, setDataloggerValue] = useState("");
  // const [tablevalue, setTablevalue] = useState();
  // const [tablemap, setTablemap] = useState();
  const [mappingParams, setMappingParams] = useState([]);
  // const [site, setSite] = React.useState("");
  const [selectedStdParam, setSelectedStdParam] = useState([]);
  const [coefficientValue, setCoefficientValue] = useState([]);
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const inputRefs = useRef([]);
  // const [stdValue, setStdValue] = useState([]);
  // const [standardParams, setStandardParams] = useState([]);
  // const [stdParamId, setStandardParamsId] = React.useState([]);
  const [paramCoff, setParamNameCoff] = useState([]);
  const [isEditMapping, setIsEditMapping] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const dummyFileParams = [
    "R-N phase - V",
    "Y-N phase - V",
    "B-N phase - V",
    "Avg voltage - V",
    "R-Y voltage - V",
    "Y-B voltage - V",
    "B-R voltage - V",
    "R phase line current - A",
    "Y phase line current - A",
    "B phase line current - A",
    "Neutral line current - A",
    "Power factor q1 - ",
    "Power factor q2 - ",
    "Power factor q3 - ",
    "Avg power factor - ",
    "R phase active power - kW",
    "Y phase active power - kW",
    "B phase active power - kW",
    "3 phase active power - kW",
    "R phase reactive power - kVAR",
    "Y phase reactive power - kVAR",
    "B phase reactive power - kVAR",
    "3 phase reactive power - kVAR",
    "R phase apparent power - kVA",
    "Y phase apparent power - kVA",
    "B phase apparent power - kVA",
    "3 phase apparent power - kVA",
    "Frequency - Hz",
    "Active import (fundamental) one - kWh",
    "Active export (fundamental) one - kWh",
    "Apparent Export - kVAh",
    "Reactive import - kVArh",
    "Reactive export - kVArh"
  ]
  const [fileMappingParams, setFileMappingParams] = useState([]);
  const dummyFilePath = [
    ".//SE00044/DEF/Secure_Elite_440_446_SUNPM_10.csv",
    ".//SE00044/DEF/Sensor_3.csv",
    ".//SE00044/DEF/Sungrow_110_SUNPM_TCP.csv",
    ".//SE00044/DEF/WPM0165C8_IO.csv"
  ]

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState(null);
  console.log(selectedFileData, 'selectedFileData');

  const requiredHeader = ["Parameter Name", "Standard Parameter", "CoEfficient"];
  const handleDownload = async () => {
    downloadExcelTemplate('Master Mapping', true, paramCoff);
  }
  const handleInput = (e) => {
    handleSelectExcel(e);
    e.target.value = null;
    // handleClose();
  };
  // const handleEmptyLogger = tablevalue?.filter(data => data.loggerFlag !== 1);
  const handleUpload = () => {
    // let errorMsg = null;
    // if ((handleEmptyLogger?.length === 0 && dataLoggerValue) || handleEmptyLogger?.some(data => data.dataLoggerName !== dataLoggerValue)) errorMsg = "Selected Data Logger Master is not empty";
    // if (!dataLoggerValue) errorMsg = "Please select data logger";
    // if (!site) errorMsg = "Please select site";

    // console.log(!dataLoggerValue, 'handleEmptyLogger.length === 0 && dataLoggerValue');

    // if (errorMsg) {
    //   setSnack({
    //     ...snack,
    //     open: true,
    //     severity: "error",
    //     message: errorMsg
    //   });
    // } else {
    fileInputRef.current.click();
    // }
  };
  async function handleSelectExcel(event) {
    const file = event.target.files[0];
    if (file) {
      console.log(file, 'filesfiles')
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (!headersPresented || jsonData.length === 0) {
          console.log("headersPresented", !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
                ? "Excel couldn't be empty"
                : null,
          });
          setExcelOpen(false);
          setOpenModal(false);
        } else {
          setExcelOpen(true);
          setOpenModal(true);
          const missingIndices = [];
          jsonData.forEach((row, index) => {
            const DummyParam = "Dummy, (N/A)";
            const modifiedUserParam = row['Standard Parameter']
              ? row['Standard Parameter']
              : DummyParam.split(',')[0].trim();
            const selectedStdParam = paramCoff.find((data) => {
              return data.stdParameterName === modifiedUserParam;
            });
            console.log(selectedStdParam, 'selectedStdsParam');
            if (!selectedStdParam) {
              console.log(`No match for 'Standard Parameter' at index: ${index}`);
              missingIndices.push(index);
            } else {
              if (selectedStdParam.stdParameterName === 'Dummy') {
              } else {
                row['Standard Parameter'] = selectedStdParam.stdParameterName;
                row['Std Unit'] = selectedStdParam?.stdUom ? selectedStdParam?.stdUom : '';
              }
            }
          });
          // if (missingIndices.length > 0) {
          //   setErrorIndex((prev) => {
          //     return { ...prev, index: missingIndices, open: true }
          //   })
          // }
          setSelectedFileData(jsonData);
        }
      };
      reader.readAsBinaryString(file);
      fileInputRef.current.value = null;
    }
  }


  const getparamName = async () => {
    let data = await GetFileParamCoff();
    setParamNameCoff(data);
  };

  const [filePath, setFilePath] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [fileget, setFileget] = useState([]);
  const getFilePath = async (id) => {
    try {
      let data = await dataloggerfilePath(id);

      // Process each file path to get the last part
      const fileNames = data.map((path) => {
        const parts = path.split("/");
        return parts[parts.length - 1];
      });

      setFilePath(data);
      setFileNames(fileNames);
    } catch (e) {
      console.log(e);
    }
  };

  const getfileget = async (id) => {
    console.log(id, "--------------------------");
    const data = await fetchFileGetFile(id);
    if (Array.isArray(data)) {
      setFileget(data);
    } else if (typeof data === "object" && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setFileget([data]);
    }
  };
  console.log(filePath, 'filePathSE00044', fileNames);



  const [masterDropDown, setMasterDropDown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mappingLoader, setMappingLoader] = useState(false);
  // const dummydata = [
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "45",
  //     "defMasterId": 3,
  //     "stdParamsId": 169,
  //     "stdParamsName": "SunAngle, (Â°)",
  //     "uom": "(DD-MM-YYYY HH:MM:SS)",
  //     "sequence": 1,
  //     "parameterName": "Total Active Energy Export -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "54",
  //     "defMasterId": 3,
  //     "stdParamsId": 166,
  //     "uom": "(NULL)",
  //     "sequence": 2,
  //     "parameterName": "Total Active Power Meter -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "1",
  //     "defMasterId": 3,
  //     "stdParamsId": 169,
  //     "uom": "(DD-MM-YYYY HH:MM:SS)",
  //     "sequence": 3,
  //     "parameterName": "R-N Phase Voltage -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "53",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "(N/A)",
  //     "sequence": 4,
  //     "parameterName": "Y-N Phase Voltage -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "54",
  //     "defMasterId": 3,
  //     "stdParamsId": 168,
  //     "uom": "(Â°)",
  //     "sequence": 5,
  //     "parameterName": "B-N Phase Voltage -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "1",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "",
  //     "sequence": 6,
  //     "parameterName": "R-Y Phase Voltage - ",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "1",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "",
  //     "sequence": 7,
  //     "parameterName": "Y-B Phase Voltage - ",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "54",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "(N/A)",
  //     "sequence": 8,
  //     "parameterName": "B-R Phase Voltage -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "1",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "",
  //     "sequence": 9,
  //     "parameterName": "Avg Power Factor - ",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "64",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "(N/A)",
  //     "sequence": 10,
  //     "parameterName": "R Current -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "1",
  //     "defMasterId": 3,
  //     "stdParamsId": 178,
  //     "uom": "(Â°)",
  //     "sequence": 11,
  //     "parameterName": "Y Current -",
  //     "filePath": "/path/to/file1"
  //   },
  //   {
  //     "createdBy": 3,
  //     "status": 1,
  //     "coEfficient": "56",
  //     "defMasterId": 3,
  //     "stdParamsId": 181,
  //     "uom": "(N/A)",
  //     "sequence": 12,
  //     "parameterName": "B Current -",
  //     "filePath": "/path/to/file1"
  //   }
  // ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: 'cornflowerblue',
      color: theme.palette.common.white,
      width: '-10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      // width: '-10px',
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const initialMaster = {
    defMasterName: null,
    defFilePath: null,
    defMasterId: null
  }
  const [masterFields, setMasterFields] = useState(initialMaster);
  const handleMasterDropdown = (value, name) => {
    const filteredMaster = tableData.filter(master => master.defMasterName === value)[0]
    if (name === 'defMasterName' && filteredMaster.defFilePath !== null) {
      setValue(2);
    } else if (name === 'defFilePath') {
      if (value === null) {
        setFileget([]);
        setMasterFields({
          ...masterFields,
          defFilePath: null
        });
      } else {
        setValue(2);
        const fileNames = filePath.filter((path) => {
          const segments = path.split("/");
          return segments[segments.length - 1] === value;
        });
        getfileget(fileNames);
      }
    }
    setMasterFields((prev) => ({
      ...prev,
      [name]: value,
      ...(name === 'defMasterName' ?
        filteredMaster.defFilePath !== null ?
          {
            defFilePath: filteredMaster.defFilePath,
            defMasterId: filteredMaster.defMasterId
          } :
          { defFilePath: null, defMasterId: null } :
        {})
    }));
  }

  // const [datalogger, setDatalogger] = useState(dummydata)
  const [tableData, setTableData] = useState([]);
  console.log(masterFields, 'masterFields');

  console.log(snack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Parameter ", path: "ftppget" },
  ];
  /*------------------------- handle save --------------------------------------------*/
  const handleSaveMaster = async () => {
    try {
      setToBackend(true);
      const selectedEqType = stdField?.equipmentType.split(",")[0];
      const equipmenttype = equipmentName?.filter(
        (data) => data.equipmentType === selectedEqType
      );
      const data = {
        equipmentTypeId: equipmenttype[0]?.equipmentTypeId,
        defMasterName: stdField?.defMasterName,
        createdBy: sessionStorage.getItem("id"),
        status: stdField?.status === "Active" ? 1 : 0,
      };
      const responseData = await saveDefMaster(data);
      console.log(responseData, "responseData");

      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: responseData?.statusDescription,
      });
      fetchAllDefMaster();
      getallMasterDropdown();
      handleCloseMaster();
      if (masterFields.defMasterId) {
        setMasterFields({
          defMasterName: stdField?.defMasterName,
        })
      }
      setToBackend(false);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: "Check your data and Try Again Later",
      });
      console.error("Error:s", error.response.data.statusDescription);
      setToBackend(false);
    }
  };
  const handleUpdateMaster = async (values) => {
    console.log(stdField, 'dataValues');
    try {
      setToBackend(true);
      const masterName = values.defMasterName
      const data = {
        equipmentTypeId: values.equipmentTypeId,
        defMasterName: masterName,
        lastUpdatedBy: sessionStorage.getItem("id"),
        status: stdField?.status === "Inactive" ? 0 : 1,
      };
      const responseData = await PutparameterUpdate(values?.defMasterId, data);
      console.log(responseData, 'datadata')

      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: "Def Master Updated Successfully",
      });

      handleCloseMaster();
      fetchAllDefMaster();
      getallMasterDropdown();
      setToBackend(false);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: "Check your data and Try Again Later",
      });
      setToBackend(false);
      console.error("Error:s", error.response.data.statusDescription);
    }
  };
  useEffect(() => {
    getSiteCall();
    // Dummy File Params
    fetchFileParams();
    getallMasterDropdown();
    fetchstandardParameter();
    getCategoryList();
    fetchAllDefMaster();
    // fetchdatalogger();
    getparamName();
    let hardDomain = './';
    let ftpFolder = 'SE00044';
    getFilePath({
      ftpFolder: ftpFolder,
      domain: hardDomain
    });
  }, []);

  const getallMasterDropdown = async () => {
    try {
      setLoading(true);
      const responseData = await fetchMasterDropdown();
      setMasterDropDown(responseData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const fetchAllDefMaster = async () => {
    try {
      setLoading(true);
      const responseData = await getAllDefMaster();
      setTableData(responseData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  // const fetchdatalogger = async () => {
  //   try {
  //     setLoading(true);
  //     const responseData = await getdatalogger();
  //     setDatalogger(responseData);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  // const config = [
  //   {
  //     data: "SMA_16KW.csv",
  //   },
  //   {
  //     data: "SMA_13KW.csv",
  //   },
  // ];
  const StatusName = [
    {
      data: "Active",
    },
    {
      data: "Inactive",
    },
  ];
  const Datalogger = [{ data: "dummy" }];
  const handleDropdownChange = (event, value, name) => {
    if (name === 'equipmentType') {
      const selectedEqType = value.split(",")[0];
      const equipmenttype = equipmentName?.filter(
        (item) => item.equipmentType === selectedEqType
      )
      setstdField({ ...stdField, [name]: value, equipmentTypeId: equipmenttype[0]?.equipmentTypeId, });
      setEditvalue({ ...editValue, [name]: value, equipmentTypeId: equipmenttype[0]?.equipmentTypeId, });
    } else {
      setstdField({ ...stdField, [name]: value });
      setEditvalue({ ...editValue, [name]: value });
    }
  };

  const saveMappingAPICall = async (payload) => {
    try {
      setToBackend(true);
      const responseData = await postDefMappingParams(payload);
      console.log(responseData, 'responseData')
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: "Mapping Saved Successfully",
      });
      setOpenModal(false);
      setCoefficientValue([]);
      setSelectedStdParam([]);
      setMasterFields({ ...masterFields, defFilePath: payload[0].filePath, defMasterId: 1 });
      fetchAllDefMaster();
      setActiveInputIndex(null);
      setToBackend(false);
    } catch (error) {
      console.error(error);
      setToBackend(false);
    }
  }
  const handleSubmitValue = () => {
    const payload = fileMappingParams.map((param, i) => {
      const defMasterId = masterDropDown.find(item => item.defMasterName === masterFields.defMasterName).defMasterId
      const selectedParam = selectedStdParam[i] ? selectedStdParam[i]?.split(",")[0] : "Dummy"
      const stdParam = ParameterName.find(item => item.stdParameterName === selectedParam)
      return {
        "createdBy": Number(sessionStorage.getItem("id")),
        "coEfficient": Number(coefficientValue[i]) || 1,
        "defMasterId": defMasterId,
        "status": 1,
        "stdParamsId": stdParam.stdId,
        "uom": stdParam.stdUom,
        "sequence": i + 1,
        "parameterName": param,
        "filePath": masterFields.defFilePath
      }
    })
    saveMappingAPICall(payload);
  }

  console.log(mappingParams, 'mappingParams');

  const handleUpdateValue = async () => {
    const payload = mappingParams.map((param, i) => ({
      "defMapingId": param.defMappingId,
      "coEfficient": Number(param.coEfficient) || 1,
      "defMasterId": param.defMasterId,
      "stdParamsId": param.stdParamsId,
      "uom": param.uom,
      "lastUpdatedBy": Number(sessionStorage.getItem("id")),
      "status": 1
    })
    );
    try {
      setToBackend(true);
      const responseData = await updateDefMapping(payload);
      console.log(responseData, 'responseData');
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: "Mapping Updated Successfully",
      });
      setOpenModal(false);
      fetchAllDefMaster();
      setMappingParams([]);
      setActiveInputIndex(null);
      setToBackend(false);
    } catch (error) {
      console.error(error);
      setToBackend(false);
    }
  }

  const handleSubmitExcel = async () => {
    const payload = selectedFileData.map((param, i) => {
      let filePath = selectedFile?.name;
      const defMasterId = masterDropDown.find(item => item.defMasterName === masterFields.defMasterName).defMasterId
      const selectedParam = param['Standard Parameter'] ? param['Standard Parameter'] : "Dummy"
      const stdParam = ParameterName.find(item => item.stdParameterName === selectedParam)
      return {
        "createdBy": Number(sessionStorage.getItem("id")),
        "coEfficient": Number(param['CoEfficient']) || 1,
        "defMasterId": defMasterId,
        "status": 1,
        "stdParamsId": stdParam.stdId,
        "uom": stdParam.stdUom,
        "sequence": i + 1,
        "parameterName": param['Parameter Name'],
        "filePath": filePath
      }
    });
    saveMappingAPICall(payload);
  }
  const getSiteCall = async () => {
    try {
      let data = await Equipmenttypedropdown();
      setequipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchstandardParameter = async () => {
    try {
      let data = await GetFileParamCoff();
      setParameterName(data);
      console.log(data);

    } catch (e) {
      console.error(e);
    }
  };



  const FallBack = () => {
    const isLoggerTable = value === 2;

    const forLogger =
      // dataLoggerValue
      //   ? tablemap
      //     ? "No Master data logger configured yet."
      //     : null
      //   :
      "Selected Master is not mapped";

    const forLoggerMaster =
      // site
      //   ? tablevalue
      //     ? "No data logger master configured yet."
      //     : null
      //   : 
      "Select a site to list data logger masters.";

    return isLoggerTable ? forLogger : forLoggerMaster;
  };
  const handleTxtChange = (event, name) => {
    setstdField({ ...stdField, [name]: event.target.value });
    setEditvalue({ ...editValue, [name]: event.target.value });
  };
  const fetchFileParams = async () => {
    try {
      setMappingLoader(true);
      setFileMappingParams(dummyFileParams);
      setMappingLoader(false);
    } catch (e) {
      console.error(e);
      setMappingLoader(false);
    }
  };
  console.log(fileMappingParams, "fileMappingParams");
  const [categoryList, setCategoryList] = useState([]);
  const getCategoryList = async () => {
    try {
      setLoading(true);
      const responseData = await Equipmentcategorydropdown();
      setCategoryList(responseData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  function getOptions(propName) {
    let option = [];
    // console.log(propName);

    switch (propName) {
      case "equipmentType":
        let options = equipmentName.map(option => {
          const filteredCategory = categoryList.find(category => category.categoryId === option.categoryId);
          return `${option.equipmentType}${filteredCategory?.equipmentCategory ? `, (${filteredCategory?.equipmentCategory})` : ''}`
        });

        return options;
      case "parameterName":
        return (option = ParameterName?.map(
          (option) => option.stdParameterName + ", (" + option.stdUom + ")"
        ));
      case "dataloggerName":
        return (option = Datalogger?.map((option) => option.data));
      case "defMasterName":
        return (option = masterDropDown?.map((option) => option.defMasterName));
      case "defFilePath":
        return option = fileNames;
      case "status":
        return (option = StatusName?.map((option) => option.data));
      default:
    }
    return option;
  }
  const handleGetFile = () => {
    setOpenModal(true);
    fetchFileParams();
  };
  const handleClose = () => {
    setOpenModal(false);
    setExcelOpen(false);
    setActiveInputIndex(null);
    setFileMappingParams([]);
    setMappingParams([]);
    setIsEditMapping(false);
    setSelectedStdParam([]);
    setSelectedFile([]);
    setSelectedFileData([]);
    setCoefficientValue([]);
    setActiveInputIndex(null);
  };
  const handleChangee = (newValue) => {
    setValue(newValue);
  };
  const hanldeOpenMaster = () => {
    setOpenMaster(true);
    seteditlog(false);
    // setEditvalue("");
  };
  const handleCloseMaster = () => {
    setstdField(initialState);
    // setEditvalue([]);
    setOpenMaster(false);
  };

  const mappedLoggerData = tableData.filter((item) => item.defFilePath !== null && item.status !== 0);

  const table = value === 0 ? tableData : mappedLoggerData;
  let filteredTable = table.filter((item) => item.defMasterName === masterFields.defMasterName);
  console.log(mappedLoggerData, 'filteredTable');


  const tabsData = [
    { name: " Master Logger", value: 0 },
    { name: "Mapped Logger", value: 2 },
  ];
  const masterHeadCells = [
    {
      label: "Equipmet Type",
      id: "equipmentType",
      view: true,
      default: true,
    },
    {
      label: "Master Name",
      id: "defMasterName",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  const mappedHeadCells = [
    {
      label: "Master Logger",
      id: "defMasterName",
      view: true,
      default: true,
    },
    {
      label: 'Parameter Count',
      id: 'defMappingCount',
      view: true,
      default: true,
    },
    {
      label: "File path",
      id: "defFilePath",
      view: true,
      default: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  let head = value === 2 ? mappedHeadCells : masterHeadCells;

  // const handleTxtChangee = (e, index) => {
  //   const { value } = e.target;
  //   setstdField((prev) => {
  //     const updatedFields = [...prev];
  //     updatedFields[index].coEfficient = value;
  //     return updatedFields;
  //   });
  // };


  // const handleSubmit = async (value) => {
  //   let transformedData = Object.entries(rowValues).map(([key, value]) => ({
  //     parameter: key.trim(),
  //     standardName: value.parameter || "Dummy, (N/A)",
  //     coEfficient: value.coEfficient || "1",
  //   }));
  //   let standardNames = mappingParams?.map((item) =>
  //     typeof item === "object" && item !== null ? item.trim() : item
  //   );
  //   let orderedData = standardNames.map((name) => {
  //     let existingItem = transformedData.find(
  //       (item) => item.parameter.trim() === name.trim()
  //     );
  //     if (existingItem) {
  //       return existingItem;
  //     } else {
  //       return {
  //         parameter: name,
  //         standardName: "Dummy",
  //         coEfficient: "1"
  //       };
  //     }
  //   });
  //   orderedData = orderedData.map((item, index) => {
  //     let uom = "";
  //     if (item.standardName.includes(',')) {
  //       const parts = item.standardName.split(',');
  //       item.standardName = parts[0].trim();
  //       uom = parts[1].trim();
  //     }
  //     item.uom = uom;
  //     item.index = index + 1;
  //     return item;
  //   });
  //   try {
  //     const mster = masterDropDown.filter((data) => {
  //       return data.defMasterName === stdField?.masterNamedata

  //     });
  //     console.log(mster)

  //     const dataToSend = orderedData.map((item) => {
  //       const stadard = ParameterName.filter((data) => data.stdParameterName === item.standardName);
  //       const stdId = stadard.length > 0 ? stadard[0].stdId : null;

  //       return {
  //         createdBy: Number(sessionStorage.getItem("id")),
  //         status: 1,
  //         coEfficient: item.coEfficient,
  //         defMasterId: mster[0]?.defMasterId,
  //         stdParamsId: stdId,
  //         uom: item.uom,
  //         sequence: item.index,
  //         parameterName: item.parameter,
  //         filePath: "/path/to/file1"
  //       };
  //     });
  //     const savedData = await postmasterparameterdata(dataToSend);
  //     setSnack({
  //       open: true,
  //       severity: "success",
  //       message: savedData.data.statusDescription,
  //     })
  //     handleClose()
  //   } catch (error) {
  //     setSnack({
  //       ...snack,
  //       open: true,
  //       severity: "error",
  //       message: "Check your data and Try Again Later",
  //     });
  //   }
  //   console.log("Ordered Data:", orderedData);
  // };

  console.log(editValue, 'editValues');
  const fetchMappingByMasterId = async (id) => {
    try {
      setMappingLoader(true);
      const responseData = await GetMappingByMaster(id);
      const sortedResponseData = responseData.sort((a, b) => a.sequence - b.sequence);
      if (Array.isArray(sortedResponseData)) {
        setMappingParams(sortedResponseData);
      } else if (typeof sortedResponseData === "object" && sortedResponseData !== null) {
        setMappingParams([sortedResponseData]);
      }
      setMappingLoader(false);
    } catch (error) {
      console.error(error);
      setMappingLoader(false);
    }
  }
  const handleEditOpen = async (val) => {
    let filteredsectorId = Object.keys(initialState).reduce((acc, key) => {
      if (val.hasOwnProperty(key)) {
        acc[key] = val[key];
      }
      if (acc['equipmentTypeId'] === val.equipmentTypeId) {
        const TypeFilter = equipmentName.reduce((result, data) => {
          if (acc['equipmentTypeId'] === data.equipmentTypeId) {
            const category = categoryList.find((cat) => cat.categoryId === data.categoryId);
            if (category) {
              result = category;
            }
          }
          return result;
        }, null);
        // console.log(key, 'filteredsectorIdfilteredsectorId');
        acc['equipmentType'] = `${val.equipmentType}${TypeFilter ? `, (${TypeFilter.equipmentCategory})` : ''}`;
        acc['equipmentTypeId'] = val.equipmentTypeId;

      }
      return acc;
    }, {});
    console.log(filteredsectorId, 'filteredsectorIdfilteredsectorId');


    if (value === 2) {
      setOpenModal(true);
      fetchMappingByMasterId(filteredsectorId.defMasterId);
      setIsEditMapping(true);
    } else {
      setOpenMaster(true);
      setstdField(filteredsectorId);
      seteditlog(true);
      setEditvalue(val);
    }

    // const updatedRowValues = dummydata.reduce((acc, item) => {
    //   const datastatus = Array.isArray(ParameterName)
    //     ? ParameterName.filter((data) => data.stdId === item.stdParamsId)
    //     : [];
    //   console.log(datastatus);

    //   acc[item.parameterName] = {
    //     parameter: datastatus.length > 0 ? datastatus[0].stdParameterName : "N/A",
    //     coEfficient: item.coEfficient,
    //   };

    //   return acc;
    // }, {});

    // setRowValues(updatedRowValues);
  };

  // const handleViewMapping = async (val) => {

  // // }
  // // const handleEquipmentUnits = (index, col, value, row) => {
  // //   // let stdvalue = standardParams?.filter((data) => {
  // //     const splitValue = value.split(",");
  // //     const trimmedValue = splitValue[0].trim();
  // //     if (splitValue.length === 1) {
  // //       return data.standardparametername === trimmedValue;
  // //     } else {
  // //       return data.standardparametername === trimmedValue;
  // //     }
  // //   });
  //   const updatedUnit = [...stdValue];
  //   const updatedStdParamId = [...stdParamId];
  //   updatedUnit[index] = stdvalue[0]?.standardparameteruom;
  //   updatedStdParamId[index] = stdvalue[0]?.standardid;
  //   setStdValue(updatedUnit);
  //   setStandardParamsId(updatedStdParamId);
  // };
  const handleStdParChange = useCallback((index, value) => {
    if (isEditMapping) {
      setMappingParams((prevRows) => {
        const updatedRows = [...prevRows];
        const stdPar = value?.split(",");
        updatedRows[index].stdParameterName = stdPar[0].trim();
        updatedRows[index].uom = stdPar[1].trim().replace(/[()]/g, "");
        const paramId = ParameterName.filter(param => param.stdParameterName === stdPar[0].trim())[0]?.stdId;
        updatedRows[index].stdParamsId = paramId;
        return updatedRows;
      })
    } else if (excelOpen) {
      setSelectedFileData((prevRows) => {
        const updatedRows = [...prevRows];
        const stdPar = value?.split(",");
        updatedRows[index]['Standard Parameter'] = stdPar[0].trim();
        updatedRows[index]['Std Unit'] = stdPar[1].trim().replace(/\s/g, "");
        return updatedRows;
      })
    } else {
      setSelectedStdParam((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index] = value;
        return updatedRows;
      });
    }

    if (value !== null) {
      setActiveInputIndex(index);
      inputRefs.current[index] = inputRefs.current[index] || React.createRef();
      inputRefs.current[index].focus();
    } else {
      setActiveInputIndex(-1);
    }
  }, [excelOpen, isEditMapping]);
  console.log(selectedFileData, isEditMapping, 'isEditMapping', fileMappingParams, mappingParams, 'mappingParams');
  const handleCoeffChange = useCallback((index, value) => {
    const validValue = value?.replace(/(?!^-)[^0-9.]/g, "");
    if (isEditMapping) {
      setMappingParams((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].coEfficient = validValue;
        return updatedRows;
      })
    } else if (excelOpen) {
      setSelectedFileData((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index]['CoEfficient'] = validValue;
        return updatedRows;
      })
    } else {
      setCoefficientValue((prevCoefficientValues) => {
        const updatedCoefficientValues = [...prevCoefficientValues];
        updatedCoefficientValues[index] = validValue;
        return updatedCoefficientValues;
      });
    }
    setActiveInputIndex(index);
    inputRefs.current[index] = inputRefs.current[index] || React.createRef();
    inputRefs.current[index].focus();

  }, [excelOpen, isEditMapping]);

  const MemoizedTableRow = React.memo(({ row, index }) => (
    <StyledTableRow className="divider" key={index}>
      <StyledTableCell align="center" className="cell-with-border">
        {index + +1}
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border" sx={{ width: 300 }}>
        {!isEditMapping ? excelOpen ? row['Parameter Name'] : row : row['parameterName'] || ''}
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border" sx={{ width: 350 }}>
        <Tooltip title={!isEditMapping ? excelOpen ? `${row['Standard Parameter'] || ''}, ${row['Std Unit'] ? `(${row['Std Unit']})` : ''}` : selectedStdParam[index] : `${row['stdParameterName'] || ''}${row['uom'] ? `, (${row['uom']})` : ''}`}>
          <Autocomplete
            disablePortal
            id={`combo-box-demo-${index}`}
            value={!isEditMapping ? excelOpen ? `${row['Standard Parameter'] || ''}, ${row['Std Unit'] ? `(${row['Std Unit']})` : ''}` : selectedStdParam[index] : `${row['stdParameterName'] || ''}${row['uom'] ? `, (${row['uom']})` : ''}`}
            options={
              paramCoff.map(
                (data) =>
                  `${data.stdParameterName}${data.stdUom ? `, (${data.stdUom})` : ""
                  }`) ||
              []
            }
            defaultValue={!isEditMapping ? excelOpen ? `${row['Standard Parameter'] || ''}, ${row['Std Unit'] ? `(${row['Std Unit']})` : ''}` : '' : `${row['stdParameterName']} , (${row['uom']})`}
            onChange={(event, value) => handleStdParChange(index, value, row)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Standard Params"
                sx={{ align: "center" }}
              />
            )}
          />
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border">
        <TextField
          id={`coefficient-input-${index}`}
          placeholder="Co-Efficient"
          variant="standard"
          value={!isEditMapping ? !excelOpen ? coefficientValue[index] : row['CoEfficient'] : row['coEfficient']}
          autoFocus={activeInputIndex === index}
          inputRef={(ref) => {
            inputRefs.current[index] = ref;
          }}
          onChange={(e) => handleCoeffChange(index, e.target.value)}
        />
      </StyledTableCell>
    </StyledTableRow >
  ));

  return (
    <>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      <div>
        <Box
          sx={{
            margin: "0 0.5% 0 0.5%",
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              mr: 0,
              mb: 1,
              // mt: 2,
              borderRadius: 0,
              width: "100%",
              height: "7.5vh",
              overflow: "visible",
            },
          }}
        >
          <Card
            elevation={0}
            style={{
              display: "flex",
              width: "101%",
              height: "6.5vh",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomBreadcrumbs
                paths={paths || []}
                separatorSize="18px"
                fontSize="14px"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <Stack spacing={2} direction="row">
                <Box sx={{ width: "100%" }}>
                  <TabsComponent
                    isLogger={true}
                    loggerState={value}
                    tabs={tabsData}
                    onTabChange={(val) => handleChangee(val)}
                  />
                </Box>

                <Tooltip
                  key={"add master"}
                  title={"Add New Master"}
                  userSelect="none"
                  followCursor
                  arrow
                >
                  <IconButton
                    variant="text"
                    style={{
                      textTransform: "capitalize",
                      color: "#370c49",
                      padding: "0",
                      fontSize: "40px",
                      borderRadius: "20px 20px 20px 20px",
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  // onClick={(event) => handleClick(data.value, event)}
                  >
                    <AddCircleOutlineSharpIcon
                      onClick={hanldeOpenMaster}
                      style={{ color: "#004AFF", fontSize: "20px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </div>
          </Card>
        </Box>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "100%", // Ensures it takes full width
              height: "auto", // Adjusts height dynamically
              padding: "1%", // Adds spacing
            },
          }}
        >
          <Card
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "row", // Ensures stacked layout
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack spacing={2} direction="row" justifyContent={"flex-start"}>
              <div>
                <Typography className="modal-typo">Master Logger</Typography>
                <Tooltip
                  title={masterFields.defMasterName}
                >
                  <Autocomplete
                    disableClearable
                    disablePortal
                    size="small"
                    options={getOptions("defMasterName")}
                    onChange={(event, value) => handleMasterDropdown(value, "defMasterName")}
                    sx={{ width: "15vw", mt: 1 }}
                    value={masterFields.defMasterName}
                    renderInput={(params) => <TextField {...params} placeholder={"SG 125kw"} />}
                  />
                </Tooltip>
              </div>
              {/* File Path Dropdown */}
              <div>
                <Typography className="modal-typo">File Path</Typography>
                <Tooltip
                  title={masterFields.defFilePath}
                >
                  <Autocomplete
                    disablePortal
                    disabled={masterFields.defMasterId}
                    size="small"
                    options={getOptions("defFilePath") || []}
                    onChange={(event, value) => handleMasterDropdown(value, "defFilePath")}
                    sx={{ width: "18vw", mt: 1 }}
                    value={masterFields.defFilePath || ""}
                    renderInput={(params) => <TextField {...params} placeholder={"LnT_Wega.ini"} />}
                  />
                </Tooltip>
              </div>
              <div style={{ display: "flex", alignItems: "end" }}>
                <Button
                  variant="contained"
                  disabled={!masterFields.defMasterName || !masterFields.defFilePath || masterFields.defMasterId}
                  sx={{
                    width: "12vw",
                    textTransform: "capitalize",
                    borderRadius: "19px",
                    height: "40px",
                  }}
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={handleGetFile}
                >
                  Get File
                </Button>
              </div>
              <div style={{ display: "flex", alignItems: "end" }}>
                <Button
                  variant="contained"
                  // disabled={!masterFields.defMasterName || !masterFields.defFilePath || masterFields.defMasterId}
                  sx={{
                    width: "12vw",
                    textTransform: "capitalize",
                    borderRadius: "19px",
                    height: "40px",
                  }}
                  startIcon={<SaveAltIcon />}
                  onClick={() => handleDownload()}
                >
                  Template
                </Button>
              </div>
              <div style={{ display: "flex", alignItems: "end" }}>
                <Button
                  variant="contained"
                  disabled={!masterFields.defMasterName || masterFields.defFilePath}
                  sx={{
                    width: "12vw",
                    textTransform: "capitalize",
                    borderRadius: "19px",
                    height: "40px",
                  }}
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => handleUpload()}
                >
                  <input
                    hidden
                    type="file"
                    ref={fileInputRef}
                    required
                    onChange={handleInput}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />

                  Upload Excel
                </Button>
              </div>
            </Stack>
          </Card>
        </Box>

      </div>

      <div>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={Openmadal}
          onClose={handleClose}
          closeAfterTransition
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "75vw",
                // height: "80vh",
                marginLeft: "15vw",
                marginTop: "23vh",
              },
            }}
          >
            <Card
              elevation={2}
              style={{ marginTop: "5%", borderRadius: "20px" }}
            >
              <CardHeader
                sx={{
                  padding: "20px 20px 20px 20px",
                  // background: CheckForLight()
                  //   ? "rgb(246, 248, 252)"
                  //   : "#4f4f4f",
                }}
                action={
                  <Stack spacing={2} direction="row">
                    <Button
                      startIcon={<CancelIcon />}
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        borderRadius: "19px",
                      }}
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>
                  </Stack>
                }
                title="Data Logger"
              />
              <Divider style={{ borderColor: "#888" }} />
              {
                mappingLoader ?
                  (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                        width: "75vw",
                        height: "30vh",
                      }}>
                      <FadeLoader color="#000440" loading={true} />
                    </div>)
                  :
                  (<TableContainer
                    sx={{
                      width: "75vw",
                      height: "58vh",
                      marginTop: "2vh",
                      // marginLeft: '5vw',
                    }}
                    component={Paper}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead style={{ marginBottom: "9vh" }}>
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            style={{ color: "black" }}
                          >
                            {" "}
                            <Typography className="modal-typo">S.No</Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: "black" }}
                          >
                            {" "}
                            <Typography className="modal-typo">
                              Parameter Name
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: "black" }}
                          >
                            {" "}
                            <Typography className="modal-typo">
                              Standard Parameter
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: "black" }}
                          >
                            {" "}
                            <Typography className="modal-typo">
                              Coefficient
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {(!isEditMapping ? excelOpen ? selectedFileData : fileget : mappingParams)?.map((row, index) => (
                          <MemoizedTableRow
                            key={index}
                            row={row}
                            index={index}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>)
              }
              <Divider style={{ borderColor: "#888" }} />
              <div className="submit" style={{}}>
                <Button
                  variant="contained"
                  style={{
                    width: "8vw",
                    marginLeft: "25vw",
                    marginTop: "-1vh",
                    marginBottom: "2vh",
                    borderRadius: "18px",
                    textTransform: "capitalize",
                  }}
                  startIcon={
                    toBackend ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={toBackend}
                  onClick={!isEditMapping ? excelOpen ? handleSubmitExcel : handleSubmitValue : handleUpdateValue}
                >
                  {isEditMapping && !toBackend ? "Update" : isEditMapping && toBackend ? "Updating" : !isEditMapping && !toBackend ? "Save" : "Saving..."}
                </Button>
              </div>
            </Card>
          </Box>
        </Modal>
      </div >
      <div>
        <Formik
          key={editlog ? "edit" : "create"}
          enableReinitialize={true}
          initialValues={stdField}
          validationSchema={MasterSchema}
          onSubmit={async (values, { resetForm }) => {
            let submitValue = values;
            if (editlog) {
              const result = await handleUpdateMaster(submitValue);
              if (result === 1) {
                resetForm();
              }
            } else {
              const result = await handleSaveMaster(submitValue);
              if (result === 1) {
                resetForm();
              }
            }
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset }) => (
            <Form>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openMaster}
                // onClose={() => {
                //   handleCloseMaster();
                //   handleReset();
                // }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={openMaster}>
                  <Box
                    className={"styleModal"}
                    // sx={{ bgcolor: 'background.paper' }}
                    sx={{
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "20px",
                        // height: '68vh',
                        height: "fit-content",
                      }}
                    >
                      <CardHeader
                        sx={{
                          padding: "20px 20px 20px 20px",
                        }}
                        action={
                          <Stack spacing={2} direction="row">
                            {editlog ? (
                              <Button
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                disabled={toBackend}
                                type={"submit"}
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "19px",
                                }}
                                onClick={() => handleSubmit()}
                              >
                                {!toBackend ? "Update" : "Updating..."}
                              </Button>
                            ) : (
                              <Button
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                disabled={toBackend}
                                type={"submit"}
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "19px",
                                }}
                                onClick={() => handleSubmit()}
                              >
                                {!toBackend ? "Save" : "Saving..."}
                              </Button>
                            )}
                            <Button
                              startIcon={<CancelIcon />}
                              color="primary"
                              variant="contained"
                              style={{
                                marginLeft: "10px",
                                textTransform: "capitalize",
                                borderRadius: "19px",
                              }}
                              onClick={() => {
                                handleCloseMaster();
                                handleReset();
                              }}
                            >
                              Close
                            </Button>
                          </Stack>
                        }
                        title={editlog ? "Update Master" : "Add New Master"}
                      />
                      <Divider style={{ borderColor: "#888" }} />

                      <div style={{ marginLeft: "2%", marginTop: "2%" }}>
                        <Card
                          sx={{
                            overflowY: "visible",
                            marginLeft: "-2%",
                            // paddingLeft: '-1vw',
                            marginTop: "-2%",
                            paddingBottom: "2%",
                            scrollBehavior: "smooth",
                            scrollbarGutter: "stable",
                            scrollbarWidth: "thin",
                            "&::-webkit-scrollbar": {
                              width: "0.4em",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "20px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555",
                            },
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ paddingLeft: "2.5%" }}>
                            <Grid
                              container
                              spacing={2}
                              rowSpacing={2}
                              columns={16}
                            >
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Equipment Type
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Tooltip
                                    title={
                                      stdField.equipmentType ? stdField.equipmentType : ""
                                    }>
                                    <Field
                                      name={"equipmentType"}
                                      render={({ field, form }) => (
                                        <Autocomplete
                                          disableClearable
                                          options={getOptions("equipmentType")}
                                          value={stdField.equipmentType}
                                          onChange={(event, value) => {
                                            handleDropdownChange(
                                              event,
                                              value,
                                              "equipmentType"
                                            );
                                          }}
                                          size="small"
                                          sx={{ width: "12vw" }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Solis 80k-4G"
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      name={"equipmentType"}
                                      component="div"
                                      className="errorStyle"
                                      style={{
                                        color: "red",
                                        marginTop: "1%",
                                        textAlign: "left",
                                        marginLeft: "0%",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Master Name
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Field
                                    as={TextField}
                                    size="small"
                                    variant="outlined"
                                    name="defMasterName"
                                    value={editlog ? editValue.defMasterName : stdField.defMasterName}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleTxtChange(e, "defMasterName");
                                    }}
                                    sx={{ width: "12vw" }}
                                    placeholder="Enter Master Name"
                                  />
                                  <ErrorMessage
                                    name={"defMasterName"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Status
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Field
                                    name={"siteName"}
                                    render={({ field, form }) => (
                                      <Autocomplete
                                        disableClearable
                                        disabled={toBackend || !editlog}
                                        options={getOptions("status")}
                                        defaultValue={editlog ? editValue.status == 1 ? "Active" : "Inactive" : "Active"}
                                        value={editlog ? editValue.status == 1 || editValue.status == "Active" ? "Active" : "Inactive" : stdField.status}
                                        onChange={(event, value) => {
                                          handleDropdownChange(
                                            event,
                                            value,
                                            "status"
                                          );
                                        }}
                                        size="small"
                                        sx={{ width: "12vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                          // placeholder="E.g: IFP"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={"status"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Card>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <div>
            <TableTemplate
              addButton={"datalogger"}
              header={head}
              rowsValue={masterFields.defMasterName ? filteredTable : table}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              userRole={Report[0]}
              fallbackText={FallBack()}
            />
          </div>
        )}
      </div>
    </>
  );
}
