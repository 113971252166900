import axios from "axios";

export const getAllPOs = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_PO_LIST}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const postSubscriptionMaster = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_MASTER_POST}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};
export const fetchsubscriptionTable = async (siteId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_TABLE}/${siteId}`
    );
    console.log(response, "API Response");
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription table data:", error);
    throw error;
  }
};
export const putSubscriptionEdit = async (data, subDetailsId) => {
  console.log(subDetailsId);
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_UPDATE}/${subDetailsId}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const putSubscriptionDelete = async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SUBSCRIPTION_DELETE}/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchSiteDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITELIST_BYUSER
      }/${sessionStorage.getItem("id")}`
    );
    console.log(response, "resssss");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchCompanyDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_SUBSCRIPTION_MASTER_COMPANY}`
    );
    console.log(response, "resssss");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const FetchSubscriptionType = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FETCH_SUBSCRIPTION}`
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
export const GetCustomerdropdowndata = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USERCONFIG_CUSTOMERLIST}/${Id}`
    );
    console.log(response.data, "API Response");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
