import React, { useState, useEffect, useMemo } from "react";
import "./AnalyticStyle.css";
import moment from "moment";
import {
  fetchDailyGenerationEira,
  fetchEnergyPerformanceEira,
  fetchParameterComparisonEira,
  fetchSpecificYieldEira,
  fetchStringCurrentEira,
  fetchStringAverage,
  fetchPvDgGridEira,
  DeemedGenrator,
  GetSetPoint,
  ActualVsExpected,
  GetSpecificYieldVsIrradiationSite,
  GetSpecificYieldVsIrradiationEqp,
  GetPRData,
  GetExpectedPercentage,
  getTotalActivePower,
  GetRevenueGen,
  CO2Avioded,
  GetPRDataForEqp,
  fetchExportTotalEnergy,
} from "../../../Api/DataAnalystApi";
import CombinedChart from "../../Charts/CombainedChart";
import FadeLoader from "react-spinners/FadeLoader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Menu,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  Divider,
  MenuItem,
  ListItemText,
  Checkbox,
  Grid,
  IconButton,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Radio,
  Tooltip,
  Badge,
  Popover,
  SwipeableDrawer,
  ClickAwayListener,
  InputAdornment,
} from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import dayjs from "dayjs";
/*------------------------- External compoenents ---------------------------*/
import Apexchart from "./ApexexChart";
import BarChart from "../../Charts/BarChart";
import AreaChart from "../../Charts/AreaChart";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import {
  removeAfterTime,
  removeQuestionMark,
  roundedUp,
  getUniqueDataLoggerIds,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import {
  adjustDateRange,
  convertLast7DaysFlagToDateRange,
  convertMonthFlag,
  convertWeekFlagToDateRange,
  getCumYearFlag,
  getLast7DaysFlag,
  getMonthFlag,
  getWeekOfMonth,
  getYearFlag,
  setCumYearlyRange,
  setYearlyRange,
} from "../../util/CustomDateRange";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from "../../util/security/Cipher";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import { HeatmapChart } from "../../Charts/HeatmapChart";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CheckForLight } from "../../util/common/CommanFunction";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { DateRange } from "react-date-range";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ConstructionIcon from "@mui/icons-material/Construction";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { styled, lighten, darken } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { BlockSiteChart } from "../../Charts/BlockSiteChart";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  zIndex: 100,
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
  fontSize: "14px",
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const timer = [
  { label: "1", value: "1" },
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  // { label: 'Today', value: 'Today' },
  // { label: 'Yesterday', value: 'Yesterday' },
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" },
  { label: "This Month", value: "This Month" },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
  { label: "Custom range", value: "custom" },
];

const DeviationOption = [
  { label: "This Month", value: "This Month" },
  { label: "Yearly", value: "Yearly" },
];

const dailychartType = [
  { label: "Line Chart", value: "daily" },
  { label: "Bar Chart", value: "custom" },
];
const chartType = [
  { label: "Daily Generation", value: "daily_generation" },
  { label: "Specific Yield", value: "specific_yield" },
  { label: "Specific Yield - Heatmap", value: "specific_yield_heatmap" },
  {
    label: "Specific Yield vs Irradiation",
    value: "specific_yield_vs_irradiation",
  },
  { label: "Energy Performance", value: "energy_performance" },
  { label: "Actual vs Expected", value: "ActualVsExcepted" },
  { label: "CO2 Avoidance", value: "co2_avoidance" },
  { label: "DG PV Grid Management", value: "dgpv" },
  { label: "Deemed Generation", value: "deemed_generation" },
  { label: "Set Point", value: "setpoint" },
  { label: "String Current", value: "string_current" },
  { label: "String Current - Heatmap", value: "string_current - heatmap" },
  { label: "String Current - Average", value: "string_current - heatmap" },
  { label: "Active Power", value: "active_power" },
  { label: "Total Active Power", value: "total_active_power" },
  { label: "Performance Ratio", value: "performance_ratio" },
  { label: "Expected Deviation", value: "expected_deviation" },
  { label: "Revenue Generation", value: "revenue_generation" },
  { label: "Export Total Energy", value: "export_total_power" },
];

const basedOnOptions = [
  { label: "Site", value: "site" },
  { label: "Equipment", value: "equipment" },
];

const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  lineHeight: "14.52px !important",
  color: " rgb(113, 118, 111)",
};

export const ViewAnalytics = () => {
  const [site, setSite] = React.useState("");
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setEquipmentParamName] = React.useState([]);
  const [equipmentParamwithUnits, setParameter] = useState([]);
  const [axisData, setAxisData] = useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [chartName, setChartName] = useState("");
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [ToDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [range, setRange] = useState("");
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [selectAllEquipments, setSelectAllEquipments] = useState(false);
  const [selectAllEquipmentParam, setSelectAllEquipmentParam] = useState(false);
  const [chartFromDate, setChartFromDate] = useState("");
  const [chartToDate, setChartToDate] = useState("");
  const [value, setValue] = useState(0);
  const [customView, setCustomView] = useState();
  const [timevalue, setTimeValue] = useState("Daily");
  const [chartSite, setChartSite] = useState();
  const [dataModel, setDataModel] = useState();
  const [fromApply, setApply] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [sdaysFlag, setSdaysFlag] = useState(getLast7DaysFlag(new Date()));
  const [WeeklyFlag, setWeeklyFlag] = useState(getWeekOfMonth(new Date()));
  const [MonthlyFlag, setMonthlyFlag] = useState(getMonthFlag(new Date()));
  const [YearlyFlag, setYearlyFlag] = useState(getYearFlag(new Date()));
  const [cumYearFlag, setCumYearFlag] = useState(getCumYearFlag(new Date()));
  const [loading, setLoading] = useState(false);
  const [minToDate, setMinToDate] = useState([]);
  const [chartDropdown, setChartDropdown] = useState(true);
  const [fromTabSwitch, setFromTabSwitch] = useState(true);
  const [componentLoading, setComponentLoading] = useState(false);
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [menuOpen, setOpenMenu] = useState(false);
  const [menuOpenn, setOpennMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [timerValue, setTimerValue] = useState();
  const [timerOptions, setTimerOptions] = useState(timer);
  const [optionalCharts, setOptionalCharts] = useState(false);
  const [buttonLock, setButtonLock] = useState(true);
  const [calenderAnchor, setCalenderAnchor] = useState(null);
  const [rangeLevel, setRangeLevel] = useState("today");
  const [RangeState, setRangeState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [chartAnchor, setChartAnchor] = useState(null);
  const [dailyChartType, setDailyChartType] = useState("daily");
  const [customChart, setCustomChart] = useState(false);
  const [basedOn, setBasedOn] = useState("Site");

  const optionToUse =
    chartName === "Expected Deviation" ? DeviationOption : optionTime;

  function openCalender(event, from) {
    // console.log(event, from);
    if (from === "fromCalender") {
      setCalenderAnchor(event.currentTarget);
      setTimeValue("custom");
    } else {
      setCalenderAnchor(anchorEl);
      setOpenMenu(false);
      setAnchorEl(null);
    }
  }

  function closeCalender() {
    setCalenderAnchor(null);
  }

  const isCalendarOpen = Boolean(calenderAnchor);
  const calenderId = isCalendarOpen
    ? "simple-popover-date-range-picker"
    : undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;
  const paths = isFromSiteList
    ? [
        { label: "Home", path: "/menu" },
        { label: "Site List", path: -1 },
        { label: "View Analytics", path: "view-analyst" },
      ]
    : [
        { label: "Home", path: "/menu" },
        { label: "View Analytics", path: "view-analyst" },
      ];
  function ResetDateRange(freq) {
    setTimerValue(freq);
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  }

  const [isOpen, setIsOpen] = useState(false);

  //Components list
  // State to manage which inv and params are checked
  const [checkedItems, setCheckedItems] = useState({});

  // State to manage open/close state for both categories and equipment
  const [openCategoryLists, setOpenCategoryLists] = useState({});
  const [openEquipmentLists, setOpenEquipmentLists] = useState({});
  const [paramsListState, setParamsliststate] = useState({});
  const [selectedParams, setSelectedParams] = useState({});

  // Function to toggle the open/close state of a category list
  const toggleCategoryListOpen = (category) => {
    setOpenCategoryLists((prevState) => {
      setIsOpen(prevState[category] !== true);
      return {
        ...prevState,
        [category]: !prevState[category],
      };
    });
  };

  // Function to toggle the open/close state of an equipment list within a category
  const toggleEquipmentListOpen = (equ) => {
    setOpenEquipmentLists((prevState) => ({
      ...prevState,
      [equ]: !prevState[equ],
    }));
  };

  // Function to toggle the open/close state of an paramater list within a category
  const toggleParamsListOpen = (cat, flag, params, equipmentByCat) => {
    if (flag) {
      const allFlag = cat + "All";
      setParamsliststate((prevState) => {
        if (prevState[allFlag] !== true) {
          setSelectedParams((prevState) => ({
            ...prevState,
            [cat]: params,
          }));
          updateCheckedItems(cat, params, equipmentByCat);
        } else {
          setSelectedParams((prevState) => ({
            ...prevState,
            [cat]: [],
          }));
          updateCheckedItems(cat, [], equipmentByCat);
        }
        return {
          ...prevState,
          [allFlag]: !prevState[allFlag],
          [cat]: true,
        };
      });
    } else {
      setParamsliststate((prevState) => ({
        ...prevState,
        [cat]: !prevState[cat],
      }));
    }
  };

  //function to control selected params
  const handleParamSelect = (equ, param, equipmentByCat) => {
    setSelectedParams((prevState) => {
      // Check if the equipment already exists in the state
      if (prevState[equ]) {
        // Check if the parameter already exists in the array
        const paramExists = prevState[equ].includes(param);
        const updatedParams = paramExists
          ? prevState[equ].filter((p) => p !== param) // Remove the param if it exists
          : [...prevState[equ], param]; // Add the param if it doesn't exist
        updateCheckedItems(equ, updatedParams, equipmentByCat);
        return {
          ...prevState,
          [equ]: updatedParams,
        };
      }
      updateCheckedItems(equ, param, equipmentByCat);
      // Initialize the equipment with the selected parameter if not present
      return {
        ...prevState,
        [equ]: [param],
      };
    });
  };

  const updateCheckedItems = (groupName, param, equipmentByCat) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };

      // Get the equipment list for the given group name
      const equipmentList = equipmentByCat[groupName] || [];

      equipmentList.forEach((equipment) => {
        if (!updatedCheckedItems[equipment]) {
          updatedCheckedItems[equipment] = { checked: false, params: [] };
        }

        if (Array.isArray(param)) {
          // Replace params if param is an array
          updatedCheckedItems[equipment].params = param;
        } else {
          // Add param to params if param is a string
          if (!updatedCheckedItems[equipment].params.includes(param)) {
            updatedCheckedItems[equipment].params = [
              ...updatedCheckedItems[equipment].params,
              param,
            ];
          }
        }
      });

      return updatedCheckedItems;
    });
  };

  function convertToUniqueOptions(data) {
    const optionsArray = []; // Iterate over each category in the input object
    for (const category in data) {
      if (data.hasOwnProperty(category)) {
        data[category].forEach((value) => {
          optionsArray.push({ option: value, category: category });
        });
      }
    }
    return optionsArray;
  }

  function getCategoriesByParameter(data, parameter) {
    const categories = [];

    for (const category in data) {
      if (data.hasOwnProperty(category)) {
        const values = data[category];
        if (values.includes(parameter)) {
          categories.push(category);
        }
      }
    }

    return categories;
  }

  //chart Type list
  const [selectedOptions, setSelectedOptions] = useState("Daily Generation");
  //Equipment list
  const [selectedEquipments, setSelectedEquipments] = useState([]);

  const handleChartCheckboxChange = (event, option) => {
    const Predefined = [
      "Daily Generation",
      "Energy Performance",
      "Specific Yield",
      "Specific Yield - Heatmap",
      "DG PV Grid Management",
      "Deemed Generation",
      "Set Point",
    ];
    if (event.target.checked) {
      // setBasedOn('Site');
      setSelectedOptions(option);
      ResetDateRange(timerValue);
      setChartName(option);
      setSelectedOption(option);
      setFromTabSwitch(false);
      setChartDropdown(false);
      setCustomChart(false);
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setCumYearFlag(getCumYearFlag(new Date()));
      setTimeValue(option !== "Expected Deviation" ? "Daily" : "This Month");
      setDailyChartType(
        chartName === "Specific Yield" ||
          option === "Specific Yield vs Irradiation"
          ? "custom"
          : "daily"
      );
      setSelectedEquipments([]);
      setOptionalCharts(
        !Predefined?.includes(option) && option !== "Active Power"
      );
      let customData;
      if (
        option === "Energy Performance" ||
        option === "String Current - Heatmap" ||
        option === "String Current - Average"
      ) {
        let displayName = equipments
          .filter((data) => {
            return data.equipmentCategory.includes("Inverter");
          })
          .map((datav) => {
            return datav.displayName;
          })
          .sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });
        setEquipmentData(displayName);
        setSelectedEquipments(displayName);
      } else if (!Predefined?.includes(option)) {
        customData = customCharts(option);
        if (option === "String Current") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName[0]);
        } else if (option === "Active Power") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName);
        }
      }
      setDatalogValue([]);
      setTableData([]);
      setValue();
      handleApplyChanges(
        option !== "Expected Deviation" ? "Daily" : "This Month",
        false,
        "timeValue",
        option
      );
    } else {
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setCumYearFlag(getCumYearFlag(new Date()));
      setTimeValue("Daily");
      setSelectedOptions("Daily Generation");
      setChartName("Daily Generation");
      setSelectedOption("Daily Generation");
      handleApplyChanges("Daily", false, "timeValue", "Daily Generation");
    }
  };

  function customCharts(event) {
    if (event === "String Current" || event === "Active Power") {
      console.log(groupByCategory(equpAndParams), equpAndParams);
      // Retrieve the keys from grouped data
      const categories = Object.keys(groupByCategory(equpAndParams));

      let SortedInvNames = [];
      categories.forEach((key) => {
        // Check if the key contains 'inverter'

        if (
          key.toLocaleLowerCase()?.includes("inverter") ||
          key.toLocaleLowerCase()?.includes("inv")
        ) {
          // Get the list of inverters in this category
          const invList = groupByCategory(equpAndParams)[key];
          const InvNames = [];

          invList.forEach((inv) => {
            InvNames.push(inv.equ);
          });

          // Sort the inverter names
          const sortedNames = InvNames.sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });

          // Accumulate the sorted names
          SortedInvNames = SortedInvNames.concat(sortedNames);
        }
      });

      // Set the equipment data with the sorted inverter names
      setEquipmentData(SortedInvNames);

      return SortedInvNames;
    }
  }

  const [panelIndex, setPanelIndex] = useState("1");
  const [equpAndParams, setEqupAndParams] = useState([]);

  const handleChangePanel = (event, newValue) => {
    if (newValue === "1") {
      setChartName("Daily Generation");
      setSelectedOptions("Daily Generation");
      setSelectedOption("Daily Generation");
      handleTabChange(site, "Daily", "daily", "Daily Generation");
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      setSelectedParams({});
      setParamsliststate({});
      setIsOpen(false);
      ResetDateRange(timerValue);
    } else {
      setChartName("Parameter Comparision");
      setDate(moment().format("YYYY-MM-DD"));
      setTimeValue("Daily");
      setSelectedOptions("Parameter Comparision");
      setSelectedOption("Parameter Comparision");
      setDatalogValue([]);
      ResetDateRange(timerValue);
    }
    setPanelIndex(newValue);
  };

  const handleCheckboxChange = (equ) => (event) => {
    setCheckedItems((prevState) => {
      // Check if the equipment already exists in the state
      if (!prevState[equ]) {
        return {
          ...prevState,
          [equ]: {
            checked: true, // Initialize the checkbox as unchecked
            params: [], // Initialize params as an empty array
          },
        };
      }

      // If it already exists, return the previous state and change the checkbox state
      return {
        ...prevState,
        [equ]: {
          ...prevState[equ],
          checked: !prevState[equ].checked,
        },
      };
    });
  };

  // Function to handle toggling of params
  const handleParamToggle = (equ, param) => () => {
    const selectedParams = checkedItems[equ]?.params || [];
    const currentIndex = selectedParams.indexOf(param);
    const newChecked = [...selectedParams];

    if (currentIndex === -1) {
      newChecked.push(param);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems((prevState) => ({
      ...prevState,
      [equ]: {
        checked: newChecked.length > 0, // Checkbox is checked if at least one param is selected
        params: newChecked,
      },
    }));
  };

  function groupByCategory(arr) {
    return arr.reduce((acc, item) => {
      let { category, equ, params } = item;

      // Normalize the category name to lower case
      let cat = category.toLowerCase();

      // Group by common equipment types
      if (cat?.includes("meter")) {
        category = "Meters";
      } else if (cat?.includes("transformer")) {
        category = "Transformers";
      } else if (cat?.includes("inverter")) {
        category = "Inverters";
      } else if (cat?.includes("panel")) {
        category = "Panels";
      } else if (cat?.includes("alarm") || category?.includes("fire")) {
        category = "Alarm Systems";
      } else if (cat?.includes("ups")) {
        category = "UPS";
      }

      // Initialize category array if it doesn't exist
      if (!acc[category]) {
        acc[category] = [];
      }

      // Add the equipment to the appropriate category
      acc[category].push({ equ, params });

      return acc;
    }, {});
  }
  function groupByCategoryWithParams(arr) {
    const grouped = arr.reduce((acc, item) => {
      let { category, params } = item;

      // Normalize the category name to lower case
      let cat = category.toLowerCase();

      // Group by common equipment types
      if (cat.includes("meter")) {
        category = "Meter";
      } else if (cat.includes("transformer")) {
        category = "Transformer";
      } else if (cat.includes("inverter")) {
        category = "Inverter";
      } else if (cat.includes("panel")) {
        category = "Panel";
      } else if (cat.includes("alarm") || cat.includes("fire")) {
        category = "Alarm System";
      } else if (cat.includes("ups")) {
        category = "UPS";
      }

      // Initialize category Set if it doesn't exist
      if (!acc[category]) {
        acc[category] = new Set();
      }

      // Add the params to the category Set
      params.forEach((param) => acc[category].add(param));

      return acc;
    }, {});
    const result = {};
    for (const [key, value] of Object.entries(grouped)) {
      result[key] = Array.from(value);
    }
    return result;
  }

  function groupEqupByCat(arr) {
    const grouped = arr.reduce((acc, item) => {
      let { category, equ, params } = item;

      // Normalize the category name to lower case
      let cat = category.toLowerCase();

      // Group by common equipment types
      if (cat.includes("meter")) {
        category = "Meter";
      } else if (cat.includes("transformer")) {
        category = "Transformer";
      } else if (cat.includes("inverter")) {
        category = "Inverter";
      } else if (cat.includes("panel")) {
        category = "Panel";
      } else if (cat.includes("alarm") || cat.includes("fire")) {
        category = "Alarm System";
      } else if (cat.includes("ups")) {
        category = "UPS";
      }

      // Initialize category array if it doesn't exist
      if (!acc[category]) {
        acc[category] = [];
      }

      // Add the equipment to the appropriate category
      acc[category].push(equ);

      return acc;
    }, {});
    const result = {};
    for (const [key, value] of Object.entries(grouped)) {
      result[key] = Array.from(value);
    }
    return result;
  }

  const getCategoryCheckedStatus = (equipmentByCat, checkedItems) => {
    return Object.entries(equipmentByCat).reduce(
      (result, [category, equipmentList]) => {
        // Check if any equipment in the category is checked
        const isChecked = equipmentList.some(
          (equipment) => checkedItems[equipment]?.checked === true
        );
        result[category] = isChecked;
        return result;
      },
      {}
    );
  };

  function calculateCategoryStats(checkedItems, equipmentByCat) {
    const result = {
      categoryStats: {},
      totalParams: 0,
    };

    for (const [category, equipmentList] of Object.entries(equipmentByCat)) {
      let selectedEquipmentCount = 0;
      let categoryParamCount = 0;

      equipmentList.forEach((equipment) => {
        if (checkedItems[equipment]?.checked) {
          selectedEquipmentCount += 1;
          categoryParamCount += checkedItems[equipment]?.params?.length || 0;
        }
      });

      result.categoryStats[category] = {
        selectedEquipment: selectedEquipmentCount,
        totalParams: categoryParamCount,
      };

      result.totalParams += categoryParamCount;
    }

    return result;
  }

  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    if (site) {
      handleTabChange(site, timevalue, range, chartSite);
    }
  }, [site]);

  async function createEquipmentParamsArray(siteId) {
    const id = params.id ? params.id : siteId;
    try {
      setComponentLoading(true);
      // Fetch the equipment dropdown data for the site
      const equip = await fetchEquipDropdownBySite(id);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (dataLoggerId === null || dataLoggerId === undefined) {
          return map;
        }
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultArray = [];

      // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        // Fetch parameters for the current dataLoggerId
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });

        // For each equipment, create an object with `equ` and `params` keys
        equipments.forEach((equipment) => {
          resultArray.push({
            equ: equipment.displayName,
            params: params
              .filter((param) => param.stdParameterName !== "Dummy")
              .map((param) => `${param.stdParameterName}(${param.stdUom})`),
            category: equipment.equipmentCategory,
          });
        });
      }

      console.log("resultArray", resultArray);

      // Update the state with the resulting array
      setEqupAndParams(resultArray);
      setComponentLoading(false);
      return resultArray;
    } catch (e) {
      console.error(e);
      setEqupAndParams([]);
      setComponentLoading(false);
    }
  }

  useEffect(() => {
    if (!params.id || !params.name) {
      if (siteIdName.length > 0) {
        const siteNameFromSession = queryParam
          ?.split("=")[1]
          ?.replaceAll("+", " ");

        const name = siteNameFromSession
          ? siteNameFromSession
          : siteIdName[0].siteName;
        setSite(name);

        const id = siteNameFromSession
          ? siteIdName.filter(
              (item) => item.siteName === siteNameFromSession
            )[0].siteId
          : siteIdName[0].siteId;

        const energyGen = siteNameFromSession
          ? siteIdName.filter(
              (item) => item.siteName === siteNameFromSession
            )[0].energyGenBasedOn
          : siteIdName[0].energyGenBasedOn;

        createEquipmentParamsArray(id);
        let date = new Date();
        let fromDate = moment(date).format("YYYY-MM-DD");
        const EiraDataModel = {
          fromDate: fromDate,
          GraphType: "Daily Generation",
          siteId: id,
          toDate: fromDate,
          range: "daily",
          timeperiod: "Daily",
          energyFlag: 0,
          energyGenBasedOn: energyGen,
          equipmentIdAndCapacity: [],
        };
        setCustomView(EiraDataModel);
        fetchEquipmentDetails(name);

        setChartSite(EiraDataModel.GraphType);

        fetchApplyChange();
      }
    } else {
      const getGen = siteIdName?.filter((item) => item.siteId === params.id);
      createEquipmentParamsArray(siteIdName[0]?.siteId);
      setSite(params.name);
      let date = new Date();
      let fromDate = moment(date).format("YYYY-MM-DD");
      const EiraDataModel = {
        fromDate: fromDate,
        GraphType: "Daily Generation",
        siteId: params.id,
        toDate: fromDate,
        range: "daily",
        timeperiod: "Daily",
        energyFlag: 0,
        energyGenBasedOn: getGen[0]?.energyGenBasedOn,
        equipmentIdAndCapacity: [],
      };

      setCustomView(EiraDataModel);
      fetchEquipmentDetails(params.id);

      setChartSite(EiraDataModel.GraphType);
      fetchApplyChange();
    }
  }, [siteIdName]);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParamsDropdownByLogger(id);
      let ParamsName = data
        .filter((item) => item.stdParameterName !== "Dummy")
        .map((item) => {
          if (item.stdParameterName === "ActivePower") {
            item.stdUom = "kW";
          }
          return item.stdParameterName;
        });
      setParameter(data);
      setParameterSite(ParamsName);
      return data;
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
      ResetDateRange(timerValue);
    } else {
      setSite(val);
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setCumYearFlag(getCumYearFlag(new Date()));
      const params = new URLSearchParams();
      params.set("siteName", val);
      setSelectedOptions("Daily Generation");
      setRangeLevel("daily");
      setTimeValue("Daily");
      setEquipmentName([]);
      setPanelIndex("1");
      setEquipmentData([]);
      setSelectedEquipments([]);
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      await fetchEquipmentDetails(val);
      let siteIdFilter = siteIdName?.filter((data) => {
        return data.siteName === val;
      });
      const dataFrequency = siteIdFilter[0].dataFrequency;
      setTimerValue(dataFrequency);
      setTimerOptions(
        timer.filter((data) => Number(data.value) >= Number(dataFrequency))
      );
      ResetDateRange(dataFrequency);
      await createEquipmentParamsArray(siteIdFilter[0]?.siteId);
      const encryptedParams = paddedEncryptQuery(params.toString());
      // console.log(encryptedParams.toString());
      navigate(`?${encryptedParams.toString()}`);
      setSelectedOption("");
    }
  };
  function checkArray(arr) {
    // Function to check if all values in an object are null
    function allValuesNull(obj) {
      return Object.values(obj).every((value) => value === null);
    }

    // Check each object in the array
    for (let obj of arr) {
      if (!allValuesNull(obj)) {
        return arr; // Return the original array if any object has non-null values
      }
    }

    return []; // Return an empty array if all objects have all null values
  }

  //--------------------range control functions starts -------------------------------//
  function UpdateFlag(data, flag) {
    let result;
    switch (data) {
      case "Last 7 days":
        result = adjustDateRange(data, sdaysFlag, flag);
        setSdaysFlag(result);
        break;
      case "Weekly":
        result = adjustDateRange(data, WeeklyFlag, flag);
        setWeeklyFlag(result);
        break;
      case "This Month":
        result = adjustDateRange(data, MonthlyFlag, flag);
        setMonthlyFlag(result);
        break;
      case "Monthly":
        result = adjustDateRange(data, YearlyFlag, flag);
        setYearlyFlag(result);
        break;
      case "Yearly":
        result = adjustDateRange(data, cumYearFlag, flag);
        setCumYearFlag(result);
        break;
      default:
        break;
    }
    return result;
  }

  const handleChangeDate = async (data) => {
    let newDate;
    let result;
    setTableData([]);
    if (!chartName) {
      setSelectedOption(chartSite);
      setChartName(chartSite);
    }
    const currentDate = moment(date);
    // console.log(timevalue);

    if (data === "left") {
      result = UpdateFlag(timevalue, "left");
      newDate = currentDate.subtract(1, "days");
    } else if (data === "right") {
      result = UpdateFlag(timevalue, "right");
      newDate = currentDate.add(1, "days");
    } else {
      return;
    }

    const today = moment().startOf("day");

    if (newDate.isAfter(today)) {
      newDate = today;
    }

    const formattedDateTime = newDate.format("YYYY-MM-DD");
    setDate(formattedDateTime);
    // console.log(formattedDateTime);
    await handleApplyChanges(
      {
        date: formattedDateTime,
        ref: result,
        chartName: chartSite,
      },
      "ref"
    );
  };
  //--------------------range control functions ends -------------------------------//

  const handleChange = (event) => {
    const { value } = event.target;
    let equip = [];
    if (value?.includes("Select All")) {
      if (selectAllEquipments) {
        setSelectAllEquipments(false);
        setEquipmentName([]);
      } else {
        setSelectAllEquipments(true);
        setEquipmentName([...equipmentData]);
      }
    } else {
      const updatedEquipmentName = value.filter(
        (item) => item !== "Select All"
      );
      setEquipmentName(updatedEquipmentName);
      setSelectAllEquipments(
        updatedEquipmentName.length === equipmentData.length
      );
    }
    if (value?.includes("Select All")) {
      equip = [...equipments];
    } else {
      equip = equipments.filter((data) =>
        value.some((val) => data.displayName?.includes(val))
      );
    }

    if (value.length !== 0 && equip.length !== 0) {
      const ids = getUniqueDataLoggerIds(equip);
      fetchParams({ dataloggerId: ids });
    }
  };

  const handleChangeEquipment = (event) => {
    const { value } = event.target;
    if (value?.includes("Select All")) {
      if (selectAllEquipmentParam) {
        setSelectAllEquipmentParam(false);
        setEquipmentParamName([]);
      } else {
        setSelectAllEquipmentParam(true);
        setEquipmentParamName([...equipmentParameter]);
      }
    } else {
      const updatedEquipmentParam = value.filter(
        (item) => item !== "Select All"
      );
      setEquipmentParamName(updatedEquipmentParam);
      setSelectAllEquipmentParam(
        updatedEquipmentParam.length === equipmentParameter.length
      );
    }
  };

  /*--------------------------------Equipment Get  ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      setEquipments(data);
      let displayName = data.map((datav) => {
        return datav.displayName;
      });
      setEquipmentData(
        displayName.sort((a, b) => {
          if (a?.includes("-") && b?.includes("-")) {
            return +a.split("-").pop() - +b.split("-").pop();
          } else if (a?.includes("_") && b?.includes("_")) {
            return +a.split("_").pop() - +b.split("_").pop();
          }
          return a.localeCompare(b);
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const dataFrequency = data[0].siteFrequency;
      setTimerValue(dataFrequency);
      setTimerOptions(
        timer.filter((data) => Number(data.value) >= Number(dataFrequency))
      );
      setSiteId([...new Set(data)]);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleChart = (data, event) => {
    setFromTabSwitch(false);
    setChartDropdown(false);
    setSelectedOption(event);
    setDate(moment().format("YYYY-MM-DD"));
    setSdaysFlag(getLast7DaysFlag(new Date()));
    setMonthlyFlag(getMonthFlag(new Date()));
    setWeeklyFlag(getWeekOfMonth(new Date()));
    setTimeValue("Daily");
    setDatalogValue([]);
    setTableData([]);
    setValue();

    if (event === "Daily Generation") {
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    } else if (event === "Energy Performance") {
      fetchEquipmentDetails(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    } else if (event === "Parameter Comparision") {
      fetchEquipmentDetails(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    }
  };
  const handleTimeChange = async (item) => {
    const { startDate, endDate } = item.selection;
    // Handle the change only if startDate and endDate are selected
    if (
      dayjs(startDate) &&
      dayjs(endDate) &&
      (dayjs(startDate).format("YYYY-MM-DD") !==
        dayjs(endDate).format("YYYY-MM-DD") ||
        dayjs(endDate).format("YYYY-MM-DD") ===
          dayjs(RangeState[0].endDate).format("YYYY-MM-DD"))
    ) {
      // console.log('Selected Date Range:', {
      //   startDate: dayjs(startDate).format('YYYY-MM-DD'),
      //   endDate: dayjs(endDate).format('YYYY-MM-DD'),
      //   condition:
      //     dayjs(startDate).format('YYYY-MM-DD') !==
      //     dayjs(endDate).format('YYYY-MM-DD'),
      // });

      handleChangeFromDate(dayjs(startDate));
      handleChangeToDate(dayjs(endDate));
      setRangeState([item.selection]);
      closeCalender();
    } else {
      setRangeState([item.selection]); // Update range without closing the calendar
    }
  };

  /*--------------handle----------- Custom range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setFromDate(formattedDateTime);
    }
  };
  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setToDate(formattedDateTime);
    }
  };
  useEffect(() => {
    setMinToDate(FromDate);
  }, [FromDate]);

  /*--------------------- Time range ---------------------*/

  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Today":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Last 7 days":
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("custom");
        setRangeLevel("last7days");
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange("custom");
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange("custom");
        break;
      case "custom":
        setRange("custom");
        break;
      default:
        break;
    }
  }, [timevalue]);

  /*------------------------------------------  Apply changes ----------------------*/
  async function createEquipmentMap(input) {
    const result = {};

    Object.entries(input).forEach(([key, value]) => {
      if (value.checked) {
        // Process each param by splitting and taking the first part
        const processedParams = value.params.map(
          (param) => param.split("(")[0]
        );
        // Use a Set to ensure unique processed params
        result[key] = [...new Set(processedParams)];
      }
    });
    setEqupParamMapValue(result);
    return result;
  }

  const formatDate = (inputDate) => {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = inputDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const calculateDateRange = (timevalue, refVal) => {
    let date = new Date();
    let fromDate = "";
    let toDate = "";
    let range = "";

    switch (timevalue) {
      case "Today":
        fromDate = toDate = formatDate(date);
        range = "today";
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        fromDate = toDate = formatDate(date);
        range = "daily";
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        fromDate = toDate = formatDate(date);
        range = "yesterday";
        setRange("daily");
        setRangeLevel("yesterday");
        break;

      case "Last 7 days":
        const SRef = convertLast7DaysFlagToDateRange(
          refVal || sdaysFlag,
          new Date().getFullYear()
        );
        // console.log(sdaysFlag, SRef);

        if (SRef) {
          const [startPart, endPart] = SRef.split(" - ");
          const today = new Date();
          const currentYear = date.getFullYear();

          // Parse the end date first (it is in the current year by default)
          const endDay = new Date(`${endPart} ${currentYear}`);

          // Parse the start date, assuming the current year first
          let startDay = new Date(`${startPart} ${currentYear}`);
          if (startDay > today && endDay > today) {
            startDay.setFullYear(currentYear - 1);
            endDay.setFullYear(currentYear - 1);
          } else if (startDay > endDay) {
            // If the startDay is later than the endDay, it must belong to the previous year
            startDay.setFullYear(currentYear - 1);
          }

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
        } else {
          const pastDate = new Date(date);
          pastDate.setDate(date.getDate() - 6);
          fromDate = formatDate(pastDate);
          toDate = formatDate(date);
        }
        range = "last7days";
        setRange("custom");
        setRangeLevel("last7days");
        break;

      // case "This Month":
      //   fromDate = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
      //   toDate = formatDate(date);
      //   range = "thismonth";
      //   setRange("custom");
      //   setRangeLevel("thismonth");
      //   break;

      // case "Last Month":
      //   fromDate = formatDate(
      //     new Date(date.getFullYear(), date.getMonth() - 1, 1)
      //   );
      //   toDate = formatDate(new Date(date.getFullYear(), date.getMonth(), 0));
      //   range = "lastmonth";
      //   setRange("custom");
      //   setRangeLevel("lastmonth");
      //   break;

      case "This Month":
        const Mref = convertMonthFlag(refVal || MonthlyFlag);
        if (Mref) {
          const [monthName, year] = Mref.split(" ");
          const monthMap = {
            January: 0,
            February: 1,
            March: 2,
            April: 3,
            May: 4,
            June: 5,
            July: 6,
            August: 7,
            September: 8,
            October: 9,
            November: 10,
            December: 11,
          };
          const month = monthMap[monthName];
          const startDate = new Date(year, month, 1);
          const endDate = new Date(year, month + 1, 0);
          fromDate = formatDate(startDate);
          toDate = formatDate(endDate);
          range = "monthly";
          setRange("custom");
          setRangeLevel("monthly");
        }
        break;

      case "Weekly":
        const Wref = convertWeekFlagToDateRange(
          refVal || WeeklyFlag,
          new Date().getFullYear()
        );
        if (Wref) {
          const [startPart, endPart] = Wref.split(" - ");
          const today = new Date();
          const currentYear = date.getFullYear();

          // Parse the end date first (it is in the current year by default)
          const endDay = new Date(`${endPart} ${currentYear}`);

          // Parse the start date, assuming the current year first
          let startDay = new Date(`${startPart} ${currentYear}`);

          if (startDay > today && endDay > today) {
            startDay.setFullYear(currentYear - 1);
            endDay.setFullYear(currentYear - 1);
          } else if (startDay > endDay) {
            // If the startDay is later than the endDay, it must belong to the previous year
            startDay.setFullYear(currentYear - 1);
          }

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
          range = "weekly";
          setRange("custom");
          setRangeLevel("weekly");
        }
        break;

      case "Monthly":
        const Yref = refVal || YearlyFlag;
        const { from, to } = setYearlyRange(Yref);
        fromDate = formatDate(from);
        toDate = formatDate(to);
        range = "yearly";
        setRange("custom");
        setRangeLevel("yearly");
        break;

      case "Yearly":
        const CYref = refVal || cumYearFlag;
        const { fromC, toC } = setCumYearlyRange(CYref);
        fromDate = formatDate(fromC);
        toDate = formatDate(toC);
        range = "yearlyCum";
        setRange("custom");
        setRangeLevel("yearlyCum");
        break;

      case "custom":
        range = "custom";
        setRange("custom");
        setRangeLevel("custom");
        return [];

      default:
        return [];
    }

    if (new Date(toDate) > new Date()) {
      toDate = formatDate(new Date());
    }

    setFromDate(fromDate);
    setToDate(toDate);
    return [fromDate, toDate, range];
  };

  function convertDailyDeemedData(data) {
    const formattedData = [
      { name: "Today Energy", data: [] },
      { name: "Irradiation", data: [] },
      { name: "Deemed Generation", data: [] },
      { name: "Set Point", data: [] },
      { name: "Potential Generation", data: [] },
      { name: "Estimated Load", data: [] },
      { name: "Min Load", data: [] },
    ];

    if (data.length === 0) return formattedData;

    data.forEach((point) => {
      const timestamp = new Date(point.timeStamp).getTime();

      formattedData[0].data.push([timestamp, point.todayEnergy]);
      formattedData[1].data.push([timestamp, point.irradiation]);
      formattedData[2].data.push([timestamp, point.deemedGeneration]);
      formattedData[3].data.push([timestamp, point.setPoint]);
      formattedData[4].data.push([timestamp, point.potentialGeneration]);

      // Only push max load if it is present
      if (
        point.hasOwnProperty("estimatedLoad") &&
        point.estimatedLoad !== undefined &&
        point.estimatedLoad !== null
      ) {
        formattedData[5].data.push([timestamp, point.estimatedLoad]);
      }

      // Only push min load if it is present
      if (
        point.hasOwnProperty("minLoad") &&
        point.minLoad !== undefined &&
        point.minLoad !== null
      ) {
        formattedData[6].data.push([timestamp, point.minLoad]);
      }
    });

    // Filter out estimated load and min load if their data arrays are empty
    const filteredData = formattedData.filter((item) => {
      if (item.name === "Estimated Load" || item.name === "Min Load") {
        return item.data.length > 0;
      }
      return true;
    });

    return filteredData;
  }

  function StringCurrentEqupMapTransform(equipmentData, inputEqu) {
    // Find the equipment object that matches the inputEqu
    const matchedEquipment = equipmentData.find((equ) => equ.equ === inputEqu);

    // If a match is found, return the desired object structure
    if (matchedEquipment) {
      return {
        [inputEqu]: matchedEquipment.params,
      };
    } else {
      // Return an empty object if no match is found
      return {};
    }
  }

  const handleApplyChanges = async (
    value,
    isTimeCall = true,
    flag,
    chart,
    invName,
    timer,
    daily_gen_type,
    based
  ) => {
    setLoading(true);
    setApply(true);
    setFromTabSwitch(false);
    setCustomView();
    setDatalogValue([site, equipment, equipmentParam]);

    const [fromDate, toDate, range] = calculateDateRange(
      flag === "timeValue" ? value : timevalue,
      value?.ref
    );
    console.log(value);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    const GraphName = chart
      ? chart
      : selectedOption
      ? selectedOption
      : chartSite;

    let equipArray = [];
    if (GraphName === "Energy Performance") {
      equipArray =
        selectedEquipments.length > 0 && typeof selectedEquipments !== "string"
          ? selectedEquipments
          : equipments
              .filter((data) => {
                return data.equipmentCategory.includes("Inverter");
              })
              .sort((a_name, b_name) => {
                const a = a_name.displayName;
                const b = b_name.displayName;
                if (a?.includes("-") && b?.includes("-")) {
                  return +a.split("-").pop() - +b.split("-").pop();
                } else if (a?.includes("_") && b?.includes("_")) {
                  return +a.split("_").pop() - +b.split("_").pop();
                }
                return a.localeCompare(b);
              })
              .map((datav) => {
                return datav.displayName;
              });
    } else if (GraphName === "String Current") {
      console.log(selectedEquipments, equipments);
      equipArray = invName
        ? invName
        : typeof selectedEquipments === "string"
        ? selectedEquipments
        : equipments

            .sort((a_name, b_name) => {
              const a = a_name.displayName;
              const b = b_name.displayName;
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            })
            .filter((data) => {
              return data.equipmentCategory
                .toLocaleLowerCase()
                .includes("inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })[0];
      const equipLoggMap = StringCurrentEqupMapTransform(
        equpAndParams,
        equipArray
      );

      setEqupParamMapValue(equipLoggMap);
    } else if (
      GraphName === "Active Power" ||
      GraphName === "Total Active Power" ||
      GraphName === "String Current - Heatmap" ||
      GraphName === "String Current - Average"
    ) {
      equipArray =
        selectedEquipments.length > 0 && typeof selectedEquipments !== "string"
          ? selectedEquipments
          : equipments
              .sort((a_name, b_name) => {
                const a = a_name.displayName;
                const b = b_name.displayName;
                if (a?.includes("-") && b?.includes("-")) {
                  return +a.split("-").pop() - +b.split("-").pop();
                } else if (a?.includes("_") && b?.includes("_")) {
                  return +a.split("_").pop() - +b.split("_").pop();
                }
                return a.localeCompare(b);
              })
              .filter((data) => {
                return data.equipmentCategory
                  .toLocaleLowerCase()
                  .includes("inverter");
              })
              .map((datav) => {
                return datav.displayName;
              });
      const equipLoggMap = StringCurrentEqupMapTransform(
        equpAndParams,
        equipArray
      );

      setEqupParamMapValue(equipLoggMap);
    } else {
      Object.keys(checkedItems).forEach((key) => {
        if (checkedItems[key].checked && checkedItems[key].params.length > 0) {
          equipArray.push(key);
        } else {
          return;
        }
      });
    }

    let filteredObjects = equipments?.filter((obj) => {
      return typeof equipArray === "string"
        ? obj.displayName === equipArray
        : equipArray.includes(obj.displayName);
    });
    // console.log(filteredObjects, equipArray, equipments);

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    const LoggerIds = filteredObjects?.map((data) => {
      return data.dataLoggerId;
    });
    const equipParamwithUnits = await fetchParams({
      dataloggerId: [...new Set(LoggerIds)],
    });
    console.log(equipments, equipmentIds);

    // Extract and flatten all params arrays
    let allParams;
    if (GraphName === "String Current") {
      allParams = equipParamwithUnits
        .filter((data) => {
          return data.stdParameterName.toLowerCase()?.includes("inputcurrent");
        })
        .flatMap((data) => `${data.stdParameterName}(${data.stdUom})`);
    } else if (
      GraphName === "Active Power" ||
      GraphName === "Total Active Power"
    ) {
      allParams = equipParamwithUnits
        .filter((data) => {
          return /\bactivepower\b/i.test(data.stdParameterName);
        })
        .flatMap((data) => `${data.stdParameterName}(${data.stdUom})`);
    } else {
      allParams = Object.values(checkedItems).flatMap((item) => item.params);
    }
    // console.log(equipArray, allParams);

    // Create a Set to filter unique values
    const uniqueParams = [...new Set(allParams)];

    let equipmentParameter = uniqueParams.map((data) => {
      return data.split("(")[0];
    });

    let equipmentParams = equipParamwithUnits.filter((data) => {
      return uniqueParams?.includes(`${data.stdParameterName}(${data.stdUom})`);
    });
    // console.log(equipmentParameter);
    console.log(uniqueParams);

    setAxisData(equipmentParams);

    const rangeValue =
      (fromDate === toDate && !isTimeCall) ||
      (timevalue === "custom" && FromDate === ToDate && isTimeCall) ||
      (FromDate === ToDate && isTimeCall)
        ? "daily"
        : "custom";

    const fromDataRanger = value !== null && typeof value === "object";
    if (
      flag !== "disableReset" &&
      !fromDataRanger &&
      flag !== "timer" &&
      flag !== "dailyGenChartType" &&
      !basedOn
    ) {
      setDate(moment().format("YYYY-MM-DD"));
    }

    // console.log(timevalue);

    const from = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : fromDate
      : isTimeCall || flag === "disableReset"
      ? FromDate
      : fromDate;

    const to = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : toDate
      : isTimeCall || flag === "disableReset"
      ? ToDate
      : toDate;
    console.log({
      FromDate,
      ToDate,
      fromDate,
      toDate,
      from,
      to,
    });

    const timeInterval = flag === "timer" ? timer : timerValue;
    const chartType =
      flag === "timeValue" && value !== "Daily"
        ? "custom"
        : daily_gen_type
        ? daily_gen_type
        : dailyChartType;

    let EiraDataModel = {
      fromDate: from,
      equipmentIds: equipmentIds,
      parameters:
        GraphName === "Active Power" ? ["ActivePower"] : equipmentParameter,
      range: fromDataRanger && timevalue === "Daily" ? "daily" : rangeValue,
      timeperiod: flag === "timeValue" ? value : timevalue,
      siteId: siteIdFilter[0]?.siteId,
      toDate: to,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyFlag: 0,
      intervalMins: timeInterval,
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      GraphType: GraphName,
    };
    console.log(EiraDataModel);

    if ((customChart || flag === "dailyGenChartType") && flag !== "timeValue") {
      EiraDataModel.range = chartType;
    }

    if (
      ((rangeLevel === "yearly" && flag === "ref") || range === "yearly") &&
      (GraphName === "Daily Generation" ||
        GraphName === "Actual vs Expected" ||
        GraphName === "CO2 Avoidance" ||
        GraphName === "DG PV Grid Management" ||
        GraphName === "Specific Yield" ||
        GraphName === "Energy Performance" ||
        GraphName === "Revenue Generation")
    ) {
      EiraDataModel.range = "yearly";
    } else if (
      ((rangeLevel === "yearlyCum" && flag === "ref") ||
        range === "yearlyCum") &&
      (GraphName === "Daily Generation" ||
        GraphName === "Actual vs Expected" ||
        GraphName === "CO2 Avoidance" ||
        GraphName === "Specific Yield" ||
        GraphName === "DG PV Grid Management" ||
        GraphName === "Energy Performance" ||
        GraphName === "Revenue Generation")
    ) {
      EiraDataModel.range = "yearlyCum";
    } else if (
      GraphName === "Actual vs Expected" ||
      GraphName === "CO2 Avoidance"
    ) {
      EiraDataModel.range = "custom";
    }
    setDataModel(EiraDataModel);
    // setDailyChartType(EiraDataModel.range);

    if (GraphName === "Total Active Power") {
      const EquipmentForSpecificYield =
        transformDataForSpecificYield(equipments);
      EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
      let filteredObjects = equipments?.filter((obj) =>
        [
          "Weather Station",
          "Energy Meter",
          "String Inverter",
          "Central Inverter",
        ]?.includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      EiraDataModel.equipmentIds.push(...equipmentIds);
    }

    if (
      GraphName === "Specific Yield" ||
      GraphName === "Specific Yield - Heatmap" ||
      GraphName === "Performance Ratio" ||
      GraphName === "Specific Yield vs Irradiation"
    ) {
      const EquipmentForSpecificYield =
        transformDataForSpecificYield(equipments);
      EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
      let filteredObjects = equipments?.filter((obj) =>
        ["Weather Station", "Energy Meter"]?.includes(obj.equipmentCategory)
      );
      const equipmentIds = EiraDataModel.equipmentIdAndCapacity?.map(
        (item) => item.equipmentId
      );

      EiraDataModel.equipmentIds.push(...equipmentIds);
    }

    if (
      GraphName === "Daily Generation" ||
      GraphName === "Deemed Generation" ||
      GraphName === "Actual vs Expected" ||
      GraphName === "CO2 Avoidance" ||
      GraphName === "Specific Yield vs Irradiation" ||
      GraphName === "Performance Ratio" ||
      GraphName === "Expected Deviation" ||
      GraphName === "Revenue Generation" ||
      GraphName === "Export Total Energy"
    ) {
      let filteredObjects = equipments?.filter((obj) =>
        [
          "Weather Station",
          "Energy Meter",
          "String Inverter",
          "Central Inverter",
        ]?.includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      // const ReqSites = [162, 163, 164, 167, 168, 169, 171];
      // const hardCodedIDs = {
      //   162: [1030, 1031, 1032, 1033, 1034, 1035, 1037, 1038, 1039, 1040],
      //   163: [
      //     999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
      //     1011, 1012, 1037, 1038, 1039, 1040,
      //   ],
      //   164: [1030, 1031, 1032, 1033, 1034, 1035, 1037, 1038, 1039, 1040],
      //   169: [1077, 1078, 1082, 1082, 1083, 1084],
      //   167: [1068, 1069, 1082],
      //   168: [1076, 1075, 1082],
      //   171: [1044, 1045, 1046, 1047, 1082],
      // };
      // const isHarded =
      //   EiraDataModel.range === "daily" &&
      //   ReqSites.includes(EiraDataModel.siteId);
      // EiraDataModel.equipmentIds = isHarded
      //   ? hardCodedIDs[EiraDataModel.siteId]
      //   : equipmentIds;

      //and uncomment below code
      EiraDataModel.equipmentIds = equipmentIds;
    }

    setChartFromDate(from);
    setChartToDate(to);
    setFromDate(from);
    setToDate(to);

    if (GraphName === "Daily Generation") {
      delete EiraDataModel.parameters;
      // if (siteIdFilter[0].energyGenBasedOn != 1) {
      //   delete EiraDataModel.equipmentIds;
      // }
      try {
        let responseData = await fetchDailyGenerationEira(EiraDataModel);
        setChartName("Daily Generation");
        setDatalogValue(responseData);
        setTableData(responseData);
        let seriesData = [];
        const irradiationSeries = {
          name: "irradiation",
          data: [],
        };
        const todayEnergySeries = {
          name: "todayenergy",
          data: [],
        };
        responseData.forEach((dataPoint) => {
          const timeStamp = new Date(dataPoint.timeStamp).getTime();
          irradiationSeries.data.push([timeStamp, dataPoint.irradiation]);
          todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy]);
        });
        seriesData.push(irradiationSeries);
        seriesData.push(todayEnergySeries);
        setMultichartData(seriesData);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Export Total Energy") {
      delete EiraDataModel.parameters;
      // if (siteIdFilter[0].energyGenBasedOn != 1) {
      //   delete EiraDataModel.equipmentIds;
      // }
      try {
        let responseData = await fetchExportTotalEnergy(EiraDataModel);
        setChartName("Export Total Energy");
        setDatalogValue(responseData);
        setTableData(responseData);
        let seriesData = [];
        const todayEnergySeries = {
          name: "todayenergy",
          data: [],
        };
        responseData.forEach((dataPoint) => {
          const timeStamp = new Date(dataPoint.timeStamp).getTime();
          todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy]);
        });
        seriesData.push(todayEnergySeries);
        setMultichartData(seriesData);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Energy Performance") {
      delete EiraDataModel.parameters;
      if (EiraDataModel.range === "daily") {
        setDailyChartType("daily");
      } else {
        setDailyChartType("custom");
      }
      try {
        let responseData = await fetchEnergyPerformanceEira(EiraDataModel);
        setChartName("Energy Performance");
        setLoading(false);
        let barChartData = responseData;
        setTableData(responseData);
        const isCumulative =
          EiraDataModel.range === "yearlyCum" ||
          EiraDataModel.range === "yearly";
        const equipmentMap = {};
        equipments.forEach((equipment) => {
          equipmentMap[equipment.equipmentId] = equipment.displayName;
        });
        const seriesData = [];
        equipments.forEach((equipment) => {
          const series = {
            name: equipment.displayName,
            data: [],
          };
          const equipmentData = responseData.filter(
            (data) => data.equipmentId === equipment.equipmentId
          );
          equipmentData.forEach((data) => {
            const timeStamp = new Date(data.timeStamp).getTime();
            const value = data.todayEnergy;
            series.data.push([timeStamp, value]);
          });
          seriesData.push(series);
        });
        let finalArray = seriesData?.filter((value) => {
          return value.data.length > 0;
        });
        setMultichartData(finalArray);
        const convertedData = barChartData.reduce((result, item) => {
          console.log(item);

          const found = result.find(
            (x) => x.name === item.equipmentId.toString()
          );
          if (found) {
            found.data.push([
              isCumulative
                ? item.timeStamp
                : new Date(item.timeStamp).getTime(),
              item.todayEnergy,
            ]);
          } else {
            result.push({
              name: item.equipmentId.toString(),
              data: [
                [
                  isCumulative
                    ? item.timeStamp
                    : new Date(item.timeStamp).getTime(),
                  item.todayEnergy,
                ],
              ],
            });
          }
          return result;
        }, []);
        let converteddata = convertedData;
        let dataMap = new Map(
          equipments.map((item) => [String(item.equipmentId), item.displayName])
        );
        converteddata.forEach((item) => {
          if (dataMap.has(item.name)) {
            item.name = dataMap.get(item.name);
          }
        });
        console.log(convertedData);

        setBarchartData(convertedData);
        setTableData(responseData);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Parameter Comparision") {
      try {
        let responseData = [];
        responseData = await fetchParameterComparisonEira(EiraDataModel);
        setChartName("Parameter Comparision");
        setTableData(responseData);
        console.log(responseData);

        const equipLoggMap = await createEquipmentMap(checkedItems);

        function doesFieldExist(equipmentName, fieldName) {
          const params = equipLoggMap[equipmentName];

          if (params && params?.includes(fieldName)) {
            return true;
          }
          return false;
        }

        const equipmentMap = {};
        const dataFields = equipmentParameter;
        const seriesData = [];

        dataFields.forEach(async (field) => {
          responseData.forEach(async (data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;
            const timeStamp = new Date(timeStampData).getTime();
            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            // console.log(seriesName);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };

              if (doesFieldExist(equipmentName[0]?.displayName, field)) {
                seriesData.push(newSeries);
              }

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });
              // console.log(filteredArray);

              setMultichartData(filteredArray);
              return filteredArray;
            }
          });
        });
        // console.log(dataFields);

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (
      GraphName === "String Current" ||
      GraphName === "Active Power" ||
      GraphName === "Total Active Power"
    ) {
      const endpoint =
        GraphName === "Total Active Power"
          ? getTotalActivePower
          : fetchParameterComparisonEira;
      try {
        let responseData = [];
        responseData = await endpoint(EiraDataModel);
        setChartName(
          GraphName === "Total Active Power"
            ? "Total Active Power"
            : "Parameter Comparision"
        );
        setTableData(responseData);
        // console.log(equipmentParameter);

        const dataFields =
          "Active Power" === GraphName ? ["ActivePower"] : equipmentParameter;
        const seriesData = [];
        // console.log(equipments);

        dataFields.forEach(async (field) => {
          responseData.forEach(async (data) => {
            // console.log(field);

            let equipmentName = equipments?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            // console.log(equipmentName);

            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;

            const timeStamp = new Date(timeStampData).getTime();

            // console.log(timeStamp);

            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            // console.log(value);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };
              seriesData.push(newSeries);
              // console.log(seriesData);

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });

              setMultichartData(filteredArray);
              return filteredArray;
            }
          });
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (
      GraphName === "Specific Yield" &&
      (timevalue === "today" ||
        timevalue === "yesterday" ||
        fromDate === toDate ||
        EiraDataModel.range === "daily")
    ) {
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield");
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // Transform the initialData array
          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp || item.timeStamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        const result = transformToChartData(responseData);
        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield") {
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield");
        const transformedData = responseData.map((item) => {
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
          };
        });

        setLoading(false);
        setTableData(transformedData);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield - Heatmap") {
      delete EiraDataModel.parameters;
      delete EiraDataModel.equipmentIds;
      EiraDataModel.range = "daily";
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield - Heatmap");
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp || item.timeStamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        const result = transformToChartData(responseData);
        console.log(result);

        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "String Current - Heatmap") {
      // Remove unwanted parameters
      delete EiraDataModel.parameters;
      EiraDataModel.range = "daily";

      try {
        let responseData = await fetchStringCurrentEira(
          EiraDataModel.equipmentIds
        );

        console.log(responseData); // Check the order of responseData

        setChartName("String Current - Heatmap");
        // function transformToChartData(initialData) {
        //   const equipmentMap = new Map();
        //   equipments.forEach((item) => {
        //     equipmentMap.set(item.equipmentId, item);
        //   });

        //   return initialData.map((item) => {
        //     const equipment = equipmentMap.get(item.equipmentId);
        //     const transformedItem = {
        //       equipmentId: item.equipmentId,
        //       equipmentName: equipment ? equipment.displayName : "Unknown",
        //       datas: {
        //         equipmentName: equipment ? equipment.displayName : "Unknown",  // Add the equipmentName
        //         ...item  // Spread the entire item into the 'datas' field
        //       }
        //     };
        //     console.log(transformedItem);

        //     return transformedItem;
        //   });

        // }

        // const result = transformToChartData(responseData);
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // List of all possible inputCurrent fields
          const allInputCurrentFields = Array.from(
            { length: 30 },
            (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
          );

          // Ensure the order is preserved by directly mapping over the initialData
          return initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            const transformedItem = {
              equipmentId: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown", // Equipment name
              datas: {
                equipmentName: equipment ? equipment.displayName : "Unknown", // Add the equipment name inside 'datas'
                // Dynamically add all 'inputCurrent_*' fields into 'datas'
                ...allInputCurrentFields.reduce((acc, field) => {
                  acc[field] = item[field] !== null ? item[field] : null; // Assign the value of each field
                  return acc;
                }, {}),
              },
            };

            console.log(transformedItem); // The log value should remain in the same format, no change in the order of fields

            return transformedItem;
          });
        }

        const result = transformToChartData(responseData);
        console.log(result);

        setTableData(result);
        setLoading(false); // End loading state
        setChartName(EiraDataModel?.GraphType);
        setTableData(result);
        setLoading(false); // End loading state
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error("Error fetching data: ", e);
        setLoading(false); // Ensure loading is turned off on error
      }
    } else if (GraphName === "String Current - Average") {
      // Remove unwanted parameters
      delete EiraDataModel.parameters;

      EiraDataModel.range = "daily";

      try {
        let responseData = await fetchStringAverage(EiraDataModel);

        console.log(responseData); // Check the order of responseData

        setChartName("String Current - Average");

        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // List of all possible inputCurrent fields
          const allInputCurrentFields = Array.from(
            { length: 30 },
            (_, i) => `inputCurrent_${String(i + 1).padStart(2, "0")}`
          );
          console.log(allInputCurrentFields);
          // Ensure the order is preserved by directly mapping over the initialData
          return initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            const transformedItem = {
              equipmentId: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown", // Equipment name
              datas: {
                equipmentName: equipment ? equipment.displayName : "Unknown", // Add the equipment name inside 'datas'
                // Dynamically add all 'inputCurrent_*' fields into 'datas'
                ...allInputCurrentFields.reduce((acc, field) => {
                  acc[field] = item[field] !== null ? item[field] : null; // Assign the value of each field
                  return acc;
                }, {}),
              },
            };

            console.log(transformedItem); // The log value should remain in the same format, no change in the order of fields

            return transformedItem;
          });
        }

        const result = transformToChartData(responseData);

        // Sort the result by equipmentId
        result.sort((a, b) => a.equipmentId - b.equipmentId);

        console.log(result);
        setTableData(result);
        setLoading(false); // End loading state
        setChartName(EiraDataModel?.GraphType);
        setTableData(result);
        setLoading(false); // End loading state
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error("Error fetching data: ", e);
        setLoading(false); // Ensure loading is turned off on error
      }
    } else if (GraphName === "DG PV Grid Management") {
      delete EiraDataModel.parameters;
      delete EiraDataModel.equipmentIds;
      if (EiraDataModel.range === "daily") {
        setDailyChartType("daily");
      } else {
        setDailyChartType("custom");
      }

      //comment this
      // EiraDataModel.range = "daily";
      setChartName("DG PV Grid Management");
      try {
        let responseData = await fetchPvDgGridEira(EiraDataModel);
        let result;

        result = {
          dgMeter: responseData.dgMeter,
          gridMeter: responseData.gridMeter,
          solarMeter: responseData.solarMeter,
        };

        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Actual vs Expected") {
      let responseData = await ActualVsExpected(EiraDataModel);

      setLoading(false);
      setTableData(responseData);
      setChartName(EiraDataModel?.GraphType);
    } else if (GraphName === "CO2 Avoidance") {
      let responseData;
      responseData = await CO2Avioded(EiraDataModel);

      setLoading(false);
      setTableData(responseData);
      setChartName(EiraDataModel?.GraphType);
    } else if (GraphName === "Deemed Generation") {
      if (
        timevalue.toLowerCase() === "today" ||
        timevalue.toLocaleLowerCase() === "yesterday" ||
        fromDate === toDate
      ) {
        if (EiraDataModel.range === "daily") {
          setDailyChartType("daily");
        } else {
          setDailyChartType("custom");
        }
        try {
          let responseData = await DeemedGenrator(EiraDataModel);
          responseData = checkArray(responseData);
          setChartName("Deemed Generation");
          const result = convertDailyDeemedData(responseData);
          setTableData(responseData);
          setMultichartData(result);
          setChartName(EiraDataModel?.GraphType);
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      } else {
        try {
          if (EiraDataModel.range === "daily") {
            setDailyChartType("daily");
          } else {
            setDailyChartType("custom");
          }
          //remove below after testing
          // EiraDataModel.range = "daily";
          // setMultichartData([]);
          //remove above after testing
          let responseData = await DeemedGenrator(EiraDataModel);
          responseData = checkArray(responseData);
          setLoading(false);
          setTableData(responseData);
          setChartName(EiraDataModel?.GraphType);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }
    } else if (GraphName === "Set Point") {
      try {
        let responseData = await GetSetPoint(EiraDataModel);
        setLoading(false);
        //check if all data is null
        const isNull = responseData.every((item) => {
          return item.avgSetpoint == null;
        });
        setTableData(isNull ? [] : responseData);
        setMultichartData([]);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield vs Irradiation") {
      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            irradiation: roundedUp(item.irradiation),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",
          };
        });

        return transformedData;
      }

      const basedOnFlag = flag === "basedOn" ? based : basedOn;
      try {
        const requestPoint =
          basedOnFlag === "Site"
            ? GetSpecificYieldVsIrradiationSite
            : GetSpecificYieldVsIrradiationEqp;
        let responseData = await requestPoint(EiraDataModel);
        const result = transformToChartData(responseData);
        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Performance Ratio") {
      // delete EiraDataModel.range;
      setLoading(true);
      const basedOnFlag = flag === "basedOn" ? based : basedOn;
      const requestPoint = basedOnFlag === "Site" ? GetPRData : GetPRDataForEqp;
      try {
        let responseData = await requestPoint(EiraDataModel);
        if (basedOnFlag !== "Site") {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });
          responseData = responseData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              ...item,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });
        }
        setTableData(responseData);
        setLoading(false);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
    } else if (GraphName === "Expected Deviation") {
      setLoading(true);
      EiraDataModel.range = "yearly";
      try {
        let responseData = await GetExpectedPercentage(EiraDataModel);

        setTableData(responseData);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else if (GraphName === "Revenue Generation") {
      if(EiraDataModel.range === "daily") EiraDataModel.range = "custom";
      setLoading(true);
      try {
        let responseData = await GetRevenueGen(EiraDataModel);
        responseData = responseData.map((obj) => {
          return {
            ...obj,
            siteId: EiraDataModel.siteId,
          };
        });
        console.log(responseData);

        setTableData(responseData);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };
  /*------------------------------KEY Metrics Moniter  ----------------*/
  const datePickerStyle = {
    "& .MuiInputBase-input": {
      fontSize: "1.2rem",
      padding: "4px",
      width: "11vw",
    },
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getEquipCap = async (id) => {
    let data = await fetchEquipDropdownBySite(id);
    setEquipments(data);
    return data;
  };

  const handleTabChange = async (site, timevalue, range, GraphType) => {
    setChartSite(GraphType);
    setRange(range);
    setDate(moment().format("YYYY-MM-DD"));
    setChartDropdown(true);
    setTimeValue(timevalue);
    setChartName("");
    setSelectedOption("");
    setEquipmentName([]);
    setEquipmentParamName([]);
    setTableData([]);
    setFromTabSwitch(true);
    setLoading(true);
    let data = siteIdName;
    let equip;
    let siteIdFilter;
    try {
      if (siteIdName.length === 0) {
        data = await getSiteCall();
      }
      siteIdFilter = data?.filter((data) => {
        return data.siteName === site;
      });
      equip = await getEquipCap(siteIdFilter[0].siteId);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }

    let date = new Date();
    let fromDate = new Date();
    let responseDate,
      toDate,
      ApplyFromDate,
      ApplyToDate,
      rangeValue,
      timeApiValue = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Daily";
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Yesterday";
        break;
      case "Last 7 days":
        fromDate.setDate(fromDate.getDate() - 6);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setRange("custom");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "custom";
        timeApiValue = "Last 7 days";
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = formatDate(startDate);
        ApplyToDate = formatDate(endDate);
        rangeValue = "custom";
        timeApiValue = "This Month";
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        let dataValue = formatDate(firstDayOfLastMonth);
        let dataValueEnd = formatDate(lastDayOfLastMonth);
        ApplyFromDate = dataValue;
        ApplyToDate = dataValueEnd;
        rangeValue = "custom";
        timeApiValue = "Last Month";
        break;
      default:
        break;
    }
    let rangeData =
      GraphType === "Specific Yield"
        ? range
        : (GraphType === "Daily Generation" && rangeValue === "daily") ||
          (GraphType === "Deemed Generation" && rangeValue === "daily")
        ? "daily"
        : "custom";

    let equipCap = [];
    if (
      chartName === "Specific Yield" ||
      chartName === "Specific Yield - Heatmap"
    ) {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equip);
      equipCap = EquipmentForSpecificYield;
    }

    let meter = [];
    const equipList = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
    let filteredObjects = equipList.filter((obj) =>
      [
        "Weather Station",
        "Energy Meter",
        "String Inverter",
        "Central Inverter",
      ]?.includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    meter = equipmentIds;

    let EiraDataModel = {
      fromDate: ApplyFromDate,
      GraphType: GraphType,
      equipmentIds: meter,
      range: rangeData,
      siteId: siteIdFilter[0]?.siteId,
      toDate: ApplyToDate,
      timeperiod: timeApiValue,
      energyFlag: 0,
      intervalMins: siteIdFilter[0]?.siteFrequency,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIdAndCapacity: equipCap,
    };
    setTimerValue(siteIdFilter[0]?.siteFrequency);
    setTimerOptions(
      timer.filter(
        (data) => Number(data.value) >= Number(siteIdFilter[0]?.siteFrequency)
      )
    );
    // const ReqSites = [162, 163, 164, 167, 168, 169, 171];
    // const hardCodedIDs = {
    //   162: [1030, 1031, 1032, 1033, 1034, 1035, 1037, 1038, 1039, 1040],
    //   163: [
    //     999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    //     1011, 1012, 1037, 1038, 1039, 1040,
    //   ],
    //   164: [1030, 1031, 1032, 1033, 1034, 1035, 1037, 1038, 1039, 1040],
    //   169: [1077, 1078, 1082, 1082, 1083, 1084],
    //   167: [1068, 1069, 1082],
    //   168: [1076, 1075, 1082],
    //   171: [1044, 1045, 1046, 1047, 1082],
    // };
    // const isHarded =
    //   EiraDataModel.range === "daily" &&
    //   ReqSites.includes(EiraDataModel.siteId);
    EiraDataModel.equipmentIds = equipmentIds;

    fetchApplyChange(EiraDataModel);
    setDataModel(EiraDataModel);
    setChartName(EiraDataModel?.GraphType);
    setCustomView(EiraDataModel);
  };

  /*------------------------------------- Handle Key Metrics Monitor ------------------------------ */
  const fetchApplyChange = async (EiraDataModel) => {
    if (EiraDataModel !== undefined) {
      setLoading(true);
      setApply(false);
      setChartFromDate(EiraDataModel?.fromDate);
      setChartToDate(EiraDataModel?.toDate);
      //remove hard codded logic upon getting api data
      // const ReqSites = [162, 163];
      // const hardCodedIDs = [1037, 1038, 1039, 1040];
      // const isHarded =
      //   EiraDataModel.range === "daily" &&
      //   ReqSites.includes(EiraDataModel.siteId) &&
      //   EiraDataModel.GraphType === "Daily Generation";
      // EiraDataModel.equipmentIds = isHarded
      //   ? hardCodedIDs
      //   : EiraDataModel.equipmentIds;
      //
      if (
        EiraDataModel?.GraphType === "Specific Yield" &&
        EiraDataModel?.timeperiod === "Daily"
      ) {
        const EquipmentForSpecificYield =
          transformDataForSpecificYield(equipments);
        EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
        EiraDataModel.range = "daily";
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // Transform the initialData array
          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        setLoading(false);
        setDatalogValue(responseData);
        setTableData(transformToChartData(responseData));
      } else if (EiraDataModel?.GraphType === "Specific Yield") {
        const EquipmentForSpecificYield =
          transformDataForSpecificYield(equipments);
        EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        const transformedData = responseData.map((item) => {
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
          };
        });
        setLoading(false);
        setTableData(transformedData);
      } else if (EiraDataModel?.GraphType === "DG PV Grid Management") {
        delete EiraDataModel.parameters;
        delete EiraDataModel.equipmentIds;
        let responseData = await fetchPvDgGridEira(EiraDataModel);
        let result;

        result = {
          dgMeter: responseData.dgMeter,
          gridMeter: responseData.gridMeter,
          solarMeter: responseData.solarMeter,
        };
        setLoading(false);
        setTableData(result);
      } else if (EiraDataModel?.GraphType === "Deemed Generation") {
        if (
          EiraDataModel?.timeperiod.toLowerCase() === "today" ||
          EiraDataModel?.timeperiod.toLocaleLowerCase() === "yesterday" ||
          EiraDataModel?.fromDate === EiraDataModel?.toDate
        ) {
          let responseData = await DeemedGenrator(EiraDataModel);
          const result = convertDailyDeemedData(responseData);
          setTableData(responseData);
          setMultichartData(result);
          setLoading(false);
        } else {
          delete EiraDataModel.parameters;
          delete EiraDataModel.equipmentIds;
          // EiraDataModel.range="daily"

          let responseData = await DeemedGenrator(EiraDataModel);

          setLoading(false);
          setTableData(responseData[0]);
        }
      } else if (EiraDataModel?.GraphType === "Daily Generation") {
        let responseData = await fetchDailyGenerationEira(EiraDataModel);

        setLoading(false);
        setDatalogValue(responseData);
        setTableData(responseData);
        if (EiraDataModel?.range === "daily") {
          let seriesData = [];
          const irradiationSeries = {
            name: "irradiation",
            data: [],
          };
          const todayEnergySeries = {
            name: "todayenergy",
            data: [],
          };
          responseData.forEach((dataPoint) => {
            const timeStamp = new Date(dataPoint.timeStamp).getTime();
            irradiationSeries.data.push([timeStamp, dataPoint.irradiation]);
            todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy]);
          });
          seriesData.push(irradiationSeries);
          seriesData.push(todayEnergySeries);
          setMultichartData(seriesData);
        }
      }
    }
  };

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  const handleClickMenuItem = (event, flag) => {
    if (flag === "Time") {
      setOpenMenu(true);
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorTimerEl(event.currentTarget);
    }
  };

  const [openBasedOn, setOpenBasedOn] = useState(false);
  const [basedOnAnchor, setBasedOnAnchor] = useState(null);
  const handledailyMenuItem = (event, flag) => {
    if (flag === "dailychart") {
      setChartAnchor(event.currentTarget);
      setOpennMenu(true);
    } else if (flag === "basedon") {
      setBasedOnAnchor(event.currentTarget);
      setOpenBasedOn(true);
    }
  };

  const handleCloseBasedOn = () => {
    setOpenBasedOn(false);
    setBasedOnAnchor(null);
  };

  const hadleBasedOn = async (data) => {
    setBasedOn(data.label);
    setOpenBasedOn(false);
    // setDailyChartType('daily');
    await handleApplyChanges(
      null,
      true,
      "basedOn",
      chartName,
      null,
      timerValue,
      null,
      data.label
    );
  };

  const iconForBasedOn = useMemo(() => {
    const based = basedOn;
    const styleForIcon = {
      color: CheckForLight() ? "black" : "white",
      opacity:
        chartName === "Specific Yield vs Irradiation" ||
        chartName === "Performance Ratio"
          ? 1
          : 0.5,
      pointerEvents:
        chartName === "Specific Yield vs Irradiation" ||
        chartName === "Performance Ratio"
          ? "auto"
          : "none",
    };

    switch (based.toLocaleLowerCase()) {
      case "site":
        return <SolarPowerIcon style={styleForIcon} />;
      case "equipment":
        return <ConstructionIcon style={styleForIcon} />;
      default:
    }
  }, [basedOn, chartName]);

  const handleMenuItemClick = async (value, flag) => {
    if (flag === "Time") {
      setTimeValue(value);
      if (value !== "custom") {
        if (value === "Daily") {
          setDate(moment().format("YYYY-MM-DD"));
        }
        setCustomChart(false);
        setRangeState([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        handleApplyChanges(
          value,
          false,
          "timeValue",
          null,
          null,
          value === "Daily" ? "daily" : dailyChartType
        );
        handleCloseMenu();
      }
      if (value === "custom") {
        openCalender();
        setTableData([]);
      }
    } else if (flag === "dailychart") {
      setDailyChartType(value);
      setCustomChart(true);
      handleCloseeMenu();
      if (chartName !== "Specific Yield vs Irradiation") {
        await handleApplyChanges(
          null,
          true,
          "dailyGenChartType",
          chartName,
          null,
          timerValue,
          value
        );
      }
    } else {
      setTimerValue(Number(value));
      await handleApplyChanges(
        null,
        true,
        "timer",
        chartName,
        null,
        Number(value)
      );
      handleCloseTimer();
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const handleCloseeMenu = () => {
    setOpennMenu(false);
    setChartAnchor(null);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };

  const RangeText =
    timevalue === "custom"
      ? "Custom Range"
      : timevalue === "Last 7 days"
      ? convertLast7DaysFlagToDateRange(sdaysFlag, new Date().getFullYear())
      : timevalue === "Weekly"
      ? convertWeekFlagToDateRange(WeeklyFlag, new Date().getFullYear())
      : timevalue === "This Month"
      ? convertMonthFlag(MonthlyFlag)
      : timevalue === "Monthly"
      ? YearlyFlag
      : timevalue === "Yearly"
      ? cumYearFlag.split("-")[0]
      : moment(date).format("DD/MM/YYYY");

  const analyticsMetrics = () => {
    const handleCheckboxChange = async (event, name) => {
      setButtonLock(false);
      if (selectedOption === "String Current") {
        event.target.checked
          ? setSelectedEquipments(name)
          : setSelectedEquipments([]);
        console.log(name);

        await handleApplyChanges(null, true, "selectedEquipments", null, name);
        return;
      }
      if (event.target.checked) {
        setSelectedEquipments((prev) => [...prev, name]);
      } else {
        setSelectedEquipments((prev) => prev.filter((item) => item !== name));
      }
    };
    const isDisabled =
      selectedOption === "Daily Generation" ||
      selectedOption === "Specific Yield" ||
      selectedOption === "Deemed Generation" ||
      selectedOption === "Specific Yield - Heatmap" ||
      selectedOption === "DG PV Grid Management" ||
      selectedOption === "Set Point" ||
      selectedOption === "Actual vs Expected" ||
      selectedOption === "CO2 Avoidance" ||
      selectedOption === "Specific Yield vs Irradiation" ||
      selectedOption === "Performance Ratio" ||
      selectedOption === "Expected Deviation" ||
      selectedOption === "Total Active Power" ||
      selectedOption === "Revenue Generation" ||
      selectedOption === "Export Total Energy" ||
      selectedOption === "";

    return (
      <>
        <div
          style={{
            marginLeft: "2%",
            marginRight: "5%",
            marginTop: "5%",
          }}
        >
          {/* <Typography
            style={{
              fontSize: '1rem',
              '@media (max-width: 600px)': {
                fontSize: '0.875rem',
              },
            }}
          >
            Metric Points
          </Typography> */}
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "23vh",
              padding: "8px",
              borderRadius: "5px",
              "@media (max-width: 600px)": {
                width: "100%",
                marginLeft: "0%",
              },
            }}
          >
            <List
              style={{
                paddingTop: 0,
                "@media (max-width: 600px)": {
                  paddingTop: "5px",
                },
              }}
            >
              {chartType.map((option) => (
                <ListItem
                  key={option.label}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions === option.label}
                        onChange={(event) =>
                          handleChartCheckboxChange(event, option.label)
                        }
                        disabled={!site}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                          cursor: "default",
                          visibility: "hidden",
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "12rem",
                            marginRight: 35,
                            cursor: "pointer",
                            fontSize: "14px",
                            color:
                              selectedOptions === option.label
                                ? "#1976d2"
                                : "inherit",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                          }}
                        >
                          {option.label}
                        </span>
                      </Box>
                    }
                    sx={{
                      fontSize: 15,
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>

        <div
          style={{
            marginLeft: "2%",
            marginRight: "5%",
            marginTop: "8%",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              letterSpacing: "0.00938em",
              fontWeight: 400,
              color: isDisabled
                ? CheckForLight()
                  ? "rgba(0, 0, 0, 0.3)"
                  : "rgba(255, 255, 255, 0.3)"
                : CheckForLight()
                ? "rgba(0, 0, 0, 0.87)"
                : "white",
              "@media (max-width: 600px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            Equipment
          </Typography>
          <Box
            sx={{
              padding: "8px",
              marginLeft: "10%",
              height: "18vh",
              overflowY: isDisabled ? "hidden" : "auto",
              overflowX: "hidden",
              // border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: "5px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <List
              style={{
                paddingTop: 0,
                "@media (max-width: 600px)": {
                  paddingTop: "5px",
                },
              }}
            >
              {equipmentData.map((name) => (
                <ListItem
                  key={name}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          isDisabled
                            ? false
                            : typeof selectedEquipments === "string"
                            ? selectedEquipments === name
                            : selectedEquipments?.includes(name)
                        }
                        onChange={(event) => handleCheckboxChange(event, name)}
                        disabled={isDisabled}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "12rem",
                            marginRight: 35,
                            cursor: "pointer",
                            fontSize: "14px",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                          }}
                        >
                          {name}
                        </span>
                      </Box>
                    }
                    sx={{
                      fontSize: 15,
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      </>
    );
  };

  const analyticsEquipment = () => {
    // Group the equipment by category
    const groupedByCategory = groupByCategory(equpAndParams);
    const equipmentByCat = groupEqupByCat(equpAndParams);
    const status = calculateCategoryStats(checkedItems, equipmentByCat);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          padding: "10px 30px 10px 5px",
        }}
      >
        <div style={{ width: "90%", textAlign: "left" }}>
          {/* <Typography>Components</Typography> */}
        </div>
        <div
          style={{
            // border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: "5px",
            width: "90%",
            marginTop: "2%",
          }}
        >
          <div
            style={{
              // marginLeft: '6%',
              marginTop: "5%",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              height: "48vh",
              flexDirection: "column",
            }}
          >
            {componentLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100%",
                }}
              >
                <FadeLoader color="#000440" loading={true} />
              </div>
            ) : equpAndParams.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                <typography>No configuration found</typography>
              </div>
            ) : (
              Object.entries(groupedByCategory)
                .sort()
                .map(([category, equipmentList]) => {
                  return (
                    <div key={category} style={{ marginBottom: "10px" }}>
                      {/* Add marginBottom for spacing */}
                      <div
                        onClick={() => toggleCategoryListOpen(category)}
                        className="categoryItem"
                      >
                        {openCategoryLists[category] ? (
                          <BookmarkOutlinedIcon sx={{ fontSize: 18 }} />
                        ) : (
                          <BookmarkBorderIcon
                            sx={{ color: "rgb(113, 118, 111)", fontSize: 18 }}
                          />
                        )}
                        <Typography
                          style={{
                            marginRight: "8px",
                            width: "12rem",
                            cursor: "pointer",
                            fontSize: "14px",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                            userSelect: "none",
                          }}
                        >
                          {category}
                        </Typography>
                        <div
                          style={{
                            width: "fit-content",
                            minWidth: "40px",
                            backgroundColor: "#e0e0e0",
                            padding: "1px",
                            textAlign: "center",
                            borderRadius: "20px",
                          }}
                        >
                          {
                            status.categoryStats[category.replace(/s$/, "")]
                              .selectedEquipment
                          }
                        </div>
                      </div>
                      {openCategoryLists[category] && (
                        <List
                          sx={{
                            marginLeft: "4px",
                            borderLeft: "1px solid gray",
                            paddingTop: "0px",
                          }}
                        >
                          {equipmentList
                            .sort((a, b) => {
                              if (
                                a.equ?.includes("-") &&
                                b.equ?.includes("-")
                              ) {
                                return (
                                  +a.equ.split("-").pop() -
                                  +b.equ.split("-").pop()
                                );
                              } else if (
                                a.equ?.includes("_") &&
                                b.equ?.includes("_")
                              ) {
                                return (
                                  +a.equ.split("_").pop() -
                                  +b.equ.split("_").pop()
                                );
                              }
                              return a.equ.localeCompare(b.equ);
                            })
                            .map(({ equ, params }) => {
                              return (
                                <div key={equ} className="equipmentItem">
                                  <FormControlLabel
                                    className="equipmentLabel"
                                    control={
                                      <Checkbox
                                        checked={
                                          checkedItems[equ]?.checked || false
                                        }
                                        onChange={handleCheckboxChange(equ)}
                                        onClick={() => {
                                          toggleEquipmentListOpen(equ);
                                          handleCheckboxChange(equ);
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 14,
                                          },
                                          cursor: "default",
                                          visibility: "hidden",
                                          marginLeft: "-8%",
                                        }}
                                      />
                                    }
                                    label={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <Radio
                                          edge="start"
                                          checked={
                                            checkedItems[equ]?.checked || false
                                          }
                                          onChange={handleCheckboxChange(equ)}
                                          onClick={() => {
                                            toggleEquipmentListOpen(equ);
                                            handleCheckboxChange(equ);
                                          }}
                                          tabIndex={-1}
                                          disableRipple
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 12,
                                            },
                                          }}
                                        />
                                        <span
                                          style={{
                                            marginRight: "8px",
                                            width: "12rem",
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            color:
                                              checkedItems[equ]?.checked ||
                                              false
                                                ? "#1976d2"
                                                : "inherit",
                                            "@media (max-width: 600px)": {
                                              width: "100%",
                                              marginRight: "0",
                                              textAlign: "center",
                                            },
                                          }}
                                        >
                                          {equ}
                                        </span>
                                      </Box>
                                    }
                                    sx={{ fontSize: 14 }}
                                  />
                                </div>
                              );
                            })}
                        </List>
                      )}
                    </div>
                  );
                })
            )}
          </div>
        </div>
        <IconButton
          disabled={isOpen || equpAndParams.length === 0}
          onClick={() => setIsOpen(true)}
          style={{
            visibility: isOpen ? "hidden" : "visible",
            position: "absolute",
            left: "95%",
            backgroundColor: "#b5b7b991",
            width: "20px",
            borderRadius: "30%",
          }}
        >
          <KeyboardArrowRightOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  const paramsListModal = () => {
    const groupedParams = groupByCategoryWithParams(equpAndParams);
    const equipmentByCat = groupEqupByCat(equpAndParams);
    const result = getCategoryCheckedStatus(equipmentByCat, checkedItems);
    const status = calculateCategoryStats(checkedItems, equipmentByCat);
    const options = convertToUniqueOptions(groupedParams);

    // function addParamFromSearch(param) {
    //   console.log(param);

    //   const cat = getCategoriesByParameter(groupedParams, param);
    //   cat.forEach((c) => {
    //     handleParamSelect(c, param, equipmentByCat);
    //   });
    // }
    console.log({
      groupedParams,
      paramsListState,
      checkedItems,
      equipmentByCat,
      selectedParams,
      openEquipmentLists,
      result,
      status,
      options,
    });

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "16px", marginLeft: "10px" }}>
            Parameters
          </Typography>

          <IconButton onClick={() => setIsOpen(false)}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <div style={{ overflowY: "auto", overflowX: "hidden", height: "75%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Autocomplete
              freeSolo
              disableClearable
              multiple
              disableCloseOnSelect
              limitTags={1}
              size="small"
              disabled={!Object.values(result).some(Boolean)}
              options={options}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.option}
              // onChange={(e, value) => {
              //   console.log(value);

              //   addParamFromSearch(value);
              // }}
              renderGroup={(params) => {
                // console.log(params.group);
                if (!result[params.group]) {
                  return null;
                }
                return (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>
                      {params.children.map((item) => {
                        console.log(selectedParams[params.group]);
                        const isChecked = selectedParams[params.group]
                          ? selectedParams[params.group].includes(item.key)
                          : false;
                        return (
                          <div style={{ display: "flex" }}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ margin: 0 }}
                              checked={isChecked && result[params.group]}
                              onChange={(e) => {
                                handleParamSelect(
                                  params.group,
                                  item.key,
                                  equipmentByCat
                                );
                              }}
                            />
                            {item}
                          </div>
                        );
                      })}
                    </GroupItems>
                  </li>
                );
              }}
              sx={{
                width: "14vw",
                "& .MuiInputBase-root": {
                  height: "30px",
                  lineHeight: "normal",
                  fontSize: "12px",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px",
                },
                "& .MuiInputBase-sizeSmall": {
                  paddingTop: "0px !important",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "30px",
                      padding: "0 8px",
                      boxSizing: "border-box",
                      fontSize: "14px",
                    },
                  }}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: "search",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment sx={{ padding: "2px" }} position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          {Object.entries(groupedParams).map(([key, params]) => {
            return (
              <div
                key={key}
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    if (result[key]) {
                      toggleParamsListOpen(key);
                    }
                  }}
                >
                  <Checkbox
                    edge="start"
                    checked={paramsListState[key + "All"] || false}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleParamsListOpen(key, true, params, equipmentByCat);
                    }}
                    disabled={!result[key]}
                    tabIndex={0}
                    disableRipple
                    sx={{
                      padding: "0px",
                      marginLeft: "1px",
                      marginRight: "4px",
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                    }}
                  />
                  <Typography
                    component="div"
                    sx={{
                      marginRight: "8px",
                      width: "8.5rem",
                      cursor: "pointer",
                      color: !result[key] ? "gray" : "inherit",
                      fontSize: "14px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginRight: "0",
                        textAlign: "center",
                      },
                      userSelect: "none",
                    }}
                  >
                    {key}
                  </Typography>
                  <div
                    style={{
                      width: "fit-content",
                      padding: "1px",
                      minWidth: "40px",
                      backgroundColor: "#e0e0e0",
                      textAlign: "center",
                      borderRadius: "20px",
                    }}
                  >
                    {status.categoryStats[key].totalParams}
                  </div>
                </div>
                {paramsListState[key] && result[key] && (
                  <List
                    sx={{
                      marginLeft: "4px",
                      borderLeft: "1px solid gray",
                    }}
                  >
                    {params.map((param) => {
                      const isChecked = selectedParams[key]
                        ? selectedParams[key].includes(param)
                        : false;

                      return (
                        <ListItemButton
                          key={param}
                          onClick={() =>
                            handleParamSelect(key, param, equipmentByCat)
                          }
                          sx={{
                            fontSize: 11,
                            padding: "0 0 0 8px",
                            "& .MuiListItemText-root": {
                              fontSize: "12px",
                            },
                          }}
                        >
                          <Radio
                            edge="start"
                            checked={isChecked}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 12,
                              },
                            }}
                          />
                          <ListItemText
                            primary={
                              <div style={{ fontSize: "12px" }}>{param}</div>
                            }
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                )}
              </div>
            );
          })}
        </div>
        <div
          style={{
            marginTop: "2%",
            marginLeft: "1%",
            marginRight: "1%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "18px", marginLeft: "10px" }}>
            Limit: {status.totalParams} / 40
          </Typography>
        </div>
        <div
          style={{
            marginTop: "2%",
            marginLeft: "1%",
            marginRight: "1%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{
              borderRadius: "25px",
              textTransform: "capitalize",
              backgroundColor: status.totalParams > 40 ? "#ff5151" : null,
              marginTop: "4%",
              color: status.totalParams > 40 ? "white" : null,
              fontSize: "1rem",
            }}
            onClick={() => {
              handleApplyChanges(
                null,
                timevalue === "custom" ? true : false,
                "disableReset"
              );
            }}
            startIcon={<DownloadDoneOutlinedIcon />}
            disabled={status.totalParams === 0 || status.totalParams > 40}
          >
            {status.totalParams > 40 ? "Limit Exceeded" : "Apply changes"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Paper
          elevation={0}
          style={{
            width: "99%",
            borderRadius: "2px 2px 0px 0px",
            userSelect: "none",
            height: "5vh",
            display: "flex",
            alignItems: "center",
            // marginLeft: '2.5%',
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <CustomBreadcrumbs
                paths={paths || []}
                separatorSize="18px"
                fontSize="14px"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <div className="monitoring-graph" style={{ flexDirection: "row" }}>
        <div style={{ margin: "0px 0px 10px 0px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              marginTop: "10px",
              backgroundColor: "background.paper",
              "& > :not(style)": {
                m: 1,
                width: "20vw",
                minHeight: "70vh",
                height: "fit-content",
              },
              // overflowY: "scroll",
            }}
          >
            <Paper elevation={0} height="100%">
              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // marginLeft: '7%',
                    // marginRight: '5%',
                    marginBottom: "5%",
                  }}
                >
                  <Typography>Site</Typography>
                  <div>
                    <Tooltip
                      title={site}
                      disableFocusListener
                      enterDelay={200}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                      arrow
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        value={site}
                        disabled={params.name}
                        options={siteIdName.map((option) => option.siteName)}
                        onChange={(data, event) => handleSite(data, event)}
                        sx={{ width: "18vw" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Site ..."
                          />
                        )}
                        classes={{ option: "autocomplete" }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Tabs value={panelIndex} onChange={handleChangePanel}>
                  <Tab
                    label="Key Metrics"
                    key={1}
                    value="1"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      backgroundColor:
                        panelIndex === "1" ? "#1976d229" : "transparent",
                      transition: "background-color 0.3s ease-in-out", // Added transition effect
                    }}
                  />
                  <Tab
                    label="Equipment"
                    key={2}
                    value="2"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      backgroundColor:
                        panelIndex === "2" ? "#1976d229" : "transparent",
                      transition: "background-color 0.3s ease-in-out", // Added transition effect
                    }}
                  />
                </Tabs>
              </Box>

              {panelIndex === "1" ? (
                <div>{analyticsMetrics()}</div>
              ) : (
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {analyticsEquipment()}
                    </div>
                    <div
                      style={{
                        // marginTop: "15px",
                        height: "72vh",
                        width: "85%", // Adjust as needed
                        position: "absolute",
                        right: !isOpen ? "0" : "-85%", // Adjust -40% to fully hide the div offscreen
                        top: !isOpen ? "0" : "-31%",
                        transition: "right 0.3s ease", // Smooth slide-in effect
                        backgroundColor: CheckForLight() ? "white" : "#121212",
                        padding: "5px",
                        visibility: isOpen ? "visible" : "hidden",
                        boxShadow: isOpen
                          ? "rgba(0, 0, 0, 0.3) 2px 0px 5px 0px"
                          : "none", // Optional shadow effect
                        zIndex: 1000,
                      }}
                    >
                      {paramsListModal()}
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </Paper>
          </Box>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ marginLeft: "1%", width: "70vw" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "13vh",
                },
              }}
            >
              <Paper elevation={0} style={{ height: "100%" }}>
                <div style={{ width: "auto", padding: "10px" }}>
                  <div>
                    <div className="graph">
                      <div>
                        <div style={{ marginTop: 10 }}>
                          <Stack spacing={1} direction="row">
                            <div>
                              {/* <Typography
                                style={{
                                  ...typoStyle,
                                  color: CheckForLight() ? 'black' : 'white',
                                }}
                              >
                                Based on
                              </Typography> */}
                              {/* <Autocomplete
                                disablePortal
                                disableClearable
                                size="small"
                                width={'5vw'}
                                disabled={
                                  chartName !== 'Specific Yield vs Irradiation'
                                }
                                options={basedOnOptions}
                                value={basedOn}
                                onChange={(event, data) => {
                                  hadleBasedOn(data);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              /> */}
                            </div>
                            <div className="monitor-text">
                              <Typography
                                style={{
                                  ...typoStyle,
                                  color: CheckForLight() ? "black" : "white",
                                }}
                              >
                                Range
                              </Typography>
                              <div>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  borderRadius="4px"
                                  sx={{ width: "28vw" }}
                                  justifyContent="space-between"
                                  p="0"
                                  style={{
                                    border: CheckForLight()
                                      ? "1px solid #c4c4c4"
                                      : "1px solid #494949",
                                  }}
                                >
                                  <Tooltip title="Based on">
                                    <IconButton
                                      onClick={(event) =>
                                        handledailyMenuItem(event, "basedon")
                                      }
                                      disabled={
                                        chartName ===
                                          "Specific Yield vs Irradiation" ||
                                        chartName === "Performance Ratio"
                                          ? false
                                          : true
                                      }
                                    >
                                      {iconForBasedOn}
                                    </IconButton>
                                  </Tooltip>
                                  <Menu
                                    anchorEl={basedOnAnchor}
                                    open={openBasedOn}
                                    onClose={handleCloseBasedOn}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {basedOnOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          hadleBasedOn(option)
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <Tooltip title="Previous">
                                    <IconButton
                                      disabled={
                                        timevalue === "custom" ||
                                        chartName === "String Current - Heatmap"
                                      }
                                      onClick={() => handleChangeDate("left")}
                                    >
                                      <ChevronLeftIcon
                                        sx={{
                                          color:
                                            timevalue === "custom" ||
                                            chartName ===
                                              "String Current - Heatmap"
                                              ? "rgb(113, 118, 111)"
                                              : CheckForLight()
                                              ? "black"
                                              : "white",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                  <Stack
                                    direction="row"
                                    style={{
                                      cursor: "pointer",
                                      flex: "1",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      mt="1px"
                                      ml="2.5px"
                                      style={{
                                        color: CheckForLight()
                                          ? "black"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                      }}
                                    >
                                      {RangeText}
                                    </Typography>
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    disabled={
                                      moment(date).isSame(
                                        moment().startOf("day")
                                      ) ||
                                      timevalue === "custom" ||
                                      chartName === "String Current - Heatmap"
                                    }
                                    onClick={() => handleChangeDate("right")}
                                  >
                                    <ChevronRightIcon
                                      sx={{
                                        color:
                                          moment(date).isSame(
                                            moment().startOf("day")
                                          ) ||
                                          timevalue === "custom" ||
                                          chartName ===
                                            "String Current - Heatmap"
                                            ? "rgb(113, 118, 111)"
                                            : CheckForLight()
                                            ? "black"
                                            : "white",
                                      }}
                                    />
                                  </IconButton>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickMenuItem(event, "Timer")
                                    }
                                    disabled={
                                      chartName !== "Daily Generation" ||
                                      chartName === "String Current - Heatmap"
                                    }
                                    sx={{
                                      width: "40px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={timerValue}
                                      color="default"
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <TimerOutlinedIcon
                                        sx={{
                                          color: CheckForLight()
                                            ? "black"
                                            : "white",
                                          opacity:
                                            chartName !== "Daily Generation"
                                              ? 0.5
                                              : 1,
                                          pointerEvents:
                                            chartName !== "Daily Generation"
                                              ? "none"
                                              : "auto",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </Badge>
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorTimerEl}
                                    open={Boolean(anchorTimerEl)}
                                    onClose={handleCloseTimer}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {timerOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={() =>
                                          handleMenuItemClick(
                                            option.value,
                                            "Timer"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handledailyMenuItem(event, "dailychart")
                                    }
                                    disabled={
                                      ((chartName === "Daily Generation" ||
                                        chartName === "Energy Performance" ||
                                        chartName ===
                                          "DG PV Grid Management") &&
                                        rangeLevel !== "yearly") ||
                                      (chartName === "Specific Yield" &&
                                        timevalue !== "Daily") ||
                                      chartName === "Deemed Generation" ||
                                      chartName ===
                                        "Specific Yield vs Irradiation"
                                        ? false
                                        : true
                                    }
                                  >
                                    <BarChartOutlinedIcon
                                      style={{
                                        color: CheckForLight()
                                          ? "black"
                                          : "white",
                                        opacity:
                                          (chartName === "Daily Generation" &&
                                            rangeLevel !== "yearly") ||
                                          (chartName === "Specific Yield" &&
                                            timevalue !== "Daily") ||
                                          chartName === "Deemed Generation" ||
                                          chartName ===
                                            "Specific Yield vs Irradiation" ||
                                          chartName === "Energy Performance" ||
                                          chartName === "DG PV Grid Management"
                                            ? 1
                                            : 0.5,
                                        pointerEvents:
                                          (chartName === "Daily Generation" &&
                                            rangeLevel !== "yearly") ||
                                          (chartName === "Specific Yield" &&
                                            timevalue !== "Daily") ||
                                          chartName === "Deemed Generation" ||
                                          chartName ===
                                            "Specific Yield vs Irradiation" ||
                                          chartName === "Energy Performance" ||
                                          chartName === "DG PV Grid Management"
                                            ? "auto"
                                            : "none",
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    anchorEl={chartAnchor}
                                    open={menuOpenn}
                                    onClose={handleCloseeMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {dailychartType.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        disabled={
                                          chartName ===
                                          "String Current - Heatmap"
                                        }
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "dailychart"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickMenuItem(event, "Time")
                                    }
                                    disabled={
                                      chartName ===
                                        "String Current - Heatmap" ||
                                      chartName === "String Current - Average"
                                    }
                                  >
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        color:
                                          chartName ===
                                            "String Current - Heatmap" ||
                                          chartName ===
                                            "String Current - Average"
                                            ? "rgb(113, 118, 111)"
                                            : CheckForLight()
                                            ? "black"
                                            : "white",

                                        opacity: 1,
                                        pointerEvents: "auto",
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {optionToUse.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "Time"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Box>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "fit-content",
                                marginTop: "23px",
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                borderRadius="4px"
                                sx={{ width: "fit-content", height: "43px" }}
                                justifyContent="space-between"
                                p="0"
                                style={{
                                  border: CheckForLight()
                                    ? "1px solid #c4c4c4"
                                    : "1px solid #494949",
                                }}
                              >
                                <IconButton
                                  onClick={(e) =>
                                    openCalender(e, "fromCalender")
                                  }
                                  disabled={
                                    chartName === "String Current - Heatmap" ||
                                    chartName === "String Current - Average"
                                  }
                                >
                                  <CalendarMonthOutlinedIcon
                                    sx={{
                                      color:
                                        chartName ===
                                          "String Current - Heatmap" ||
                                        chartName === "String Current - Average"
                                          ? "rgb(113, 118, 111)"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                    }}
                                  />
                                </IconButton>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    From:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(FromDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    color: CheckForLight()
                                      ? "#c4c4c4"
                                      : "#494949",
                                    marginRight: "10px",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    To:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(ToDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                              </Box>
                              <div>
                                <Popover
                                  id={calenderId}
                                  open={isCalendarOpen}
                                  anchorEl={calenderAnchor}
                                  onClose={closeCalender}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  sx={{ mt: 1 }}
                                >
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) => handleTimeChange(item)}
                                    months={2}
                                    ranges={RangeState}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    dateDisplayFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    direction="horizontal"
                                  />
                                </Popover>
                              </div>
                            </div>

                            <div
                              style={{
                                marginTop: "2%",
                                marginLeft: "1%",
                                marginRight: "1%",
                                display: "flex",
                                // justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                  marginTop: "4%",
                                  fontSize: "1rem",
                                  visibility:
                                    panelIndex === "2" && timevalue !== "custom"
                                      ? "hidden"
                                      : "visible",
                                }}
                                onClick={() => {
                                  handleApplyChanges(
                                    null,
                                    timevalue === "custom" ? true : false,
                                    "disableReset"
                                  );
                                }}
                                startIcon={<DownloadDoneOutlinedIcon />}
                                disabled={
                                  (chartName === "Energy Performance" &&
                                    site !== "" &&
                                    selectedEquipments.length > 0) ||
                                  (chartName === "String Current - Heatmap" &&
                                    site !== "" &&
                                    selectedEquipments.length > 0) ||
                                  (chartName === "String Current - Average" &&
                                    site !== "" &&
                                    selectedEquipments.length > 0) ||
                                  (chartName === "Parameter Comparision" &&
                                    site !== "" &&
                                    !optionalCharts &&
                                    ((Object.keys(checkedItems).length > 0 &&
                                      Object.values(checkedItems).some(
                                        (x) => x.checked
                                      )) ||
                                      selectedEquipments !== "")) ||
                                  ((chartName === "Daily Generation" ||
                                    chartName === "Specific Yield" ||
                                    chartName === "Specific Yield - Heatmap" ||
                                    chartName === "String Current - Heatmap" ||
                                    chartName === "Actual vs Expected" ||
                                    chartName === "CO2 Avoidance" ||
                                    chartName === "DG PV Grid Management" ||
                                    chartName === "Deemed Generation" ||
                                    chartName === "Set Point" ||
                                    optionalCharts) &&
                                    site !== "" &&
                                    timevalue === "custom")
                                    ? false
                                    : true
                                }
                              >
                                Apply
                              </Button>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    <div>
                      <div>
                        {loading ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "60vh",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                height: "60vh",
                                overflowX: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  //  marginTop: "15%",
                                }}
                              >
                                <FadeLoader color="#000440" loading={true} />
                              </div>
                            </CardContent>
                          </Card>
                        ) : datalog?.length > 0 ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "10%",
                              overflowX: "scroll",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              {chartName === "Parameter Comparision" ||
                              chartName === "Total Active Power" ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  dataModel={dataModel}
                                  name={"paramter"}
                                  timeValue={timevalue}
                                  axisData={axisData}
                                  widgetName={`${site}-${chartName}`}
                                  loggerMap={equpParamMap}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "Revenue Generation" ? (
                                <>
                                  <BlockSiteChart
                                    tableData={tableData}
                                    chartName={chartName}
                                    customView={dataModel}
                                    barchartdata={barchartdata}
                                    widgetName={`${site}-${chartName}`}
                                    fallBack={fallBack}
                                    isStacked={false}
                                    siteList={siteIdName}
                                    from={"Vanalytics"}
                                  />
                                </>
                              ) : chartName ===
                                "Specific Yield vs Irradiation" ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={
                                    fromApply ? dataModel : customView
                                  }
                                  dailyChartType={dailyChartType}
                                  widgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  basedOn={basedOn}
                                  from={"Vanalytics"}
                                />
                              ) : (chartName === "Specific Yield" &&
                                  (dataModel.range !== "daily" ||
                                    timevalue === "Daily")) ||
                                chartName === "Actual vs Expected" ||
                                chartName === "CO2 Avoidance" ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={
                                    fromApply ? dataModel : customView
                                  }
                                  widgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "Specific Yield - Heatmap" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "String Current - Heatmap" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "String Current - Average" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${site}-${chartName}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "DG PV Grid Management" &&
                                chartFromDate === chartToDate &&
                                dailyChartType === "daily" ? (
                                <AreaChart
                                  tableData={tableData}
                                  chartName={`${site}-${chartName}`}
                                  WidgetName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : (chartName === "Deemed Generation" &&
                                  dailyChartType !== "daily") ||
                                (chartName === "Energy Performance" &&
                                  dailyChartType !== "daily") ||
                                (chartName === "DG PV Grid Management" &&
                                  dailyChartType !== "daily") ||
                                (chartName === "Deemed Generation" &&
                                  dailyChartType !== "daily") ||
                                chartName === "Performance Ratio" ||
                                chartName === "Expected Deviation" ||
                                chartName === "Export Total Energy" ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  customView={dataModel}
                                  barchartdata={barchartdata}
                                  widgetName={`${site}-${chartName}`}
                                  fallBack={fallBack}
                                  basedOn={basedOn}
                                  from={"Vanalytics"}
                                />
                              ) : (chartFromDate === chartToDate &&
                                  chartName !== "Daily Generation") ||
                                chartName === "Deemed Generation" ||
                                chartName === "Set Point" ||
                                (chartName === "Daily Generation" &&
                                  dataModel.range === "daily") ||
                                (chartName === "Specific Yield" &&
                                  timevalue !== "Daily" &&
                                  dailyChartType === "daily") ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  timeValue={timevalue}
                                  dataModel={dataModel}
                                  multiChartData={multiChartData}
                                  axisData={axisData}
                                  key="1"
                                  name="chartData"
                                  widgetName={`${site}-${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName !== "Deemed Generation" ||
                                chartName === "Daily Generation" ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  customView={dataModel}
                                  barchartdata={barchartdata}
                                  widgetName={`${site}-${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : (
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "50vh",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "1.5rem",
                                      textTransform: "capitalize",
                                      lineHeight: "14.52px !important",
                                      color: " rgb(113, 118, 111)",
                                    }}
                                  >
                                    No data found in the database
                                  </Typography>
                                </CardContent>
                              )}
                            </CardContent>
                          </Card>
                        ) : (
                          fallBack
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
