import axios from 'axios';
/*---------------------------------------------- Get call------------------------------*/

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const fetchSiteTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_MAP_SITE_DETAIL_TABLE_BYCOMPANY
          : process.env.REACT_APP_MAP_SITE_DETAIL__TABLE
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const postSiteSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_MAP_SITE__POST}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
export const putsiteupdateEdit = async (data, id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_MAP_SITE__PUT}/${id}`,
      data
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteMapByUser = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_MAP_SITE_DELETE}=${id}`
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Getdropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_MAP_USER_DROP_DOWN_BYCOMPANY
          : process.env.REACT_APP_MAP_USER__DROP_DOWN
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const GetdropdownvalueForSiteMap = async (forOEM, Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM && forOEM
          ? process.env.REACT_APP_SITE_DROP_DOWN_BYCOMPANY
          : process.env.REACT_APP_SITE_DROP_DOWN
      }/${
        isOEM && forOEM
          ? sessionStorage.getItem('companyId')
          : !forOEM
          ? Id
          : sessionStorage.getItem('customerId')
      }`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// export const Getdropdownvalue = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${
//         isOEM
//           ? process.env.REACT_APP_SITE_DROP_DOWN_BYCOMPANY
//           : process.env.REACT_APP_SITE_DROP_DOWN
//       }/${
//         isOEM
//           ? sessionStorage.getItem('companyId')
//           : sessionStorage.getItem('customerId')
//       }`
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
/*---------------------------------------------- Site Name list------------------------------*/
export const Getdropdownvalue = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITELIST_BYUSER
      }/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetCustomerdropdowndata = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_USERCONFIG_CUSTOMERLIST
      }/${sessionStorage.getItem('companyId')}`
    );
    console.log(response.data, 'API Response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};