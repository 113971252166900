import React, { useEffect, useState, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import { fetchBarChartGet, fetchBarChartGett } from "../../Api/PieChartDataApi";
import { useTheme, Paper, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { removeQuestionMark } from "../util/common/CommanFunction";
import { decryptPaddedQuery } from "../util/security/Cipher";
import { Padding } from "@mui/icons-material";
import { postTicketbarchart } from "../../Api/TicketApi";
Highcharts3D(Highcharts);

const BarChart = (props) => {
  const {
    sitevalue,
    statuscall,
    barchart,
    filterstatus,
    Barharttooltip,
    calenderbar,
    ticketcalender,
    barchartstatusdata,
    barchartstatus,
  } = props;
  console.log(barchartstatusdata, barchartstatus, ticketcalender);
  console.log(filterstatus);

  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const isLight = colorMode === "light";

  const [chartOptions, setChartOptions] = useState({});
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState(null); // State to hold fetched data
  console.log(data);

  const location = useLocation();
  console.log(location);
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);

  const params = useMemo(() => {
    const parsedParams = {};
    for (const [key, value] of queryParams.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  }, [queryParams]);

  const isFromSiteList = params?.data && Object.keys(params).length > 0;
  const isFromSticketcalender = params?.datas;
  console.log(sitevalue);
  console.log(statuscall);
  const fetchDataBarchart = async (sitevalue) => {
    console.log(sitevalue);
    let id = sessionStorage.getItem("id");
    console.log(id);
    try {
      let fetchedData;
      if (isFromSiteList === true) {
        fetchedData = await fetchBarChartGett(params.id);
      } else if (sitevalue > 0) {
        fetchedData = await fetchBarChartGett(sitevalue);
      } else if (isFromSticketcalender === "true") {
        const datas = {
          siteId: params?.id,
          filterType:
            params?.state === "Created" ? "createdDate" : "scheduleOn",
          ticketStatusId: params?.state,
          fromDate: params?.start,
          toDate: params?.end,
        };
        fetchedData = await postTicketbarchart(datas);
      } else {
        fetchedData = await fetchBarChartGet(id);
      }

      console.log("Fetched data:", fetchedData);

      if (fetchedData && fetchedData.ticketTypes) {
        setData(barchartstatus === true ? barchartstatusdata : fetchedData);
      } else {
        setData(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setData(null);
    }
  };

  useEffect(() => {
    fetchDataBarchart(sitevalue);
  }, [
    colorMode,
    isFromSiteList,
    params.id,
    sitevalue,
    statuscall,
    barchart,
    filterstatus,
    barchartstatusdata,
    barchartstatus,
  ]);

  useEffect(() => {
    if (!data) return;

    const ticketTypes = data.ticketTypes;
    const categories = Object.keys(ticketTypes).map(
      (category) => category.charAt(0).toUpperCase() + category.slice(1) // Capitalize first letter of each category
    );

    const seriesKeys = Object.keys(ticketTypes["Close"]); // ['finishedTicket', 'AssignedTicket', ...]

    // Generate series data for each key in seriesKeys
    const series = seriesKeys.map((key) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter of each series name
      data: categories.map((category) => ticketTypes[category][key] || 0), // Ensure missing data defaults to 0
    }));

    // Calculate max value in the dataset to determine tick positions
    const maxValue = Math.max(...series.flatMap((serie) => serie.data));
    const tickInterval = maxValue / 4;
    const tickPositions = Array.from({ length: 4 }, (_, index) =>
      Math.ceil((index + 1) * tickInterval)
    );

    setChartOptions({
      chart: {
        type: "column",
        options3d: {
          enabled: false,
          alpha: 10,
          beta: 25,
          depth: 70,
        },
         backgroundColor: 'transparent',
        height: "35%",
        spacing: [12, 20, 25, 20],
        // backgroundColor: isLight ? "#ffffff" : "#121212",
      },
      title: {
        text: "Status Specific Chart",
        style: {
          fontSize: "15px",
          fontWeight: "normal",
          color: isLight ? "black" : "white",
        },
      },
      series,
      xAxis: {
        gridLineWidth: 0,
        categories,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      yAxis: {
        title: {
          text: "Count",
          style: {
            color: isLight ? "black" : "white",
          },
        },
        gridLineWidth: 0,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: "0.8rem",
          color: isLight ? "black" : "white",
        },
        itemHoverStyle: {
          color: "gray",
        },
      },
      plotOptions: {
        series: {
          depth: 25,
          point: {
            events: {
              click: function () {
                console.log(`${this.category}, ${this.series.name}`);

                const tooltipText = `${this.category}, ${this.series.name}`;
                console.log(tooltipText);

                // Check and call the tooltip function
                if (typeof Barharttooltip === "function") {
                  Barharttooltip(tooltipText);
                } else {
                  console.error("Barharttooltip is not a function");
                }
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.key}</b><br>${this.series.name}: <b>${this.y}</b><br>`;
        },
      },
      credits: {
        enabled: false,
      },
    });

    // Check if there is data to display
    setHasData(series.some((serie) => serie.data.some((value) => value > 0)));
  }, [data, isLight]);

  return (
    <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Paper
        elevation={0}
        style={{
          width: "100%",
          borderRadius: "2px 2px 0px 0px",
          userSelect: "none",
          height: "40vh",
          padding: "1%",
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
       </Paper>
     </Box>
  );
};

export default BarChart;
