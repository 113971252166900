import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  Link,
  Autocomplete,
  TextField,
  useTheme,
} from "@mui/material";
import { fetchSiteDetails } from "../../Api/CurringApi";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CreateIcon from "@mui/icons-material/Create";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CustomBreadcrumbs from "../util/components/CustomBread";
import CustomSnackbar from "../util/components/CustomSnackbar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FadeLoader } from "react-spinners";
import { useRef } from "react";
import { paddedEncryptQuery } from "../util/security/Cipher";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  postTicketFilterTable,
  fetchAssignDetail,
  fetchstateCount,
} from "../../Api/TicketApi";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { useNavigate } from "react-router";
import "./Calender.css";

export default function Ftpgetfile() {
  const initialState = {
    siteName: "",
  };
  const [stdField, setstdField] = useState(initialState);
  const [siteIdName, setSiteId] = useState([]);
  const [site, setSite] = useState("");
  console.log(site);
  useEffect(() => {
    fetchDatastate(siteIdName[0]?.siteId);
    setSite(siteIdName[0]?.siteName);
  }, [siteIdName]);
  const [events, setEvents] = useState([]);
  const [schedulelist, setSchedulelist] = useState([]);
  const [schedule, setSchedule] = useState(false);
  const [SiteReport, setSiteReport] = useState([]);
  const [data, setData] = useState([]);
  const [startdate, setStartdate] = useState();
  const [enddate, setenddate] = useState();
  const navigate = useNavigate();
  console.log(startdate, enddate);
  console.log(data?.assignedTicketCount);

  const [loading, setLoading] = useState(true);

  const [currentDate, setCurrentDate] = useState(new Date());
  console.log(siteIdName[0]?.siteName);

  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  console.log(snack);
  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentDate(prevMonth);
  };
  const today = new Date(); // Current date

  // Function to handle the next month button click
  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentDate(nextMonth);
  };
  const [todayTickets, setTodayTickets] = useState([]);
  console.log(todayTickets);

  const previousDateRangeRef = useRef(null);
  useEffect(() => {
    const today = new Date();
    const todayDateString = `${today.getDate().toString().padStart(2, "0")}/${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;
    console.log(schedule, schedulelist);

    const filteredTickets = SiteReport.filter(
      (ticket) =>
        ticket.scheduleOn ===
        (schedule === true ? schedulelist : todayDateString)
    );

    setTodayTickets(filteredTickets);
  }, [SiteReport, schedule, schedulelist]);
  const generateCalendarDates = () => {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    // Get the first day of the month and the number of days in the month
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Create an array of dates (empty for days before the 1st)
    const calendarDates = [];
    for (let i = 0; i < firstDay; i++) {
      calendarDates.push(null); // Empty spaces before the start of the month
    }
    for (let i = 1; i <= daysInMonth; i++) {
      calendarDates.push(i);
    }
    return calendarDates;
  };

  // Days of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Get the generated dates for the current month
  const calendarDates = generateCalendarDates();
  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Ticket Configuration", path: "/menu/ticket" },
    { label: "Ticket Calendar", path: "ticket_calender" },
  ];

  useEffect(() => {
    getSiteCall();
    fetchAssignDetails();
  }, []);
  const fetchDatastate = async (id) => {
    const currentDate = new Date();

    // Get the current year and month dynamically
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // This gives the current month (0 for January, 11 for December)

    // Get the first and last days of the current month
    const startDate = new Date(currentYear, currentMonth, 1); // First day of the current month
    const endDate = new Date(currentYear, currentMonth + 1, 0); // Last day of the current month

    // Add one day to both start and end dates
    startDate.setDate(startDate.getDate() + 1); // Add one day to start date
    endDate.setDate(endDate.getDate() + 1); // Add one day to end date

    // Format dates as YYYY-MM-DD
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    const formattedStartDatee = startDate
      .toISOString()
      .split("T")[0]
      .slice(0, 7);
    setStartdate(formattedStartDate);
    setenddate(formattedEndDate);
    const datas = {
      siteId: id,
      fromDate: formattedStartDate,
      toDate: formattedEndDate,
      filterType: "scheduleOn",
    };

    try {
      setLoading(true);
      console.log(datas?.siteId);

      if (datas?.siteId !== undefined) {
        const data = await postTicketFilterTable(datas);
        const value = await fetchstateCount(datas?.siteId, formattedStartDatee);
        setData(value);
        setLoading(false);
        const updateAssignedBy = (item) => {
          if (item.assignedBy === null) {
            item.assignedBy = "Assign";
          }
          if (Array.isArray(item.ticketTransactions)) {
            item.ticketTransactions.forEach((transaction) => {
              if (transaction.assignedBy === null) {
                transaction.assignedBy = "assign";
              }
            });
          }

          if (item.scheduleOn) {
            const date = new Date(item.scheduleOn);
            const day = String(date.getDate()).padStart(2, "0"); // Two-digit day
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Two-digit month
            const year = date.getFullYear();
            item.scheduleOn = `${day}/${month}/${year}`;
          }

          return item;
        };

        // Process the fetched data
        if (Array.isArray(data)) {
          const updatedData = data.map(updateAssignedBy);
          setSiteReport(updatedData);
          setLoading(false);
        } else if (typeof data === "object" && data !== null) {
          const updatedData = updateAssignedBy(data);
          setSiteReport([updatedData]);
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(`Error fetching data for site ID ${id}:`, e);
    }
  };

  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };

  function navigateEncryptedURL(URL, datas) {
    const ids = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    console.log(ticketstatus);
    console.log(data);

    let statusIdFilter = ticketstatus?.filter((data) => {
      return data.statusName === datas;
    });
    console.log(statusIdFilter);

    const stateId = statusIdFilter[0]?.ticketStatusId;
    console.log(stateId);

    let passSite = {
      id: ids[0]?.siteId,
      state: stateId,
      datas: true,
      start: startdate,
      end: enddate,
    };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }

  const handleholdClick = () => {
    navigateEncryptedURL("/menu/ticket", "Hold");
  };
  const handleCloseClick = () => {
    navigateEncryptedURL("/menu/ticket", "Closed");
  };
  const handleCreatedClick = () => {
    navigateEncryptedURL("/menu/ticket", "Created");
  };
 const  handleticketClick =()=>{
  navigate("/menu/ticket",)
 }
  const handlefinishedClick = () => {
    navigateEncryptedURL("/menu/ticket", "Finished");
  };
  const handleunfinishedClick = () => {
    navigateEncryptedURL("/menu/ticket", "Unfinished");
  };
  const handleAssignedClick = () => {
    navigateEncryptedURL("/menu/ticket", "Assigned");
  };

  const handleinprogressClick = () => {
    navigateEncryptedURL("/menu/ticket", "Inprogress");
  };
  const [ticketstatus, setAssignuser] = useState([]);
  const theme = useTheme();
  const handleSite = async (data, val) => {
    const params = new URLSearchParams();
    params.set("siteName", val);
    setSite(val);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === val;
    });
    console.log(siteIdFilter[0]?.siteId);
    fetchDatastate(siteIdFilter[0]?.siteId);
    setSchedule(false);
  };
  const colorMode = theme.palette.mode;
  function getStatusIcon(status) {
    console.log(status);

    let backgroundColor, textColor, borderRadius, padding, cursor;

    switch (status) {
      case "Finished":
        backgroundColor = "#bef4c6";
        textColor = "#023321";
        borderRadius = "8px";
        padding = "5px";
        cursor = "pointer";
        break;
      case "Offline":
      case "Assigned":
        backgroundColor = "#f4f389";
        textColor = "black";
        borderRadius = "8px";
        padding = "5px";
        cursor = "pointer";
        break;
      case "Inprogress":
        backgroundColor = "#f7adad";
        textColor = "black";
        borderRadius = "8px";
        padding = "5px";
        cursor = "pointer";
        break;
      case "Unfinished":
        backgroundColor = "#e8de84";
        textColor = "black";
        borderRadius = "8px";
        padding = "5px";
        cursor = "pointer";
        break;
      default:
        backgroundColor = "rgba(0, 0, 0, 0.2)";
        textColor = "black";
        borderRadius = "8px";
        padding = "5px";
        // cursor = "pointer";
        break;
    }

    return { backgroundColor, textColor, borderRadius, padding, cursor };
  }

  const formatEvents = (data) => {
    console.log(data); // Debug the input data

    // Create a map to track the count of events for each date
    const dateEventCount = {};
    const eventsByDate = {};

    // Filter and format the events
    const formattedEvents = data
      .filter((item) => item.scheduleOn) // Ensure scheduleOn exists
      .map((item) => {
        const [day, month, year] = item.scheduleOn.split("/"); // Split DD/MM/YYYY
        const formattedDate = `${year}-${month}-${day}`; // Convert to YYYY-MM-DD

        // Initialize or add to eventsByDate
        if (!eventsByDate[formattedDate]) {
          eventsByDate[formattedDate] = [];
        }

        // Add event to the corresponding date
        if (eventsByDate[formattedDate].length < 5) {
          eventsByDate[formattedDate].push({
            title: `${item.ticketCode}-${item.categoryName}`, // Event title
            date: formattedDate, // Event date
          });
        }

        // Increment the count for the date
        dateEventCount[formattedDate] =
          (dateEventCount[formattedDate] || 0) + 1;

        return null; // This is just for building eventsByDate
      });

    // Combine events and add "View More" if necessary
    const allEvents = [];
    Object.entries(eventsByDate).forEach(([date, events]) => {
      allEvents.push(...events);
      if (dateEventCount[date] > 5) {
        allEvents.push({
          title: "View More", // Add "View More" option
          date: date,

          style: {
            backgroundColor: "white",
            color: "#007BFF", // Blue text color for "View More"
            textDecoration: "underline", // Underlined text to indicate it's clickable
            cursor: "pointer", // Change cursor to pointer for clickable effect
            padding: "5px", // Add padding for better spacing
          },
        });
      }
    });

    console.log(allEvents); // Debug the formatted events
    setSchedule(false);
    setEvents(allEvents); // Set formatted events
  };

  const handleViewMore = (date) => {
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
    console.log(formattedDate);
    setSchedulelist(formattedDate);
    setSchedule(true);
  };

  const handleDateChange = async (arg) => {
    const currentTitle = arg.view.title;

    console.log(site.length > 0);

    const siteName = siteIdName?.filter((data) => data.siteName === site);
    const siteId = siteName[0]?.siteId;

    const monthYear = currentTitle;
    const [monthName, year] = monthYear.split(" ");
    const month = new Date(`${monthName} 1, ${year}`).getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    const formattedStartDate = new Date(startDate);
    formattedStartDate.setDate(formattedStartDate.getDate() + 1);
    const formattedStartDateString = formattedStartDate
      .toISOString()
      .split("T")[0];

    const formattedEndDate = new Date(endDate);
    formattedEndDate.setDate(formattedEndDate.getDate() + 1);
    const formattedEndDateString = formattedEndDate.toISOString().split("T")[0];
    setStartdate(formattedStartDateString);
    setenddate(formattedEndDateString);
    console.log(formattedStartDateString);
    const formattedEndDateStringg = formattedEndDate.toISOString().slice(0, 7);
    console.log(formattedEndDateStringg);

    const datas = {
      siteId: siteId,
      toDate: formattedEndDateString,
      filterType: "scheduleOn",
      fromDate: formattedStartDateString,
    };
    console.log(datas.siteId);

    try {
      if (datas.siteId !== undefined) {
        // setLoading(true)
        const data = await postTicketFilterTable(datas);
        const value = await fetchstateCount(
          datas?.siteId,
          formattedEndDateStringg
        );
        setData(value);
        setLoading(false);
        const updateAssignedBy = (item) => {
          if (item.assignedBy === null) {
            item.assignedBy = "Assign";
          }
          if (Array.isArray(item.ticketTransactions)) {
            item.ticketTransactions.forEach((transaction) => {
              if (transaction.assignedBy === null) {
                transaction.assignedBy = "assign";
              }
            });
          }

          if (item.scheduleOn) {
            const date = new Date(item.scheduleOn);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            item.scheduleOn = `${day}/${month}/${year}`;
          }

          return item;
        };

        if (Array.isArray(data)) {
          const updatedData = data.map(updateAssignedBy);
          setSiteReport(updatedData);
          setLoading(false);
        } else if (typeof data === "object" && data !== null) {
          const updatedData = updateAssignedBy(data);
          setSiteReport([updatedData]);
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const headerToolbarConfig = {
    left: "prev",
    center: "title",
    right: "next",
  };
  const handleTicketClick = (data) => {
    console.log(data);

    navigate("/menu/view_ticket", {
      state: {
        id: data,
        isFromSiteList: data.isFromSiteList,
        isFromSticketcalender: true,
      },
    });
  };
  useEffect(() => {
    formatEvents(SiteReport);
  }, [SiteReport]);
  const fetchAssignDetails = async () => {
    try {
      const data = await fetchAssignDetail();
      setAssignuser(data);
    } catch {}
  };
  return (
    <div>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "6vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              width={"100%"}
              direction="row"
              justifyContent="space-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: "1%" }}>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "6vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              width={"100%"}
              direction="row"
              justifyContent="space-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={site}
                  sx={{
                    width: "18vw",
                    ml: "20px",
                    backgroundColor:
                      colorMode === "light" ? "#F7F8F9" : "#38373C",
                    "& .MuiInputBase-root": {
                      display: "flex",
                      alignItems: "center",
                      height: "3.5vh",
                      minHeight: "unset",
                      fontSize: "0.875rem",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "4px 8px",
                      display: "flex",
                      alignItems: "center",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiAutocomplete-input": {
                      height: "100%",
                      padding: "2px 8px",
                      textAlign: "center",
                      fontSize: "0.875rem",
                      lineHeight: "4.5vh",
                    },
                    "& .MuiInputLabel-root": {
                      top: "-5px",
                      fontSize: "0.875rem",
                    },
                    "& .MuiAutocomplete-option": {
                      textAlign: "center",
                    },
                  }}
                  options={siteIdName.map((option) => option.siteName)}
                  onChange={(data, event) => handleSite(data, event)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Site ..." />
                  )}
                  classes={{ option: "autocomplete" }}
                />
              </Grid>
              <Grid
                item
                xs="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                {" "}
                 <Tooltip title="Ticketconfiguration">
                <LocalActivityOutlinedIcon style={{color:"#1976d2",cursor:"pointer"}} onClick={handleticketClick} />
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: "1%" }}>
        <Grid container spacing={2} columns={21}>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #36bde2 10%)`,
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Created
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#36bde2",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.unAssignedTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  <CreateIcon
                    onClick={
                      data?.unAssignedTicketCount === 0
                        ? undefined
                        : handleCreatedClick
                    }
                    disabled={data?.unAssignedTicketCount === 0}
                    style={{
                      cursor:
                        data?.unAssignedTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.unAssignedTicketCount === 0
                          ? "#808080"
                          : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #29d6cf 10%)`,
              }}
            >
              {" "}
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Assigned
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#29d6cf",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.assignedTicketCount}
                  </Typography>
                </Grid>

                <Grid item md={2} lg={2} xs={2}>
                  <AssignmentTurnedInOutlinedIcon
                    onClick={
                      data?.assignedTicketCount === 0
                        ? undefined
                        : handleAssignedClick
                    }
                    disabled={data?.assignedTicketCount === 0}
                    style={{
                      cursor: data?.assignedTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.assignedTicketCount === 0 ? "#808080" : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #ff6c6b 10%)`,
              }}
            >
              {" "}
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Inprogress
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#ff6c6b",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.inprogressTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  <EventRepeatIcon
                    disable={data?.inprogressTicketCount === 0}
                    onClick={
                      data?.inprogressTicketCount
                        ? undefined
                        : handleinprogressClick
                    }
                    style={{
                      cursor:
                        data?.inprogressTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.inprogressTicketCount === 0
                          ? "#808080"
                          : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #41918d 10%)`,
              }}
            >
              {" "}
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Hold
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#518e8b",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.holdTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  {/* <Link
                  underline="hover"
                  onClick={handleholdClick}
                  style={{
                    cursor: "pointer",
                    lineHeight: "16px",
                    fontSize: "0.80rem",
                  }}
                  
                > */}
                  <PauseCircleOutlinedIcon
                    onClick={
                      data?.holdTicketCount === 0 ? undefined : handleholdClick
                    }
                    disabled={data?.holdTicketCount === 0}
                    style={{
                      cursor: data?.holdTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.holdTicketCount === 0 ? "#808080" : "#1976d2",
                    }}
                  />
                  {/* </Link> */}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #78e8a7 10%)`,
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Finished
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#78e8a7",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.finishedTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  <CheckCircleOutlinedIcon
                    onClick={
                      data?.finishedTicketCount
                        ? undefined
                        : handlefinishedClick
                    }
                    disabled={data?.finishedTicketCount === 0}
                    style={{
                      cursor: data?.finishedTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.finishedTicketCount === 0 ? "#808080" : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #b4dd71 10%)`, // Background color starts at 90% and ends with the desired color
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Unfinished
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#b4dd71",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.unFinishedTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  <HourglassEmptyIcon
                    onClick={handleunfinishedClick}
                    disable={data?.unFinishedTicketCount === 0}
                    style={{
                      cursor:
                        data?.unFinishedTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.unFinishedTicketCount === 0
                          ? "#808080"
                          : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                width: "100%",
                minHeight: "15vh",
                padding: "20px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                background: `linear-gradient(to bottom, #fff 90%, #5cd2f7 10%)`,
              }}
            >
              {" "}
              <Grid container spacing={1}>
                <Grid item md={8} lg={10} xs={10}>
                  <Typography style={{ fontSize: "18px", opacity: "0.7" }}>
                    Closed
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 650,
                      fontSize: "18px",
                      marginTop: "10px",
                      marginLeft: "3px",
                      color: "#5cd2f7",
                    }}
                  >
                    {/* {totalSites} */}
                    {data?.closedTicketCount}
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} xs={2}>
                  <EventBusyOutlinedIcon
                    onClick={handleCloseClick}
                    disable={data?.closedTicketCount === 0}
                    style={{
                      cursor: data?.closedTicketCount === 0 ? "" : "pointer",

                      color:
                        data?.closedTicketCount === 0 ? "#808080" : "#1976d2",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "1%", display: "flex" }}>
        <Grid container spacing={2} columns={15}>
          <Grid item xs={10}>
            <Paper style={{ width: "100%" }}>
              <div style={{ padding: "20px" }}>
                <div>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                        padding: "20%",
                        height: "50%",
                      }}
                    >
                      <FadeLoader color="#000440" loading={true} />
                    </div>
                  ) : (
                    <div>
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        initialView="dayGridMonth"
                        initialDate={new Date(2025, new Date().getMonth())}
                        events={events}
                        headerToolbar={headerToolbarConfig}
                        eventContent={(arg) => {
                          if (arg.event.title === "View More") {
                            return (
                              <div>
                                <Link
                                  onClick={() =>
                                    handleViewMore(arg.event.start)
                                  }
                                  style={{ color: "white", cursor: "pointer" }}
                                >
                                  {arg.event.title}
                                </Link>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                style={{
                                  color: "white", // Blue text
                                  textDecoration: "none",
                                  // Underline
                                  cursor: "pointer",
                                }}
                                onClick={() => handleViewMore(arg.event.start)}
                              >
                                {arg.event.title}
                              </div>
                            );
                          }
                          return null; // Default rendering for other events
                        }}
                        datesSet={(data) => handleDateChange(data)} // Listen for the date change event
                      />
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper
              className="total-sites"
              elevation={0}
              sx={{
                borderRadius: "20px",
                width: "100%",
                minHeight: "100%",
                padding: "20px",
                borderRadius: "13px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  userSelect: "none",
                  textTransform: "capitalize",
                }}
              >
                {schedule === true
                  ? `Ticket List - ${schedulelist}`
                  : "Today's - Todo List"}
              </Typography>

              {/* List the filtered tickets */}
              <div style={{ marginTop: "6%" }}>
                {todayTickets.length > 0 ? (
                  todayTickets.map((ticket) => {
                    const {
                      backgroundColor,
                      textColor,
                      borderRadius,
                      padding,
                      cursor,
                    } = getStatusIcon(ticket.statusName);
                    return (
                      <div key={ticket.ticketCode} style={{ marginTop: "3%" }}>
                        <Typography
                          style={{
                            backgroundColor,
                            color: textColor,
                            borderRadius,
                            padding,
                            cursor,
                          }}
                          onClick={() => handleTicketClick(ticket)}
                        >
                          {ticket.ticketCode?.toString().slice(-5)} -{" "}
                          {ticket.categoryName} - {ticket.statusName} -{" "}
                          {ticket.assignedBy}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center", marginTop: "50%" }}>
                    No tickets for today
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
