import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Paper,
  Modal,
  Typography,
  Grid,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Switch from "@mui/material/Switch";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FadeLoader from "react-spinners/FadeLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { SiteListApi, SiteOverview } from "../../Api/SiteListApi";
import Download from "../../Template/Excel/Download";
import TableTemplate from "../../Template/TableTemplate";
import errorMsg from "../util/errorMessage.json";
import CustomSnackbar from "../util/components/CustomSnackbar";
import {
  getFormatDate,
  removeQuestionMark,
} from "../util/common/CommanFunction";
import { decryptPaddedQuery } from "../util/security/Cipher";

export default function Site({ SiteList }) {
  const [siteEnergy, setSiteEnergy] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);

  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  console.log(params);
  const [siteTab, setSiteTab] = React.useState("");
  useEffect(() => {
    if (params.status) {
      setSiteTab(params.status);
    }
  }, [params.status]);
  const isFromSite = Object.keys(params).length > 0&&params.datass
  console.log(params,isFromSite);
  const defaultLoadingStates = {
    siteName: true,
    status: true,
    todayEnergy: true,
    totalEnergy: true,
    specificYield: true,
    inverters: true,
    capacity: true,
    lastUpdated: true,
  };
  const [loading, setloading] = useState(false);
  const [overview, setOverview] = useState([]);
  const [siteNameLoading, setSiteNameLoading] = useState(
    defaultLoadingStates.siteName
  );
  const [statusLoading, setStatusLoading] = useState(
    defaultLoadingStates.status
  );
  const [todayEnergyLoading, setTodayEnergyLoading] = useState(
    defaultLoadingStates.todayEnergy
  );
  const [totalEnergyLoading, setTotalEnergyLoading] = useState(
    defaultLoadingStates.totalEnergy
  );
  const [specificYieldLoading, setSpecificYieldLoading] = useState(
    defaultLoadingStates.specificYield
  );
  const [invertersLoading, setinvertersLoading] = useState(
    defaultLoadingStates.inverters
  );
  const [capacityLoading, setcapacityLoading] = useState(
    defaultLoadingStates.capacity
  );
  const [lastUpdatedLoading, setlastUpdatedLoading] = useState(
    defaultLoadingStates.lastUpdated
  );

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Site List", path: -1 },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setloading(true);
      const data = await SiteListApi();
      const updatedSites = data.map((site) => ({
        ...site,
        specificYield: specificYiled(site.specificYield),
      }));
      setSiteEnergy(updatedSites);
      setloading(false);
      console.log(data, "vvv");
    } catch (e) {
      console.error(e);
    }
  };
 
  console.log(siteEnergy, "siteEnergy");
  console.log(overview, "overview");

  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Status",
      id: "siteStatus",
      view: true,
      default: true,
    },
    {
      label: `Today Energy (kWh)`,
      // label: Today Energy,
      id: "sumOfTodayEnergy",
      view: true,
      default: true,
    },
    {
      label: `Total Energy (MWh)`,
      // label: Total Energy,
      id: "sumOfTotalEnergy",
      view: true,
    },
    {
      label: "Specific Yield",
      id: "specificYield",
      view: true,
    },
    {
      label: "PR (%)",
      id: "performanceRatio",
      view: true,
    },
    {
      label: `Capacity (kWp)`,
      id: "withCapacityUnits",
      view: true,
    },
    {
      label: "Inverters",
      id: "inverterCount",
      view: true,
      default: true,
    },
    {
      label: "Last Updated",
      id: "lastUpdatedTimestamp",
      view: true,
      default: true,
    },
  ];

  const handleDownloadTable = (val) => {
    if (!siteEnergy.length) {
      setSnack(errorMsg.Download);
    } else {
      Download(val, "site");
    }
  };

  const handlesiteoverview = async (data) => {
    navigate("menu/site_overview", {
      // state: { id: data.data, isFromSiteList: data.isFromSiteList },
    });
  };

  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : "0";
  };
  const utcDate = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000;

  const istDate = new Date(utcDate.getTime() + istOffset);

  // Formatting the date
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "Asia/Kolkata",
  };

  const formattedDate = istDate.toLocaleString("en-IN", options);
  console.log(formattedDate, "lastUpdatedTimestamp");

  console.log(params.values);
  const tableValues = siteEnergy
    .map((row) => {
      let newRow = { ...row };

      if (row.installationCapacity) {
        newRow.withCapacityUnits = `${row.installationCapacity}`;
      }
      if (row.lastUpdatedTimestamp !== null) {
        newRow.lastUpdatedTimestamp =
          row.lastUpdatedTimestamp === ""
            ? "-"
            : getFormatDate(row.lastUpdatedTimestamp);
      }
      newRow.performanceRatio =
        row.performanceRatio?.split("%")[0] ?? row.performanceRatio;

      newRow.sumOfActivePower =
        row.sumOfActivePower?.split("kW")[0] ?? row.sumOfActivePower;

      newRow.sumOfTodayEnergy =
        row.sumOfTodayEnergy?.split("kWh")[0] ?? row.sumOfTodayEnergy;

      newRow.sumOfTotalEnergy =
        row.sumOfTotalEnergy?.split("MWh")[0] ?? row.sumOfTotalEnergy;

      return newRow;
    })
    .filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(siteTab.toLowerCase())
      )
    );
    const isMatchingSiteType = isFromSite
    ? tableValues.filter(row => row.sitetype === params.values) // Return matching rows
    : tableValues;
  
console.log(isMatchingSiteType);


  console.log(tableValues, "tableValues");
  const handleChange = (newValue) => {
    switch (newValue.toLowerCase()) {
      case "all":
        setSiteTab("");
        break;
      case "active":
        setSiteTab("Active");
        break;
      case "offline":
        setSiteTab("Offline");
        break;
      case "warning":
        setSiteTab("Warning");
        break;
      case "down":
        setSiteTab("Down");
        break;
      default:
        setSiteTab("");
    }
  };
  const totalNumberofSites = siteEnergy.length;

  const numberOfActiveSites = siteEnergy.filter(
    (site) => site.siteStatus === "Active"
  ).length;

  const numberOfOfflineSites = siteEnergy.filter(
    (site) => site.siteStatus === "Offline"
  ).length;

  const numberOfWarningSites = siteEnergy.filter(
    (site) => site.siteStatus === "Warning"
  ).length;

  const numberOfDownSites = siteEnergy.filter(
    (site) => site.siteStatus === "Down"
  ).length;
  console.log(totalNumberofSites, "totalNumberofSites");
  const tabsData = [
    { name: "All Sites", value: "all", badgeCount: totalNumberofSites },
    { name: "Active Sites", value: "active", badgeCount: numberOfActiveSites },
    {
      name: "Offline Sites",
      value: "offline",
      badgeCount: numberOfOfflineSites,
    },
    {
      name: "Warning Sites",
      value: "warning",
      badgeCount: numberOfWarningSites,
    },
    { name: "Down Sites", value: "down", badgeCount: numberOfDownSites },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            <CustomSnackbar
              open={snack.open}
              onClose={() => setSnack(initialSnack)}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"Site List"}
              SearchLabel={"Search Site Here... "}
              header={headCells}
              rowsValue={isMatchingSiteType}
              tabsData={tabsData}
              handleChange={handleChange}
              userRole={SiteList[0]}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handleTicketPopupOpen={(val) => handlesiteoverview(val)}
              isSiteList={true}
              overviewData={siteEnergy}
              paths={paths}
              rawData={siteEnergy}
              fallbackText={`No ${
                siteTab !== "" ? siteTab : ""
              } site is configured for this user`}
            />
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Paper
                  elevation={0}
                  className="total-custom"
                  sx={{
                    width: "18%",
                    height: "max-content",
                    overflow: "hidden",
                    backgroundColor: "aliceblue",
                    padding: "10px",
                    marginLeft: "81%",
                    marginTop: "9.5%",
                  }}
                >
                  <div>
                    <Grid container spacing={10}>
                      <Grid item xs={6} md={14}>
                        <Typography
                          style={{
                            color: "rgb(18, 127, 216)",
                            marginLeft: "80%",
                            marginTop: "4%",
                            cursor: "pointer",
                          }}
                          onClick={handleClose}
                        >
                          <CloseOutlinedIcon />
                        </Typography>
                        {siteNameLoading ? (
                          <FormControlLabel
                            sx={{
                              display: "block",
                            }}
                            control={
                              <Switch
                                checked={siteNameLoading}
                                onChange={() =>
                                  setSiteNameLoading(!siteNameLoading)
                                }
                                color="primary"
                                disabled
                              />
                            }
                            label="Site Name"
                          />
                        ) : (
                          <FormControlLabel
                            sx={{
                              display: "block",
                            }}
                            control={<Switch color="primary" />}
                            label="Site Name"
                          />
                        )}
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={statusLoading}
                              onChange={() => setStatusLoading(!statusLoading)}
                              color="primary"
                            />
                          }
                          label="Select Status"
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={todayEnergyLoading}
                              onChange={() =>
                                setTodayEnergyLoading(!todayEnergyLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Today Energy"
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={totalEnergyLoading}
                              onChange={() =>
                                setTotalEnergyLoading(!totalEnergyLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Total Energy"
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={specificYieldLoading}
                              onChange={() =>
                                setSpecificYieldLoading(!specificYieldLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Specific Yield "
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={capacityLoading}
                              onChange={() =>
                                setcapacityLoading(!capacityLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Capacity "
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={invertersLoading}
                              onChange={() =>
                                setinvertersLoading(!invertersLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Inverters"
                        />
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={lastUpdatedLoading}
                              onChange={() =>
                                setlastUpdatedLoading(!lastUpdatedLoading)
                              }
                              color="primary"
                            />
                          }
                          label="Last Updated "
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
