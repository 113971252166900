import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  TextField,
  Autocomplete,
  Menu,
  MenuItem,
  IconButton,
  InputBase,
  InputAdornment,
  Modal,
  Card,
  CardHeader,
  Stack,
  Button,
  Divider,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  PaginationItem,
  Pagination,
  useTheme,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { dgreview } from "../util/TextField";
import FadeLoader from "react-spinners/FadeLoader";
import dayjs from "dayjs";
/*------------api call-----------------*/
import {
  postDgrSave,
  postDgrUpdate,
  fetchSiteandDateGet,
  fetchTodaySiteandDateGet,
  fetchSitestartTime,
  getcategory,
} from "../../Api/DgrApi";
import CustomSnackbar from "../util/components/CustomSnackbar";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableTemplate from "../../Template/TableTemplate";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { EquipmentType } from "../util/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Delete } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toDate } from "date-fns";
import {
  postSubscriptionMaster,
  putSubscriptionEdit,
  putSubscriptionDelete,
  fetchSiteDetails,
  FetchSubscriptionType,
  fetchsubscriptionTable,
  fetchCompanyDetails,
  GetCustomerdropdowndata,
} from "../../Api/POListApi";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Download from "../../Template/Excel/Download";
import jsPDF from "jspdf";
import { CheckForLight } from "../util/common/CommanFunction";
import { data } from "react-router";
import errorMsg from "../util/errorMessage.json";
export default function Equipmenthome({ dailyGeneration }) {
  const [searchInput, setSearchInput] = useState("");
  const [menuOpenn, setOpennMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editValue, setEditValue] = useState();
  const [modalopen, setModalOpen] = React.useState(false);
  const [modalopenn, setModalOpenn] = React.useState(false);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  console.log(editValue);

  const initialPayload = {
    companyId: Number(sessionStorage.getItem("companyId")),
  };
  const [selectedImgName, setSelectedImgName] = useState("");
  const [defaultSubscriptionValue, setDefaultSubscriptionValue] =
    useState(null);
  const [payload, setPayload] = useState(initialPayload);
  const [selectedFile, setSelectedFile] = useState(null);
  const [customerId, setCustomerId] = useState([]);
  const [Company, setCompany] = useState([]);
  console.log(Company);

  const theme = useTheme();
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const colorMode = theme.palette.mode;
  const typoStyle = {
    textTransform: "capitalize",
    opacity: "0.8",
    fontSize: "15px",
  };
  /*----------------------------Fields-------------------------------*/
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [chartAnchor, setChartAnchor] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [siteTemp, setSiteTemp] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [customerName, setCustomerName] = useState([]);
  const [subscriptionTable, setSubscriptionTable] = useState([]);
  const [customerlist, setCustomerlist] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [poTable, setPoTable] = useState([]);
  const [pageValue, setPageValue] = useState(0);
  const [base64Image, setBase64Image] = useState("");
  const [siteIdName, setSiteId] = useState([]);
  const [isTableVisible, setTableVisible] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    editMode && editValue?.subscriptionId ? editValue.subscriptionId : null
  );
  let initialState = {
    poNumber: "",
    subscriptionName: "",
    siteName: "",
    PoFromDate: "",
    PoToDate: "",
    file: "",
    subscriptionPlan: "",
    status: "Active",
    customerName: "",
    companyName: "",
    ToDate: "",
    FromDate: "",
    emailId: "",
  };
  const [subscriptionField, setSubscriptionField] = useState(initialState);
  console.log(subscriptionField);
  const [openn, setOpenn] = React.useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const handleClosee = () => {
    setOpenn(false);
    setSelectedImgName("");
  };
  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchSubscription = async () => {
    let responseData = await FetchSubscriptionType();
    console.log(responseData.data[0].subscriptionName, "fetched data");
    setSubscriptionOptions(responseData?.data);
    setDefaultSubscriptionValue(responseData?.data[0].subscriptionName);
  };

  const handleplandata = (event, value, name) => {
    console.log(value, name);
    setSubscriptionField({ ...subscriptionField, [name]: value });
  };
  useEffect(() => {
    fetchFiltertable(subscriptionField);
  }, [subscriptionField]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const formatDateWithTime = (date) => {
    if (!date) return null;
    const jsDate = dayjs(date).isValid()
      ? dayjs(date).toDate()
      : new Date(date);
    if (isNaN(jsDate.getTime())) return null;

    const year = jsDate.getFullYear();
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const day = String(jsDate.getDate()).padStart(2, "0");
    const hours = String(jsDate.getHours()).padStart(2, "0");
    const minutes = String(jsDate.getMinutes()).padStart(2, "0");
    const seconds = String(jsDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const calculateAutoPopulatedDate = (baseDate) => {
    if (!baseDate) return null;
    switch (selectedFrequency) {
      case "Monthly":
        return dayjs(baseDate).add(1, "month");
      case "Quarterly":
        return dayjs(baseDate).add(3, "month");
      case "Half Yearly":
        return dayjs(baseDate).add(6, "month");
      case "Annual":
        return dayjs(baseDate).add(1, "year");
      default:
        return null;
    }
  };
  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };
  const handleFileChange = (event) => {
    setSelectedImgName("");
    const file = event.target.files[0];
    setSelectedFile(file); // Store the file in state
    const reader = new FileReader();
    setBase64Image("");
    setSnack({ ...initialSnack, open: false });

    if (file) {
      const fileSize = file.size;
      const fileType = file.type;
      console.log(fileSize, "fileSize");
      const unsupportedTextTypes = [
        "text/plain",
        "text/csv",
        "text/html",
        "text/xml",
      ];
      const fileTypeCheck = !unsupportedTextTypes.includes(fileType);

      if (fileTypeCheck) {
        reader.onloadend = () => {
          setBase64Image(reader.result);
          setSelectedImgName(file.name);
        };
        reader.readAsDataURL(file);
        console.log(reader, "imageFile");
      } else {
        setSelectedFile(null); // Clear selected file if type not supported
        setSnack({
          ...initialSnack,
          open: true,
          severity: "warning",
          message: "Text file is not supported",
        });
      }
    } else {
      setSelectedFile(null);
      setBase64Image("");
      setSelectedImgName("");
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  useEffect(() => {
    if (editMode && editValue) {
      setFromDate(dayjs(editValue.poFromDate));
      setToDate(dayjs(editValue.poToDate));
      setSubscriptionField({
        PoFromDate: editValue.poFromDate,
        PoToDate: editValue.poToDate,
      });
    }
  }, [editMode, editValue]);
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          // sx={{ color: colorMode === 'light' ? '#484C46' : '#007AFF' }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );
  const PageChange = (value) => {
    const newPageValue = parseInt(value, 5);
    if (!isNaN(newPageValue)) {
      setPageValue(newPageValue);
    }
  };
  const handleDelete = async (row) => {
    console.log(row);
    console.log(row?.poId);
    const dele = await putSubscriptionDelete(row?.poId)
      .then(() => {
        fetchData(row);
        handleClosee();
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "success",
            message: "Data deleted successfully",
          };
        });
        window.location.reload();
      })

      .catch((err) => {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "warning",
            message: "Unable to delete try again later",
          };
        });
        console.log(err);
      });
  };
  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );
  const sendUpdateRequest = async (payload, subDetailsId) => {
    try {
      // Remove empty fields
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "") {
          delete payload[key];
        }
      });

      const response = await putSubscriptionEdit(payload, subDetailsId);
      if (response.status === 200) {
        setSnack(errorMsg.success);
        fetchDataTable(payload?.siteId);
        handleModalClose();

        setOpen(false);
        setEditValue("");
        fetchData();
        // window.location.reload();
      }
    } catch (e) {
      console.error("Error:", e.message);
      setSnack(errorMsg.failure);
    }
  };
  const handleUpdate = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emails;
    if (typeof subscriptionField.emailId === "string") {
      emails = subscriptionField.emailId
        .split(",")
        .map((email) => email.trim());
    } else if (Array.isArray(subscriptionField.emailId)) {
      emails = subscriptionField.emailId.map((email) => email.trim());
    } else {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format",
      });
      return;
    }

    const invalidEmails = emails.filter((email) => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format. After comma check each email address.",
      });
      return;
    }
    try {
      let subDetailsId = editValue?.sub_detailsId;
      const formatDateWithTime = (date) => {
        if (!date) return null;
        const jsDate = new Date(date);
        return `${jsDate.getFullYear()}-${String(
          jsDate.getMonth() + 1
        ).padStart(2, "0")}-${String(jsDate.getDate()).padStart(
          2,
          "0"
        )} ${String(jsDate.getHours()).padStart(2, "0")}:${String(
          jsDate.getMinutes()
        ).padStart(2, "0")}:${String(jsDate.getSeconds()).padStart(2, "0")}`;
      };
      const currentTimestamp = formatDateWithTime(new Date());
      const poFromDateToSend =
        subscriptionField.PoFromDate !== undefined
          ? formatDateWithTime(subscriptionField.PoFromDate)
          : editValue?.PoFromDate;
      const poToDateToSend =
        subscriptionField.PoToDate !== undefined
          ? formatDateWithTime(subscriptionField.PoToDate)
          : editValue?.PoToDate;
      const subscriptionIdToSend =
        selectedSubscriptionId || editValue?.subscriptionId;
      let payload = {
        subDetailsId: subDetailsId,
        siteId: editValue.siteId,
        PoFromDate: poFromDateToSend,
        emailId: subscriptionField?.emailId,
        PoToDate: poToDateToSend,
        poNumber: subscriptionField?.poNumber,
        subscriptionPlan: subscriptionField?.subscriptionPlan,
        createdBy: parseInt(sessionStorage.getItem("id")),
        status: subscriptionField.status === "Inactive" ? 0 : 1,
        subscriptionId: subscriptionIdToSend,
        lastUpdatedDate: currentTimestamp,
        podetails: [],
      };
      if (selectedFile && selectedImgName) {
        let reader = new FileReader();
        reader.onloadend = async () => {
          let base64String = reader.result?.split(",")[1];
          let fileNameWithoutExtension = selectedImgName.substring(
            0,
            selectedImgName.lastIndexOf(".")
          );
          payload.podetails.push({
            fileName: fileNameWithoutExtension,
            file: base64String,
          });
          await sendUpdateRequest(payload, subDetailsId);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        await sendUpdateRequest(payload, subDetailsId);
      }
    } catch (error) {
      console.error("Error occurred during update:", error);
    }
  };
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const handleModalClose = () => {
    setModalOpen(false);
    setEditMode(false);
    setSiteTemp("");
    setEquipments([]);
    setFromDate(null);
    setToDate(null);
    setSubscriptionField(initialState);
    setSelectedFile("");
    setSelectedFrequency(null);
  };
  const handleModalClosee = () => {
    setModalOpenn(false);
  };
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    const isOEM = sessionStorage.getItem("userTypeId") == 1;
    try {
      const data = isOEM
        ? { companyId: sessionStorage.getItem("companyId") }
        : { customerId: sessionStorage.getItem("customerId") };

      const responseData = await postSubscriptionMaster(data);

      if (Array.isArray(responseData)) {
        setCustomerName(responseData);
      } else if (typeof responseData === "object" && responseData !== null) {
        setCustomerName([responseData]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchFiltertable = async () => {
    console.log(
      subscriptionField?.companyName,
      subscriptionField?.customerName
    );

    const company =
      Company?.filter(
        (data) => data.companyName === subscriptionField?.companyName
      ) || [];

    const customer =
      customerId?.filter(
        (data) => data.customerName === subscriptionField?.customerName
      ) || [];

    console.log(customer);
    console.log(subscriptionField?.FromDate, subscriptionField?.ToDate);

    let data = {
      companyId:
        company[0]?.companyId === undefined
          ? sessionStorage.getItem("companyId")
          : company[0]?.companyId,
      customerId: customer[0]?.customerId,
      poFromDate: subscriptionField?.FromDate,
      poToDate: subscriptionField?.ToDate,
    };
    console.log(data);

    data = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          value !== "" &&
          value !== "null" &&
          (!Array.isArray(value) || value.length > 0) // Check for empty arrays
      )
    );

    if (Object.keys(data).length === 0) {
      console.warn("No valid data to send in API request.");
      return;
    }

    try {
      const responseData = await postSubscriptionMaster(data);

      if (Array.isArray(responseData)) {
        setCustomerName(responseData);
      } else if (typeof responseData === "object" && responseData !== null) {
        setCustomerName([responseData]);
      }
    } catch (error) {
      console.error("Error fetching subscription master:", error);
    }
  };

  const fetchDataTable = async (Id, Idd) => {
    console.log(Id, Idd);

    try {
      const responseData = await fetchsubscriptionTable(Id);

      if (Array.isArray(responseData)) {
        setSubscriptionTable(responseData);

        // Find the first matching subscription for the given Idd
        const matchedSubscription = responseData.find(
          (sub) => sub.sub_detailsId === Idd
        );

        if (matchedSubscription) {
          console.log(matchedSubscription);

          setEditValue(matchedSubscription);
          setSubscriptionField({
            ...matchedSubscription,
            status: matchedSubscription.status === 1 ? "Active" : "Inactive",
          });
        }
      } else if (typeof responseData === "object" && responseData !== null) {
        setSubscriptionTable([responseData]);

        setSubscriptionField({
          ...responseData,
          status: responseData.status === 1 ? "Active" : "Inactive",
        });
      }
    } catch (error) {
      console.error("Error fetching subscription table:", error);
    }
  };

  const fetchDatavalue = async () => {
    const isOEM = sessionStorage.getItem("userTypeId") == 1;
    try {
      const datacustomer = {
        customerId: customerlist,
        fromDate: "",
        ToDate: "",
      };
      const responseData = await postSubscriptionMaster(data);

      if (Array.isArray(responseData)) {
        setCustomerName(responseData);
      } else if (typeof responseData === "object" && responseData !== null) {
        setCustomerName([responseData]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(customerId);

  const handleCustomerChange = (data, value, name) => {
    setSubscriptionField((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "companyName" ? { customerName: "" } : {}), // Reset customerName when companyName changes
    }));
    console.log(value, name);

    if (name === "companyName") {
      const company = Company?.filter((data) => data.companyName === value);
      getCustomerList(company[0]?.companyId);

      setSubscriptionField((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "companyName" ? { customerName: "" } : {}), // Reset customerName when companyName changes
      }));
    }
    fetchFiltertable();
  };

  const handlePODate = (value, type) => {
    fetchFiltertable();
    console.log(value);

    setSubscriptionField((prev) => ({
      ...prev,
      [type]: value.format("YYYY-MM-DD"),
    }));
  };
  const handleCloseeMenu = () => {
    setOpennMenu(false);
    setChartAnchor(null);
  };

  const headcells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Subscription Name",
      id: "subscriptionName",
      view: true,
    },
    {
      label: "PO From Date",
      id: "poFromDate",
      view: true,
    },

    {
      label: "PO To Date",
      id: "poToDate",
      view: true,
    },
    {
      label: "PO Number",
      id: "poNumber",
      view: true,
    },

    {
      label: "Action",
      id: "action",
      view: true,
    },
  ];

  const dailychartType = [
    { label: "Excel", value: "excel" },
    { label: "Pdf", value: "pdf" },
  ];

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    getSiteCall();
    fetchSubscription();
    getCompanyList();
  }, []);

  /*--------------GetCustomer List---------------*/
  const getCustomerList = async (Id) => {
    console.log(Id);

    try {
      let data = await GetCustomerdropdowndata(Id);
      console.log(data, "GetCustomerdropdowndata");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCompanyList = async () => {
    try {
      let data = await fetchCompanyDetails();
      console.log(data, "GetCustomerdropdowndata");
      if (Array.isArray(data)) {
        setCompany(data);
      } else if (typeof data === "object" && data !== null) {
        setCompany([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Subscription Master", path: "po" },
  ];
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const exportToPDF = (data) => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    doc.setFontSize(12);
    doc.text("Purchase Orders Report", 14, 10);

    if (!data.length) {
      doc.text("No data available", 14, 20);
      doc.save("purchase_orders.pdf");
      return;
    }
    const headers = Object.keys(data[0]);
    const capsHeaders = headcells.map((item) => item.label);
    const rows = data.map((item) =>
      headers.map((key) => {
        console.log(item, key, "keyOfItem");
        return key === "PO From Date" || key === "PO To Date"
          ? dayjs(item[key]).format("YYYY-MM-DD")
          : item[key];
      })
    );

    console.log(rows, "pdfRows");

    doc.autoTable({
      head: [capsHeaders],
      body: rows,
      startY: 20,
      theme: "grid",
      styles: {
        fontSize: 7.5,
        cellPadding: 2,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 9.5,
      },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
      },
      margin: { top: 15, left: 10 },
      tableWidth: "wrap",
    });

    // Save PDF
    doc.save("purchase_orders.pdf");
  };

  const isLight = CheckForLight();

  const handleMenuItemClick = async (value) => {
    const formattedData = customerName.map((item) => ({
      SiteName: item.siteName,
      SubscriptionName: item.subscriptionName,
      PoNumber: item.poNumber,
      FromDate: dayjs(item.poFromDate).format("YYYY-MM-DD"),
      ToDate: dayjs(item.poToDate).format("YYYY-MM-DD"),
    }));

    if (value === "excel") {
      Download(formattedData, "POList");
    } else if (value === "pdf") {
      exportToPDF(formattedData);
    }

    setOpennMenu(false);
  };

  const handleClickMenuItem = (event, flag) => {
    console.log(event, flag);

    setOpennMenu(true);
    setAnchorEl(event.currentTarget);
  };
  console.log(editValue?.poDetails);

  const handleEditOpen = (val) => {
    console.log(val);

    setTableVisible(true);
    setModalOpen(true);
    setFromDate(dayjs(val.poFromDate));
    fetchDataTable(val?.siteId, val?.sub_detailsId);
    setToDate(dayjs(val.poToDate));
  };
  const handlehistoryTable = (val) => {
    setModalOpenn(true);
    fetchDataTable(val?.siteId);
  };

  const status = [{ data: "Active" }, { data: "Inactive" }];
  const handledocumentdownload = (val) => {
    if (val?.fileUrl) {
      window.open(val.fileUrl, "_blank");
    } else {
      console.error("No document URL provided.");
    }
  };
  const handleClickGo = () => {
    if (pageValue > 0 && pageValue <= Math.ceil(poTable.length / rowsPerPage)) {
      setPage(pageValue - 1);
    }
  };
  // useEffect=(()=>{
  //   fetchFiltertable()
  // },[subscriptionField])
  const handlechangeponumber = (event, name) => {
    setSubscriptionField({ ...subscriptionField, [name]: event.target.value });
  };
  const handleDownload = async () => {
    const filteredFieldSets = customerName.map(
      ({
        uniqueId,
        downTimeId,
        equipmentId,
        flog,
        downTimeCategoryId,
        group,
        Startsecond,
        Endsecond,
        ...rest
      }) => rest
    );
    const worksheet = XLSX.utils.json_to_sheet(filteredFieldSets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FieldSets Data");
    XLSX.writeFile(workbook, "downTime.xlsx");
  };
// Filter rows based on search input
const tableValues = customerName.filter((row) =>
  Object.values(row).some((value) =>
    String(value).toLowerCase().includes(searchInput.toLowerCase())
  )
);

// Function to get latest row for each siteId
function getLatestRows(filteredData) {
  const latestMap = new Map();

  filteredData.forEach(row => {
      const { siteId, poToDate } = row;
      const existing = latestMap.get(siteId);

      if (!existing || new Date(poToDate) > new Date(existing.poToDate)) {
          latestMap.set(siteId, row);
      }
  });

  return Array.from(latestMap.values());
}

// Call the function with filtered table values
const latestFilteredResults = getLatestRows(tableValues);

console.log(latestFilteredResults);



  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "-15px",
            }}
          >
            <Paper
              elevation={0}
              style={{
                width: "100%",
                borderRadius: "2px 2px 0px 0px",
                userSelect: "none",
                height: "5vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                container
                width={"100%"}
                direction="row"
                justifyContent="space-between"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "-10px",
            }}
          >
            <Paper
              elevation={0}
              style={{
                display: "flex",
                width: "100%",
                borderRadius: "2px 2px 0px 0px",
                userSelect: "none",
                marginTop: "1%",
                height: "50px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-5px",
                }}
              >
                <div>
                  <InputBase
                    size="small"
                    className="cus-search"
                    placeholder={"Search Subscription master Here..."}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ width: "40px" }}
                      >
                        <IconButton>
                          <SearchOutlinedIcon style={{ color: "#9EA4AE" }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    style={{
                      width: "240px",
                      height: "30px",
                      backgroundColor: isLight ? "#F7F8F9" : "#4C585B",
                      borderRadius: "8px",
                      fontSize: "small",
                      marginLeft: "1vw",
                      marginRight: "0.5vw",
                      marginTop: "-0.75%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 2, // Ensures proper spacing between fields
                      width: "100%", // Adjust width of the container
                      marginTop: "0.5%",
                    }}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      options={Company.map((option) => option.companyName)}
                      value={subscriptionField?.companyName}
                      onChange={(data, event) =>
                        handleCustomerChange(data, event, "companyName")
                      }
                      sx={{ width: "13vw" }}
                      size="small"
                      ListboxProps={{
                        style: {
                          maxHeight: "200px",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Company Name"
                        />
                      )}
                    />
                    {/* Customer Name Field */}
                    <Autocomplete
                      id="combo-box-demo"
                      options={customerId.map((option) => option.customerName)}
                      value={subscriptionField.customerName} // ✅ Corrected `value` prop
                      disabled={!subscriptionField.companyName}
                      onChange={(event, value) =>
                        handleCustomerChange(event, value, "customerName")
                      } // ✅ Fixed parameter order
                      sx={{ width: "13vw" }}
                      size="small"
                      ListboxProps={{
                        style: { maxHeight: "200px" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Customer Name"
                        />
                      )}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={(event) => handlePODate(event, "FromDate")}
                        disableClearable
                        format="DD/MM/YYYY"
                        //  disabled={!subscriptionField.companyName}
                        slotProps={{
                          textField: {
                            size: "small",
                            InputProps: {
                              style: {
                                width: "13vw",
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disableClearable
                        onChange={(event) => handlePODate(event, "ToDate")}
                        // disabled={!subscriptionField.FromDate}
                        slotProps={{
                          textField: {
                            size: "small",
                            InputProps: {
                              style: {
                                width: "13vw",
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "1%",
                }}
              >
                <div>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpenn}
                    onClose={handleCloseeMenu}
                    style={{ marginLeft: "-0.5%", marginTop: "0.5%" }}
                  >
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        padding: "15px",
                        color: "#1976D2",
                      }}
                      onClick={() => handleMenuItemClick("excel")}
                    >
                      <SaveAltIcon />
                      <Typography
                        style={{ marginLeft: "3%", fontSize: "14px" }}
                      >
                        Download Excel
                      </Typography>
                    </MenuItem>
                    {/* <MenuItem
                      style={{
                        textTransform: "capitalize",
                        padding: "15px",
                        color: "#1976D2",
                      }}
                      onClick={() => handleMenuItemClick("pdf")}
                    >
                      <PictureAsPdfIcon fontSize="small" />
                      <Typography
                        style={{ marginLeft: "3%", fontSize: "14px" }}
                      >
                        Download PDF
                      </Typography>
                    </MenuItem> */}
                  </Menu>

                  <IconButton
                    disabled={tableValues.length === 0}
                    // onClick={(event) => handleClickMenuItem(event, "Time")}
                    onClick={() => handleMenuItemClick("excel")}
                  >
                    <Tooltip title="subscription">
                      <SaveAltIcon
                        disabled={tableValues.length === 0}
                        style={{
                          color: tableValues.length === 0 ? "grey" : "#004AFF",
                          fontSize: "20px",
                          marginLeft: "2%",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </Paper>
          </Box>
          <div style={{ marginTop: "0.5%" }}>
            <div>
              <Modal open={modalopen}>
                <Box className={"styleModalcustomPO"}>
                  <Card
                    sx={{
                      borderRadius: "20px",
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: "20px 20px 20px 20px",
                        // background: CheckForLight()
                        //   ? "rgb(246, 248, 252)"
                        //   : "#4f4f4f",
                      }}
                      action={
                        <Stack spacing={2} direction="row">
                          {editValue !== undefined ? (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleUpdate}
                              startIcon={<SaveIcon />}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              // onClick={handleSave}
                              startIcon={<SaveIcon />}
                              disabled={isSaveDisabled}
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            // style={buttonStyles}
                            style={{
                              borderRadius: "25px",
                              textTransform: "capitalize",
                            }}
                            onClick={handleModalClose}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      }
                      title={"Add New Subscription"}
                    />
                    <Divider sx={{ borderColor: "#888" }} />
                    <div
                      style={{
                        // height: "60vh",
                        height: "auto",
                        padding: "20px",
                      }}
                    >
                      <Grid container spacing={2} columns={12}>
                        {/* Site Name */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Site Name *</Typography>
                          <Autocomplete
                            size="small"
                            disablePortal
                            disabled
                            options={siteIdName.map(
                              (option) => option.siteName
                            )}
                            value={subscriptionField.siteName}
                            onChange={(event, value) =>
                              setSubscriptionField({
                                ...subscriptionField,
                                siteName: value,
                              })
                            }
                            sx={{ width: "13vw" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Site"
                              />
                            )}
                          />
                        </Grid>

                        {/* Plan */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Plan *</Typography>
                          <Autocomplete
                            size="small"
                            disablePortal
                            options={[
                              "Monthly",
                              "Quarterly",
                              "Half Yearly",
                              "Annual",
                            ]}
                            disabled={editValue}
                            value={subscriptionField?.subscriptionPlan}
                            onChange={(event, value) =>
                              handleplandata(event, value, "subscriptionPlan")
                            }
                            sx={{ width: "13vw" }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Monthly" />
                            )}
                          />
                        </Grid>

                        {/* PO Number */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>PO Number *</Typography>
                          <TextField
                            disablePortal
                            disableClearable
                            id="combo-box-demo"
                            size="small"
                            // disabled={editMode}
                            value={subscriptionField.poNumber}
                            onChange={(event) =>
                              handlechangeponumber(event, "poNumber")
                            }
                            sx={{ width: "13vw" }}
                            placeholder="Enter PO Number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Email Id *</Typography>
                          <TextField
                            disablePortal
                            disableClearable
                            id="combo-box-demo"
                            size="small"
                            // disabled={editMode}
                            value={subscriptionField.emailId}
                            onChange={(event) =>
                              handlechangeponumber(event, "emailId")
                            }
                            sx={{ width: "13vw" }}
                            placeholder="Enter emailId"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <div style={{ flex: 1, minWidth: "150px" }}>
                            <Typography style={typoStyle}>
                              Subscription *
                            </Typography>
                            <Autocomplete
                              size="small"
                              disablePortal
                              disableClearable
                              id="combo-box-demo"
                              value={subscriptionField?.subscriptionName}
                              options={subscriptionOptions?.map(
                                (option) => option.subscriptionName
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "100px",
                                },
                              }}
                              onChange={(event, newValue) => {
                                const selectedOption = subscriptionOptions.find(
                                  (option) =>
                                    option.subscriptionName === newValue
                                );
                                setSelectedSubscriptionId(
                                  selectedOption?.subscriptionId ||
                                    editValue?.subscriptionId ||
                                    null
                                );
                              }}
                              sx={{ width: "13vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Ticketing"
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </div>
                        </Grid>

                        {/* From Date */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>From Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              value={fromDate}
                              onChange={(newDate) => {
                                const formattedDate =
                                  formatDateWithTime(newDate);
                                setFromDate(newDate);
                                setToDate(calculateAutoPopulatedDate(newDate));
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoFromDate: formattedDate,
                                  PoToDate: formatDateWithTime(
                                    calculateAutoPopulatedDate(newDate)
                                  ),
                                });
                              }}
                              sx={{ width: "13vw" }}
                              disabled={editMode || !selectedFrequency} // Disabled until Plan is selected
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select From Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",

                                  error: false,
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>

                        {/* To Date */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>To Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              disableClearable
                              value={toDate}
                              onChange={(newDate) => {
                                const formattedDate =
                                  formatDateWithTime(newDate);
                                setToDate(newDate);
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoToDate: formattedDate,
                                });
                              }}
                              disabled={editMode || !selectedFrequency}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select To Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <div style={{ flex: 1, minWidth: "150px" }}>
                            <Typography style={typoStyle}>Status *</Typography>
                            <Autocomplete
                              size="small"
                              disablePortal
                              disableClearable
                              id="combo-box-demo"
                              // disabled={!editMode}
                              sx={{ width: "13vw" }}
                              defaultValue={subscriptionField.status}
                              options={status?.map((option) => option.data)}
                              ListboxProps={{
                                style: {
                                  maxHeight: "100px",
                                },
                              }}
                              onChange={(event, value) =>
                                handleplandata(event, value, "status")
                              }
                              sx={{ width: "13vw" }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Active" />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <div style={{ flex: 1, minWidth: "150px" }}>
                            <Typography style={{ marginBottom: "10px" }}>
                              Document Upload
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="file-upload">
                                <UploadFileOutlinedIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "#1976d2",
                                    fontSize: "24px",
                                    // margin: "10px",
                                  }}
                                />
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.csv,.xls,.xlsx,.doc,.docx,.png,.jpg,.jpeg" // Include all desired file types
                                onChange={handleFileChange}
                              />

                              {selectedFile && (
                                <Typography
                                  style={{
                                    marginLeft: "10px", // Space between the button and the file name
                                    fontSize: "14px",
                                    overflow: "visible", // Allow the file name to be fully visible
                                    whiteSpace: "normal", // Allows file name to wrap to next line if needed
                                    wordWrap: "break-word", // Ensures long file names break properly if needed
                                    flexGrow: 1, // Make sure the file name takes remaining space without truncating
                                  }}
                                >
                                  {selectedFile.name}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <div style={{ marginTop: "3%" }}>
                        <Card
                          elevation={1}
                          style={{ height: "44vh", overflow: "auto" }}
                        >
                          {editValue?.poDetails?.filter((row) => row.fileUrl)
                            ?.length > 0 ? (
                            <div
                              className="table-container-wrapper"
                              style={{ width: "100%" }}
                            >
                              <TableContainer>
                                <Table aria-label="a dense table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">
                                        Document Name
                                      </TableCell>
                                      <TableCell align="center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {editValue.poDetails
                                      .filter((row) => row.fileUrl) // Exclude rows where fileUrl is null or undefined
                                      .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                      .map((row, index) => (
                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            {decodeURIComponent(
                                              row.fileUrl
                                                ?.split("/")
                                                .slice(-2, -1)[0]
                                            )}
                                          </TableCell>
                                          <TableCell align="center">
                                            <SaveAltIcon
                                              style={{
                                                color: "Grey",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handledocumentdownload(row)
                                              }
                                            />
                                            <Delete
                                              style={{
                                                color: "Grey",
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => handleDelete(row)}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          ) : (
                            <div
                              style={{
                                height: "44vh",
                                overflow: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h6" align="center">
                                There is no document uploaded
                              </Typography>
                            </div>
                          )}
                        </Card>
                      </div>
                    </div>
                    {editValue?.poDetails?.filter((row) => row.fileUrl)
                      ?.length > 0 ? (
                      <div style={{ padding: "20px", marginTop: "-50px" }}>
                        <Card
                          component="div"
                          elevation={1}
                          style={{ height: "4.8vh" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid item xs={2}></Grid>
                            <Grid
                              item
                              xs={10}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Stack spacing={2} direction="row">
                                <Pagination
                                  count={Math.ceil(
                                    poTable.length / rowsPerPage
                                  )}
                                  shape="rounded"
                                  color="primary"
                                  page={page + 1}
                                  onChange={handleChangePage}
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: renderCustomPrevious,
                                        next: renderCustomNext,
                                      }}
                                      {...item}
                                    />
                                  )}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{
                                    color: "#E6E7E9",
                                    height: "30px",
                                    marginRight: "16px",
                                  }}
                                />
                              </Stack>
                              <Stack
                                direction="row"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  marginLeft: "1%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "14.52px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Go to page
                                </Typography>
                                <TextField
                                  variant="standard"
                                  style={{
                                    width: "24px",
                                    marginLeft: "5px",
                                  }}
                                  value={pageValue !== 0 ? pageValue : ""}
                                  onChange={(e) => PageChange(e.target.value)}
                                />
                                <Button
                                  style={{
                                    padding: "0",
                                    width: "12px",
                                    fontSize: "14px",
                                  }}
                                  onClick={handleClickGo}
                                  endIcon={
                                    <ArrowForwardIcon
                                      style={{ fontSize: "16px" }}
                                    />
                                  }
                                >
                                  GO
                                </Button>
                                <Autocomplete
                                  options={rowsPerPageOptions}
                                  getOptionLabel={(option) => option.toString()}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      sx={{
                                        fontFamily: "Inter, sans-serif",
                                        width: 40,
                                      }}
                                    />
                                  )}
                                  value={rowsPerPage}
                                  onChange={handleChangeRowsPerPage}
                                  disableClearable
                                  style={{ width: 120 }}
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Card>
                </Box>
              </Modal>
            </div>
            <div>
              <Modal open={modalopenn}>
                <Box className={"styleModalcustomPO"}>
                  <Card
                    sx={{
                      borderRadius: "20px",
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: "20px 20px 20px 20px",
                        // background: CheckForLight()
                        //   ? "rgb(246, 248, 252)"
                        //   : "#4f4f4f",
                      }}
                      action={
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            // style={buttonStyles}
                            style={{
                              borderRadius: "25px",
                              textTransform: "capitalize",
                            }}
                            onClick={handleModalClosee}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      }
                      title={"Subscription History"}
                    />
                    <Divider sx={{ borderColor: "#888" }} />
                    <div>
                      <TableTemplate
                        PageName={"poListsub"}
                        header={headcells}
                        userRole={dailyGeneration[0]}
                        handleEditPopupOpen={(val) => handleEditOpen(val)}
                        rowsValue={subscriptionTable}
                      />
                    </div>
                  </Card>
                </Box>
              </Modal>
            </div>
            <div style={{ marginTop: "0.5%" }}>
              <TableTemplate
                PageName={"poList"}
                header={headcells}
                rowsValue={latestFilteredResults}
                handleEditPopupOpen={(val) => handleEditOpen(val)}
                userRole={dailyGeneration[0]}
                paths={paths}
                handlehistoryTable={(val) => handlehistoryTable(val)}
                fallbackText={`No POs available`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
