import axios from 'axios';
export const Equipmenttypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const GetFileParamCoff = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_ALL_PARAM_COFF}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchMasterDropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_GETALL_MASTERDROPDOWN}`
    )
    return response.data
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

export const getAllDefMaster = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_GETALL_DEFMASTER}`
    )
    return response.data
  } catch (error) {
    console.error('Error posting data:', error);
  }
}
export const getdatalogger = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_GETALL_DATALOGGER}`
    )
    return response.data
  } catch (error) {
    console.error('Error posting data:', error);
  }
}

export const postDefMappingParams = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_POSTDEFMAPPING_SAVE}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};
export const updateDefMapping = async (obj) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_PUTDEFMAPPING_UPDATE}`, obj);
    return response.data
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
export const PutparameterUpdate = async (Id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_PUTDEFMASTER_MASTERID}/${Id}`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

