import axios from 'axios';

export const fetchEquipmentListTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_EQUIPMENTLIST_TABLE
      }/${sessionStorage.getItem('id')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putEquipmenttype = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
/*---------------------- POST Equipment Save ---------------------*/
export const postEquipmentSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const Equipmentcategorydropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_EQUIPMENT_CATEGORY
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const Equipmenttypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_EQUIPMENT_TYPE
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*-------------------------------------------- Equipment bu SIte ID----------------------*/

export const getEquipmentTypeBySiteId = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTBYSITEID}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
