import { useMemo } from "react";
import moment from "moment";

const MONTHS_MAP = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

const normalizeTimestampForSorting = (timestamp) => {
  return MONTHS_MAP[timestamp] || timestamp;
};

const useGenerateSeries = (
  data,
  params,
  siteList = [],
  isTimeSeries = false
) => {
  return useMemo(() => {
    if (!data || !Array.isArray(data) || !params || params.length === 0) {
      return [];
    }

    // Group data by siteId
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.siteId]) {
        acc[item.siteId] = {};
        params.forEach(({ key }) => {
          acc[item.siteId][key] = [];
        });
      }

      let timestamp;

      if (item.timeStamp || item.timestamp) {
        const originalTimestamp = item.timeStamp || item.timestamp;

        if (isTimeSeries) {
          // Convert to Unix timestamp in milliseconds for time series
          timestamp = moment(originalTimestamp).valueOf();
        } else {
          // Original formatting logic
          if (
            moment(originalTimestamp, "YYYY-MM-DD HH:mm:ss.SS", true).isValid()
          ) {
            timestamp = moment(
              originalTimestamp,
              "YYYY-MM-DD HH:mm:ss.SS"
            ).format("HH:mm");
          } else if (moment(originalTimestamp, "YYYY-MM-DD", true).isValid()) {
            timestamp = moment(originalTimestamp, "YYYY-MM-DD").format(
              "MMM DD"
            );
          } else {
            timestamp = originalTimestamp;
          }
        }
      }

      params.forEach(({ key }) => {
        // Ensure the key exists in the data item
        if (key in item) {
          acc[item.siteId][key].push([timestamp, item[key]]);
        }
      });

      return acc;
    }, {});

    // Build series dynamically
    const series = [];
    for (const siteId in groupedData) {
      const siteName =
        siteList.find((site) => site.siteId === Number(siteId))?.siteName ||
        `Site ${siteId}`;

      params.forEach(({ key, displayName, type, axis, color }, index) => {
        // Only include the series if data exists for the parameter
        if (groupedData[siteId][key].length > 0) {
          // Sort the data by normalized timestamp for chronological order
          const sortedData = groupedData[siteId][key].sort(
            ([timestampA], [timestampB]) =>
              isTimeSeries
                ? timestampA - timestampB // Direct numeric comparison for Unix timestamps
                : normalizeTimestampForSorting(timestampA) -
                  normalizeTimestampForSorting(timestampB)
          );

          const name =
            siteList.length > 0 ? `${siteName} - ${displayName}` : displayName;

          series.push({
            name: name,
            color: color,
            type: type,
            data: sortedData,
            yAxis: axis !== undefined ? axis : index,
          });
        }
      });
    }

    return series;
  }, [data, params, siteList, isTimeSeries]); // Added isTimeSeries to dependencies
};

export default useGenerateSeries;
