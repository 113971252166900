// ExcelTemplateDownload.js
import * as XLSX from "xlsx";

export const TemplateCheck = (prop, OEM, formula) => {
  const workbook = XLSX.utils.book_new();
  const ErrorSheet = [
    [
      "Error Code",
      "Equipment Category",
      "Equipment Type",
      "Description",
      "Error Message",
      "Error Type",
      "Priority",
      "Approved By",
    ],
  ];
  const ExpectedDataSheet = [
    [
      "Site Name",
      "Year",
      "Month",
      "Performance Ratio",
      "Ambient Temperature",
      "Energy Grid",
      "Global Horizontal",
      "Horizontal Diffuse",
      "Global Incident",
      "Effective Global",
    ],
  ];
  const StandardParamSheet = [["Parameter Name", "UOM", "Description"]];
  const EquipDetails = [
    [
      "Site Name",
      "Display Name",
      "Equipment Category",
      "Equipment Type",
      "AC Capacity",
      "DC Capacity",
      "AcUnits",
      "DcUnits",
      "Serial No",
      "Latitude",
      "Longitude",
      "Primary Equipment",
      "No Of Components",
      "Remark",
      "Installation Date",
      "Warranty Date",
    ],
  ];
  const EquipConfig = [
    [
      "Site Name",
      "Equipment Name",
      "Daily GenBased On",
      "Modbus Address",
      "Production Flag",
      "Device Sn",
      "Energy Counter",
      "Data Logger Name",
      "Status Master",
    ],
  ];
  const Datalogger = [[
    "Parameter Name",
    "Standard Parameter",
    "CoEfficient",
  ]];
  let resultSheet;
  switch (prop) {
    case "Error":
      resultSheet = ErrorSheet;
      break;
    case "Standard Parameter":
      // alert('standard Data')
      resultSheet = StandardParamSheet;
      break;
    case "Equip Details":
      resultSheet = EquipDetails;
      break;
    case "Expected Data":
      resultSheet = ExpectedDataSheet;
      break;
    case "Equip Config":
      resultSheet = EquipConfig;
      break;
    case "Datalogger":
    case "Master Mapping":
      resultSheet = Datalogger;
      break;
    default:
      break;
  }

  /*----------------- Except Customer Name for the Expect Data Page ----------------------*/

  const includeCustomerName = prop !== "Expected Data";
  if (includeCustomerName && prop !== "Equip Details" && prop !== "Datalogger" && prop !== "Master Mapping" && OEM) {
    resultSheet[0].push("Customer Name");
  }

  const sheet1 = XLSX.utils.aoa_to_sheet(resultSheet);
  XLSX.utils.book_append_sheet(workbook, sheet1, "Sheet1");
  if (formula) {
    let modifiedFormula = formula.sort((a, b) => a.stdParameterName.localeCompare(b.stdParameterName));
    const sheet2ColumnHeaders = ["Count", "Std Parameter Name"];
    const formulaArray = [
      sheet2ColumnHeaders,
      ...modifiedFormula.map((data, index) => [
        index + 1,
        ...Object.entries(data)
          .filter(([key]) => key !== "stdId" && key !== "stdUom")
          .map(([key, value]) =>
            value
          )
      ]),
    ];
    const sheet2 = XLSX.utils.aoa_to_sheet(formulaArray);
    XLSX.utils.book_append_sheet(workbook, sheet2, "Sheet2");
  }

  const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  const blob = new Blob([s2ab(excelData)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
};

export const downloadExcelTemplate = (prop, OEM, formula) => {
  const excelBlob = TemplateCheck(prop, OEM, formula);
  const url = window.URL.createObjectURL(excelBlob);
  const a = document.createElement("a");
  a.href = url;
  a.download = prop + " Template.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}
